import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { toastOptions } from '../../util/Constants'
import Constants from '../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';

const CustomInputField = withStyles({
    root: {
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid white'
        },
        '&:hover .MuiInput-underline:before': {
            borderBottom: '2px solid wheat'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '3px solid white'
        },
        '& input': {
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            padding: '0px'
        },
        '& .MuiInputLabel-formControl': {
            color: 'white'
        },
        '& .Mui-error': {
            '&.MuiInputLabel-formControl': {
                color: 'red'
            },
            '&.MuiInput-underline:after': {
                borderBottom: '3px solid red'
            }
        }
    }
})(TextField);

const formFields = [
    {
        label: 'First Name',
        regex: /^[A-Za-z ]+$/,
        key: 'firstName'
    },
    {
        label: 'Last Name',
        regex: /^[A-Za-z ]+$/,
        key: 'lastName'
    },
    {
        label: 'Login Id',
        regex: '',
        key: 'userName'
    },
    {
        label: 'Password',
        regex: /^[\W\S]{3,20}$/,
        key: 'password'
    },
    {
        label: 'Email',
        regex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
        key: 'email'
    }
]

class AddUserModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            data: {}
        }
        this.closeBtnRef = React.createRef();
    }

    /**
     * This method is to validate data entered by user and to set value of fields
     * 
     * @param {string} value value entered by user
     * @param {regex} pattern regular expression to check validity of field
     * @param {integer} index index of field
     */
    validate(value, pattern, index=-1) {
        const regex = new RegExp(pattern);
        const valid = regex.test(value);
        const { errors } = this.state;
        errors[index] = !valid;
        this.setState({
            errors
        });
    }

    /**
     * This method makes the api call to create a new user
     */
    createUserApiCall() {
        const { dispatch, loadUserData } = this.props;
        const { data } = this.state;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.createUser}`;
        const body = JSON.stringify(data);
        this.setState({
            data: {}
        });
        fetch(url, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            dispatch(showLoader(false));
            loadUserData();
            return response.json();
        }).then(jsonData => {
            if(jsonData.success) {
                toast.success("Success: New user created", toastOptions);
            } else {
                throw new Error();
            }
        }).catch(error => {
            dispatch(showLoader(false));
            toast.error("Error: Operation failed", toastOptions);
            console.log(error);
        })
    }

    /**
     * This method performs the final validation before adding a new user
     */
    createUser() {
        const { errors, data } = this.state;
        formFields.forEach((field, index) => {
            if(data[field.key] === undefined || data[field.key].trim() === '') {
                errors[index] = true;
            }
        })
        if(errors.find(val=>val===true)) {
            this.setState({
                errors
            })
            toast.error("Please correct errors before saving", toastOptions);
            return;
        }
        this.createUserApiCall();
        this.closeBtnRef.current.click();
    }

    render() {

        const { errors, data } = this.state;

        return (
            <div className="modal fade" id="addUserModal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add User</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <span className="modal-body-background">
                            </span>
                            <form>
                                {
                                    formFields.map((field, index) =>
                                        <div key={index} className="form-group">
                                            <CustomInputField
                                                required
                                                label={field.label}
                                                helperText={errors[index] ? "Invalid entry" : ''}
                                                fullWidth
                                                error={errors[index]}
                                                value={data[field.key] || ''}
                                                onChange={(event)=>{
                                                    data[field.key] = event.target.value;
                                                    this.setState({
                                                        data
                                                    });
                                                    this.validate(event.target.value, field.regex, index);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" onClick={()=>this.createUser()} className="btn btn-success">Save</button>
                            <button type="button" ref={this.closeBtnRef} className="btn btn-danger" data-dismiss="modal"
                                onClick={()=>{
                                    this.setState({
                                        data: {},
                                        errors: []
                                    })
                                }}
                            >Close</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(AddUserModal);

AddUserModal.defaultProps = {
    loadUserData: ()=>{}
}