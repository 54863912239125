import React, { Fragment } from 'react';
import { toastOptions } from '../../util/Constants';
import './SimpleFileUpload.css';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';

class SimpleUploadComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileName: ''
        }
    }

    /**
     * This function handles setting file name as label and initialize the file upload
     * @param {event} event - change event from file input
     */
    handleFile(event) {
        const file = Array.from(event.target.files)[0];
        if(file) {
            const fileName = file.name;
            if(!file.name)
                return;
            this.setState({
                fileName
            })
        }
        event.target.blur();
        event.target.value = "";
        this.uploadFile(file);
    }

    /**
     * This is the file to be uploaded
     * @param {file} file - file to be uploaded
     */
    uploadFile(file) {
        const { dispatch, uploadUrl } = this.props;
        const fileName = file.name;
        dispatch(showLoader(true));
        const data = new FormData();
        data.append('file', file);
        fetch(uploadUrl, {
            method: 'POST',
            body: data
        }).then(response=>{
            this.setState({
                fileName: ''
            })
            dispatch(showLoader(false));
            if(response.status === 200) {
                toast.success(`Success: File ${fileName} uploaded`, toastOptions);
            } else {
                throw new Error("File upload failed")
            }
        }).catch(error=>{
            dispatch(showLoader(false));
            this.setState({
                fileName: ''
            })
            toast.error(`Error: File ${fileName} upload failed`, toastOptions);
            console.log(error);
        });
    }

    render() {
        const { fileName } = this.state;
        const { className, label, image, disabled } = this.props;
        return(
            <Fragment>
                <input type="file" id="file" className="inputfile" onChange={(event)=>this.handleFile(event)} disabled={disabled} />
                <label htmlFor="file" className={disabled?"uploadlabeldisabled":''+className}>
                    {image && <img src={image.src} width={image.width} height={image.height} />}
                    &nbsp;
                    {fileName || label || "Choose a file"}
                </label>
            </Fragment>
        )
    }
}

export default connect()(SimpleUploadComponent);

SimpleUploadComponent.defaultProps = {
    uploadUrl: '',
    className: '',
    label:  '',
    image: '',
    disabled: false
}