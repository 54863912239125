import React from 'react';
import * as d3 from 'd3';
import './HorizontalBarChart.css';
import { numberWithComas } from '../../../util/Constants';

export default class HorizontalBarChart extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    getAllValues(data = []) {
        const values = []
        data.forEach(row => {
            row.bars.forEach(bar => {
                values.push(bar.value);
            })
        })
        return values;
    }

    componentDidUpdata(prevProps) {
        if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.drawChart();
        }
    }

    drawChart() {
        //alert(this.getAllValues(padding));
        const chartRef = this.chartRef.current;
        const { data, dx, padding, tooltipTemplate, widthOffset, heightOffset, aspectRatio } = this.props;
        let { barHeight:barHeight } = this.props;
        const allValues = this.getAllValues(data);
        const allNames = data.map(row => row.name);
        const xaxisHeight = 40;

        //alert(allNames.length);
        // adjust bar height
        if(allNames.length > 33) {
            barHeight=8;
        } else if(allNames.length > 27) {
            barHeight = 10;
        } else if(allNames.length > 21) {
            barHeight = 12;
        } else if(allNames.length > 20) {
            barHeight = 15;
        }
        const height = data.length * barHeight + xaxisHeight;

        // clean the board
        d3.select(chartRef).selectAll('svg').remove();
        // FOR SCALE
        const scaleX = d3.scaleLinear()
            .domain([0, d3.max(allValues)])
            .range([0, 700]);
        const width = scaleX(d3.max(allValues))+dx+50;
        const scaleY = d3.scaleBand()
            .domain(allNames)
            .range([0, height - xaxisHeight]);
        // const pageWidth = d3.select('body').node().getBoundingClientRect().width;
        const svg = d3.select(chartRef)
            .append('svg')
            // \.attr('width', (pageWidth-30)*0.65)
            // .attr('height', '100%')
            .attr("preserveAspectRatio", aspectRatio)
            .attr('viewBox', `0 0 ${width+widthOffset} ${height+heightOffset}`);
        if(this.props.width) {
            svg.attr('width', this.props.width);
        }
        if(this.props.height) {
            svg.attr('height', this.props.height)
        }
        const g = svg.selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(${dx}, ${barHeight * i})`)

        const Tooltip = d3.select(this.tooltipRef.current);

        // Three function that change the tooltip when user hover / move / leave a cell
        const mouseover = function(d) {
            Tooltip
            .style("opacity", 1)
        }
        const mousemove = function(d) {
            const data = d3.select(this.parentNode).data();
            const createTooltipHTML = _=>{
                if(!tooltipTemplate) {
                    return undefined;
                }
                let tooltip = String(tooltipTemplate);
                tooltip = tooltip.replaceAll('#{data[0].name}', data[0].name);
                tooltip = tooltip.replaceAll('#{data[0].bars[0].fill}', data[0].bars[0].fill);
                tooltip = tooltip.replaceAll('#{numberWithComas(data[0].bars[0].value)}', numberWithComas(data[0].bars[0].value));
                return tooltip;
            }
            Tooltip
            .html(createTooltipHTML() || `
                <div style="text-align: center; font-weight: 600;">
                    ${data[0].name}
                </div>
                <div>
                    <svg width='10px' height='10px'>
                        <rect width='10px' height='10px' fill='${data[0].bars[0].fill}'></rect>
                    </svg>
                    Target : ${numberWithComas(data[0].bars[0].value)}
                </div>
                <div>
                    <svg width='10px' height='10px'>
                        <rect width='10px' height='10px' fill='${data[0].bars[1].fill}'></rect>
                    </svg>
                    Achieved : ${numberWithComas(data[0].bars[1].value)}
                </div>
            `)
            const styles = Tooltip.node().getBoundingClientRect();
            Tooltip.style('left', `${d3.event.x + 5}px`).style('top', `${d3.event.y - styles.height-5}px`)
            // Tooltip.style('left', `${d3.mouse(this)[0]+styles.width/2+20}px`)
            // Tooltip.style('top', `${d3.mouse(this)[1]}px`)
            // .style("left", (d3.mouse(this)[0]+70) + "px")
            // .style("top", (d3.mouse(this)[1]) + "px")
        }
        const mouseleave = function(d) {
            Tooltip
            .style("opacity", 0)
        }

        Tooltip.on('mouseover', (d)=>{
            Tooltip.style('left', 0);
        })

        g.selectAll('rect')
            .data((d, i) => data[i].bars)
            .enter()
            .append('rect')
            .attr('width', d => scaleX(d.value))
            .attr('height', barHeight - padding)
            .attr('fill', d => d.fill)
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
        
        const xaxis = d3.axisBottom()
            .scale(scaleX);

        const yaxis = d3.axisLeft()
            .scale(scaleY);

        svg.append('g')
            .attr('transform', `translate(${dx}, ${height - xaxisHeight})`)
            .style('color', 'white')
            .call(xaxis);
        svg.append('g')
            .attr('transform', `translate(${dx}, ${0})`)
            .style('color', 'white')
            .call(yaxis);
    }

    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate(pervProps) {
        if(JSON.stringify(pervProps.data) !== JSON.stringify(this.props.data)) {
            this.drawChart();
        }
    }

    render() {
        const { parentHeight, parentWidth } = this.props;
        return (
            <React.Fragment>
                <div ref={this.tooltipRef} className="tooltip"></div>
                <div ref={this.chartRef} style={{position: 'relative', width: parentWidth, height: parentHeight}}></div>
            </React.Fragment>
        )
    }
}

HorizontalBarChart.defaultProps = {
    data: [],
    barHeight: 20,
    padding: 5,
    dx: 80,
    tooltipTemplate: undefined,
    widthOffset: 20,
    heightOffset: 20,
    width: undefined,
    height: undefined,
    aspectRatio: 'xMinYMin meet',
    parentWidth: '100%',
    parentHeight: undefined
}