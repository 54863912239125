import * as React from "react";
import { HashRouter, Route, Redirect } from 'react-router-dom'
import HeaderComponent from "./js/component/HeaderComponent";
import FooterComponent from "./js/component/FooterComponent";
import NavigationComponent from "./js/component/NavigationComponent";
import UserComponent from "./js/component/UserComponent/UserComponent";
import ZipReportComponent from "./js/component/ZipReportComponent/ZipReportComponent";
import UploadComponent from "./js/component/UploadComponent/UploadComponent";
import MasterComponent from "./js/component/MasterComponent/MasterComponent";
import ReportComponent from "./js/component/ReportComponent";
import LookupComponent from "./js/component/MFGLookupComponent/LookupComponent";
import LoginPage from "./js/page/LoginPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import LoadingComponent from "./js/component/LoadingComponent";
import UploadVerification from "./js/component/UploadVerification/UploadVerification";
import Dashboard from './js/component/Dashboard/Dashboard';
import Home from "./js/component/Home/Home";
import HeatMap from "./js/component/HeatMap/HeatMap";
import InnerLoader from "./js/component/InnerLoader";
import Expense from "./js/component/Expense/Expense";
import Budget from "./js/component/Budget/Budget";
import Constants from './js/util/Constants';
import SAMDashboard from "./js/component/SAMDashboard/SAMDashboard";
import './App.css'

class App extends React.Component {

    render() {
        const { enabledScreens, showLoader, userDetails } = this.props;
        const isLoggedOut = JSON.stringify(userDetails) === '{}';
        return (<div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            /><ToastContainer />
            {(showLoader && isLoggedOut) &&
                <LoadingComponent />
            }
            { isLoggedOut ?
                <LoginPage />
                :
                <HashRouter>
                    <HeaderComponent />
                    <NavigationComponent enabledScreens={enabledScreens} userDetails={userDetails} />
                    <div className="tab-content" style={{position: 'relative'}} id="nav-tabContent">
                        { showLoader &&
                            <InnerLoader />
                        }
                        { enabledScreens.includes('USERS') &&
                            <Route exact path="/" component={_=><Redirect to={'nav-'+(userDetails.homeScreen || Constants.defaultHomeScreen)} />} />
                        }
                        { enabledScreens.includes('HOME') &&
                            <Route exact path="/nav-home" component={Home} />
                        }
                        { enabledScreens.includes('USERS') &&
                            <Route exact path="/nav-user" component={UserComponent} />
                        }
                        { enabledScreens.includes('ZIP_REP') &&
                            <Route exact path="/nav-zip" component={ZipReportComponent} />
                        }
                        { enabledScreens.includes('UPLOAD_DATA') &&
                            <Route exact path="/nav-upload" component={UploadComponent} />
                        }
                        { enabledScreens.includes('MASTER') &&
                            <Route exact path="/nav-master" component={MasterComponent} />
                        }
                        { enabledScreens.includes('REPORTS') &&
                            <Route exact path="/nav-report" component={ReportComponent} />
                        }
                        { enabledScreens.includes('LOOKUP') &&
                            <Route exact path="/nav-lookup" component={LookupComponent} />
                        }
                        { enabledScreens.includes('HEAT_MAP') &&
                            <Route exact path="/nav-heatmap" component={HeatMap} />
                        }
                        { enabledScreens.includes('UPLOAD_VERIFY') &&
                            <Route exact path="/nav-verify" component={UploadVerification} />
                        }
                        { enabledScreens.includes('DASHBOARD') &&
                            <Route exact path="/nav-dashboard" component={Dashboard} />
                        }
                        { enabledScreens.includes('SAM_DASHBOARD') &&
                            <Route exact path="/nav-samdashboard" component={SAMDashboard} />
                        }
                        { enabledScreens.includes('EXPENSE') &&
                            <Route exact path="/nav-expense" component={Expense} />
                        }
                        { enabledScreens.includes('BUDGET') &&
                            <Route exact path="/nav-budget" component={Budget} />
                        }
                    </div>
                </HashRouter>
            }
            <FooterComponent />
        </div>
        );
    }

}

const mapStateToProps = state => {
    const { showLoader, userDetails, privileges } = state;
    return {
        showLoader,
        userDetails,
        enabledScreens: privileges.readable
    }
}

export default connect(mapStateToProps)(App);

