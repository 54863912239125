import React from 'react';

export default class SpecialSelect extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { onChange } = this.props;
        onChange(event.target.value);
    }

    render() {
        const { entries, value, label } = this.props;
        return(
            <select className="select special-select" onChange={this.handleChange} value={value}>
                <option value=''>{label}</option>
                {
                    entries.map((entry, key)=>{
                        return(
                            <option key={key} value={entry.value}>{entry.label}</option>
                        )
                    })
                }
            </select>
        )
    }
}

SpecialSelect.defaultProps = {
    entries: [],
    onChange: ()=>{},
    value: undefined,
    label: ''
}