import * as React from "react";
import SimpleUploadComponent from '../SimpleUploadComponent/SimpleUploadComponent';
import UploadIcon from '../../../images/icon-upload.ico';
import DownloadIcon from '../../../images/icon-save.png';
import MFGLookupTable from './MFGLookupTable';
import './LookupComponent.css';
import MFGSearchBox from "./MFGSearchBox";
import Save from '../../../images/icon-save.png';
import Button from '../ZipReportComponent/Button';
import Constants, { downloadFile } from '../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';
import AddAndSaveButtons from '../MasterComponent/AddAndSaveButtons';
import Manufacturers from "../Manufacturers";

class LookupComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
            manufacturers: [],
            distributors: []
        }
        this.mfgLookupTableRef = React.createRef();
    }

    saveMfgLookupData() {
        const table = this.mfgLookupTableRef.current;
        if(table) {
            table.saveMfgLookupData();
        }
    }

    addRow() {
        const table = this.mfgLookupTableRef.current;
        if(table) {
            table.addRow();
        }
    }

    /**
     * Function to perform search operation in data
     * 
     * @param {object} search - object containing search params
     */
    search(search) {
        const searchString = Object.keys(search).reduce((total, current)=>total+=`&${current}=${search[current]}`, '');
        this.setState({
            searchString
        })
    }

    render() {
        const { searchString, distributors, manufacturers } = this.state;
        const { dispatch, editable } = this.props;
        return (
            <div className="tab-pane show active" id="nav-lookup" role="tabpanel" aria-labelledby="nav-lookup-tab">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8">
                            <MFGSearchBox
                                onSearch={(search)=>this.search(search)}
                                onManufacturersFetchComplete={manufacturers=>{
                                    this.setState({
                                        manufacturers: manufacturers.map(mfg=>mfg.mfgName)
                                    })
                                }}
                                onDistributorsFetchComplete={distributors=>{
                                    this.setState({
                                        distributors: distributors.map(dist=>dist.distributionName)
                                    })
                                }}
                            />
                        </div>
                        <div className="col-sm-4">
                            <div className="row zip-update-button-container">
                                <SimpleUploadComponent label="UPLOAD" image={{
                                        src: UploadIcon,
                                        width: 20,
                                        height: 20
                                    }}
                                    uploadUrl={`${Constants.baseURL}${Constants.mfgLookupUploadFile}`}
                                    disabled={!editable}
                                />
                                <Button
                                    onClick={()=>{
                                        dispatch(showLoader(true));
                                        const url = `${Constants.baseURL}${Constants.downloadMfgLookupData}`;
                                        downloadFile(url, undefined, 'xlsx')
                                            .finally(()=>{
                                                dispatch(showLoader(false));
                                            })
                                    }}
                                    icon={DownloadIcon}
                                    label='Download'
                                    dimension='20'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <MFGLookupTable
                                ref={this.mfgLookupTableRef}
                                searchString={searchString}
                                manufacturers={manufacturers}
                                distributors={distributors}
                                editable={editable}
                            />
                        </div>
                    </div>
                    <AddAndSaveButtons
                        onAddClick={()=>{
                            this.addRow();
                        }}
                        onSaveClick={()=>{
                            this.saveMfgLookupData();
                        }}
                        disabled={!editable}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('LOOKUP')
})

export default connect(mapStateToProps)(LookupComponent);