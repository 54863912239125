import React from 'react';
import './ProductFamilyManagement.css';
import FamilyCodeEditor from './FamilyCodeEditor/FamilyCodeEditor';
import FamilyCodeMapper from './FamilyCodeMapper/FamilyCodeMapper';
import UploadIcon from '../../../../images/icon-upload.ico';
import DownloadIcon from '../../../../images/icon-save.png';
import Button from '../../ZipReportComponent/Button';
import SimpleUploadComponent from '../../SimpleUploadComponent/SimpleUploadComponent';
import Constants, { downloadFile } from '../../../util/Constants';
import { showLoader } from '../../../../redux/actions';
import { connect } from 'react-redux';

class ProductFamilyManagement extends React.Component {

    render() {
        const { dispatch, editable } = this.props;
        return(
            <div className="container-fluid">
                <div className="row zip-update-button-container">
                    <SimpleUploadComponent
                        uploadUrl={`${Constants.baseURL}${Constants.uploadProdFamilyData}`}
                        image={{
                            src: UploadIcon,
                            width: 20,
                            height: 20
                        }}
                        label="UPLOAD"
                        disabled={!editable}
                    />
                    <Button
                        onClick={() => {
                            dispatch(showLoader(true));
                            downloadFile(`${Constants.baseURL}${Constants.downloadProdFamilyData}`, undefined, 'xlsx')
                                .finally(()=>{
                                    dispatch(showLoader(false));
                                })
                        }}
                        icon={DownloadIcon}
                        label='Download'
                        dimension='20'
                    />
                </div>
                <div className="row prod-family-management-container">
                    <div className="col-sm-8">
                        <FamilyCodeMapper
                            editable={editable}
                            showLoader={loaderState=>dispatch(showLoader(loaderState))}
                        />
                    </div>
                    <div className="col-sm-4 family-code-editor">
                        <FamilyCodeEditor
                            editable={editable}
                            showLoader={loaderState=>dispatch(showLoader(loaderState))}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('MASTER')
})

export default connect(mapStateToProps)(ProductFamilyManagement);