import React from 'react';
import './FileCard.css';
import Constants from '../../../util/Constants';
import axios from 'axios';

/**
 * This component renders one single file and it's progress bar
 */
export default class FileCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progressPercent: 0
        }
    }

    componentDidMount() {
        const { file } = this.props;
        if(!file) {
            return;
        }
        const data = new FormData();
        data.append('file', file);
        this.upload(data);
    }

    // This function uploads the a file to the database
    upload(data) {
        const {manufacturer, distributor, multiDistrSpec, refreshHistoryTable} = this.props;
        let path = Constants.fileUpload;
        path = path.replace('{distributor}', distributor);
        path = path.replace('{mfg}', manufacturer);
        path = path.replace('{multiDistrSpec}', multiDistrSpec);
        const url = `${Constants.baseURL}${path}`

        axios.request({
            method: 'POST',
            url,
            data,
            onUploadProgress: (progress) => {
                const progressPercent = parseInt(progress.loaded*100/progress.total);
                this.setState({
                    progressPercent
                });
            }
        }).then(response => {
            console.log(response);
            refreshHistoryTable();
        }).catch(error => {
            console.log(error);
            this.setState({
                progressPercent: 100,
                error: true
            });
            refreshHistoryTable();
        })
    }

    render() {
        const { file } = this.props;
        const { progressPercent, error } = this.state;
        return(
                <div className="file-card">
                    <div>
                        <h5>{file.name}</h5>
                        <code>{Number(parseInt(file.size)/(1024*1024)).toFixed(3)} MB</code>
                    </div>
                    <div className="file__progress">
                        <div className="progress">
                            <div className={"progress-bar progress-bar-striped progress-bar-animated "+(error? 'bg-danger' :'bg-warning')} style={{width: progressPercent+'%'}} >
                                {error ? 'An error has occured' : progressPercent+'%'}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

FileCard.defaultProps = {
    refreshHistoryTable: ()=>{}
}