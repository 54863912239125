import React from 'react';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import  TableBody from '../../ZipReportComponent/TableBody';

const columns = [
    {
        label: 'Distributor Name',
        key: 'distributionName',
        nosort: true
    },
    {
        label: 'HQ Name',
        key: 'hqName',
        nosort: true
    },
    {
        label: 'Status',
        key: 'status',
        type: 'boolean',
        nosort: true
    },
	{
        label: 'isStrategic',
        key: 'isStrategic',
        type: 'boolean',
        nosort: true
    },
]

export default class DistributorsTable extends React.Component {

    incaseSensitiveSearch(string1, string2) {
        return String(string1).toUpperCase().startsWith(String(string2).toUpperCase());
    }

    filter(data=[]) {
        const { distributor } = this.props;
        return data.filter(row=>(distributor === '' || this.incaseSensitiveSearch(row.distributionName, distributor)));
    }

    render() {
        const { data, onDataChange, deleteRow, editable, resetEditSwitch } = this.props;
        return(
            <div className="listing-grid table-responsive table-container mfg-lookup-table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders columns={columns} />
                            {editable && <th className="history-table-header" scope="col" >Action</th >}
                        </tr>
                    </thead>
                    <TableBody
                        columns={columns}
                        data={this.filter(data)}
                        onDataChange={onDataChange}
                        hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                            if(String(currentRow.id).startsWith('temp')) {
                                resultArray.push(index);
                            }
                            return resultArray;
                        }, [])}
                        deleteHistory={deleteRow}
                        disableActions={!editable}
                        resetEditSwitch={resetEditSwitch}
                    />
                </table>
            </div>
        )
    }
}

DistributorsTable.defaultProps = {
    data: [],
    onDataChange: ()=>{},
    deleteRow: ()=>{},
    editable: true
}