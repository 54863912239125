import React from 'react';
import './BudgetDetails.css';
import { numberWithComas } from '../../../util/Constants';
import * as d3 from 'd3';

export default class BudgetDetails extends React.Component {

    constructor(props) {
        super(props);
        this.expenseRef = React.createRef();
        this.budgetRef = React.createRef();
        this.currentYear = new Date().getFullYear();
    }

    componentDidMount() {
        this.setupTooltip()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.budget !== this.props.budget || prevProps.expense !== this.props.expense) {
            this.setupTooltip()
        }
    }

    setupTooltip() {
        const { mouseover, mousemove, mouseleave, expense, budget } = this.props;
        const expenseBar = this.expenseRef.current;
        d3.select(expenseBar)
            .on("mouseover", mouseover)
            .on("mousemove", _ => mousemove(`
                <div>
                    <svg width='10px' height='10px'>
                        <rect width='10px' height='10px' fill='goldenrod'></rect>
                    </svg>
                    Expense : ${numberWithComas(expense)}
                </div>
                <div>
                    <svg width='10px' height='10px'>
                        <rect width='10px' height='10px' fill='olivedrab'></rect>
                    </svg>
                    Budget : ${numberWithComas(budget)}
                </div>
            `))
            .on("mouseleave", mouseleave)
        const budgetBar = this.budgetRef.current;
        d3.select(budgetBar)
            .on("mouseover", mouseover)
            .on("mousemove", _ => mousemove(`
                <div>
                    <svg width='10px' height='10px'>
                        <rect width='10px' height='10px' fill='goldenrod'></rect>
                    </svg>
                    Expense : ${numberWithComas(expense)}
                </div>
                <div>
                    <svg width='10px' height='10px'>
                        <rect width='10px' height='10px' fill='olivedrab'></rect>
                    </svg>
                    Budget : ${numberWithComas(budget)}
                </div>
            `))
            .on("mouseleave", mouseleave)
    }

    render() {
        const { expense, budget } = this.props;
        let expensePercent = Math.floor(expense * 100 / budget);
        let budgetPercent = 100 - expensePercent;
        if (expensePercent > 100) {
            expensePercent = 100;
            budgetPercent = 0;
        }
        return (
            <table className="budget-table">
                <tbody>
                    <tr>
                        <td className="inner-budget-table">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Budget {this.currentYear}</th>
                                        <td>{numberWithComas(budget)}</td>
                                    </tr>
                                    <tr>
                                        <th>YTD Expense</th>
                                        <td>{numberWithComas(expense)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <div ref={this.expenseRef} className="expense-bar" style={{ width: `${expensePercent}%` }}>
                                {numberWithComas(expense)}
                            </div>
                            <div ref={this.budgetRef} className="budget-bar" style={{ width: `${budgetPercent}%` }}>
                                {numberWithComas(budget)}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

BudgetDetails.defaultProps = {
    mouseleave: () => { },
    mouseover: () => { },
    mouseleave: () => { }
}