import React from 'react';
import Distributors from '../../../Distributors';
import TableHeaders from '../../../ZipReportComponent/TableHeaders';
import TableBody from '../../../ZipReportComponent/TableBody';
import Constants, { toastOptions } from '../../../../util/Constants';
import AddAndSaveButtons from '../../AddAndSaveButtons';
import Popup from '../../../Popup/Popup';
import { toast } from 'react-toastify';

const columns = [
    {
        label: 'Distributor',
        nosort: true,
        key: "distributor"
    }, {
        label: 'Partner Number',
        nosort: true,
        key: "distrPartNo"
    }, {
        label: 'Manufacturer Number',
        nosort: true,
        key: "mfgPartNo"
    }, {
        label: 'Family Code',
        nosort: true,
        key: "familyCode"
    }, {
        label: 'Active',
        nosort: true,
        key: "enabled",
        type: 'boolean'
    }
]

export default class FamilyCodeMapper extends React.Component {

    static count = 0;

    constructor(props) {
        super(props);
        this.state={
            data: [],
            showPopup: false,
            resetEditSwitch: false
        }
        this.tableBodyRef = React.createRef();
        this.distributor = '';
        this.familyCode = '';
        this.partNo = '';
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const searchString = ['distributor', 'familyCode', 'partNo'].reduce((total, current)=>{
            if(!this[current]) {
                return total;
            }
            return(
                `${total}&${current}=${this[current]}`
            )
        }, '');
        const url = `${Constants.baseURL}${Constants.getProdFamilyMapper}?${searchString}`;
        const { showLoader } = this.props;
        showLoader(true);
        fetch(url)
        .then(response=>response.json())
        .then(data=>{
            this.setState({
                data: data.response
            })
        }).catch(error=>{
            console.log(error);
        }).finally(()=>{
            showLoader(false);
        })
    }

    addData() {
        const dummyData = {
            distrPartNo: "",
            distributor: "",
            enabled: true,
            familyCode: "",
            id: `temp${FamilyCodeMapper.count++}`,
            mfgPartNo: ""
        }

        const { data } = this.state;
        data.push(dummyData);
        this.setState({
            data
        })
    }

    saveData() {
        if(JSON.stringify(this.newData)==='[]')
            return;
        this.newData.forEach(row=>{
            if(String(row.id).startsWith('temp')) {
                delete row.id;
            }
        })
        const url = `${Constants.baseURL}${Constants.postProdFamilyMapper}`;
        const { showLoader } = this.props;
        showLoader(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(this.newData)
        }).then(response=>{
            if(response.status !== 204) {
                throw new Error();
            }
            this.tableBodyRef.current.clearEditedValues();
            toast.success("Success: Save success", toastOptions);
            this.setState({
                resetEditSwitch: !this.state.resetEditSwitch
            })
        }).catch(error=>{
            console.log(error);
            toast.error("Error: Save failed", toastOptions);
        }).finally(()=>{
            showLoader(false);
            this.fetchData();
        })
    }

    deleteData(id) {

        // this function is to prevent unsaved data loss after the data fetch of delete api call
        const deleteData = ()=>{
            const { data } = this.state;
            const newData = data.filter(row=>row.id !== id);
            this.setState({
                data: newData
            })
        }

        if(String(id).startsWith('temp')) {
            deleteData();
            return;
        }
        
        const params = Constants.deleteProdFamilyMapper.replace('{id}', id);
        const url = `${Constants.baseURL}${params}`

        const { showLoader } = this.props;
        showLoader(true);

        fetch(url, {
            method: 'DELETE'
        }).then(response=>{
            if(response.status !== 204) {
                throw new Error()
            }
            toast.success('Success: Record deleted', toastOptions);
            deleteData();
        }).catch(error=>{
            console.log(error);
            toast.error("Error: Delete failed", toastOptions);
        }).finally(()=>{
            showLoader(false);
        })
    }

    /**
     * Higher order function to close popup
     * 
     * @param {function} arg - function to execute
     */
    closePopup(arg = ()=>{}) {
        this.setState({
            showPopup: false
        })
        arg()
    }

    render() {
        const { data, showPopup, resetEditSwitch } = this.state;
        const { editable } = this.props;
        return (
            <React.Fragment>
                { showPopup &&
                    <Popup
                        onYes={()=>{
                            this.closePopup(this.deleteData(this.idToDelete));
                        }}
                        onNo={()=>{
                            this.closePopup();
                        }}
                    />
                }
                <div className="row">
                    <div className="col-sm-3">
                        <Distributors
                            onChange={(event) => {
                                this.distributor = event.target.value
                            }}
                        />
                    </div>
                    <div className="col-sm-3">
                        <input type="text" className="txt form-control" placeholder="Part Number"
                            onChange={(event) => {
                                this.partNo = event.target.value
                            }}
                        />
                    </div>
                    <div className="col-sm-3">
                        <input type="text" className="txt form-control" placeholder="Family Code"
                            onChange={(event) => {
                                this.familyCode = event.target.value
                            }}
                        />
                    </div>
                    <div className="col-sm-3 family-mapper-search">
                        <button
                            className="button"
                            onClick={() => {
                                this.fetchData();
                            }}
                        >Search</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="listing-grid table-responsive table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <TableHeaders columns={columns} />
                                        {editable && <th className="history-table-header" scope="col" >Action</th >}
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableBody
                                        ref={this.tableBodyRef}
                                        columns={columns}
                                        data={data}
                                        deleteHistory={(id)=>{
                                            this.idToDelete = id;
                                            this.setState({
                                                showPopup: true
                                            })
                                        }}
                                        hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                                            if(String(currentRow.id).startsWith('temp')) {
                                                resultArray.push(index);
                                            }
                                            return resultArray;
                                        }, [])}
                                        onDataChange={(newData)=>{
                                            this.newData = newData;
                                        }}
                                        disableActions={!editable}
                                        resetEditSwitch={resetEditSwitch}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={()=>{
                        this.addData();
                    }}
                    onSaveClick={()=>{
                        this.saveData();
                    }}
                    disabled={!editable}
                />
            </React.Fragment>
        )
    }
}

FamilyCodeMapper.defaultProps = {
    editable: true
}