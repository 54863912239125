import * as React from "react";
import { Select, FormControl, MenuItem, Input, withStyles, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import Constants, {toastOptions, constructErrorBody, CustomTextField, WheatSwitch} from '../../util/Constants';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';
import Active from '../../../images/icon-active.png';
import Disabled from '../../../images/icon-disabled.png';
import Save from '../../../images/icon-save.png';
import Search from '../../../images/icon-search.png';
import AddUser from '../../../images/icon-add-user.png';
import AddUserModal from './AddUserModal';
import 'bootstrap';
import Popup from "../Popup/Popup";
import TableHeaers from '../ZipReportComponent/TableHeaders';

const styles = theme => ({
    underline: {
        borderBottom: '2px solid white',
        '&:after': {
            borderBottom: '2px solid white',
        },
        '&:hover': {
            borderBottom: '2px solid wheat'
        },
    },
    disabled: {
        borderBottom: '1px solid gray',
        '&:hover': {
            borderBottom: '1px solid gray'
        }
    }
})

const columns = [
    {
        label: '#',
        nosort: true
    },
    {
        label: 'User Name',
        nosort: false,
        key: 'userName'
    },
    {
        label: 'Role',
        nosort: false,
        key: 'roleName'
    },
    {
        label: 'Status',
        nosort: true
    },
    {
        label: 'Email',
        nosort: true
    },
    {
        label: 'Login ID',
        nosort: true
    },
    {
        label: 'Password',
        nosort: true
    },
    {
        label: 'Last Logged In',
        nosort: false,
        key: 'lastLoggedIn'
    }
]

const PasswordField = CustomTextField;

class UserComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            filteredUsers: [],
            roles: [],
            enableEdit: [],
            showPopup: false,
            sortBy: 'userId',
            sortDirection: 'ascending'
        };
        this.searchRef = React.createRef();
        this.editedUserIds = new Set();
    }

    componentDidMount() {
        this.loadRoles();
    }

    loadRoles() {
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.getAllRoles}`;
        fetch(url)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    roles: res
                })
                this.loadUserData();
            })
            .catch(error => {
                dispatch(showLoader(false));
                console.log('Error: ' + error);
            })
    }

    loadUserData() {
        const { dispatch } = this.props;
        const url = `${Constants.baseURL}${Constants.getAllUsers}`;
        fetch(url)
            .then(res => res.json()) // It resolves the promise with a JSON object
            .then(res => {
                dispatch(showLoader(false));
                // console.log(res) // make sure that matches is `res` directly or a neste object within `res` object
                this.setState({
                    users: res,
                    filteredUsers: res,
                })
            }).catch(error => {
                dispatch(showLoader(false));
                console.log('Error: ' + error);
            });
    }

    saveAllChanges() {
        if(this.editedUserIds.size === 0) {
            return;
        }
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.updateUsers}`;
        const { users } = this.state;
        const body = JSON.stringify(users.filter(user=>{
            return this.editedUserIds.has(user.userId);
        }).map(user => ({
            roleId: user.roleId,
            roleName: user.roleName,
            status: user.status,
            userName: user.userName
        })));
        const headers = {
            'Content-Type': 'application/json',
        }
        fetch(url, {
            method: 'PUT',
            body,
            headers
        })
            .then(res=>{
                dispatch(showLoader(false));
                console.log(res)
                if(res.status===204)
                    return res;
                return res.json();
            })
            .then(jsonData => {
                if(jsonData.status===204) {
                    this.setState({
                        enableEdit: []
                    })
                    toast.success("Success: User data saved successfully", toastOptions);
                } else
                    toast.error(constructErrorBody(jsonData.status, jsonData.error, jsonData.message), toastOptions);
            })
            .catch(error => {
                dispatch(showLoader(false));
                console.log('Error: ' + error);
            })
    }

    deleteUser() {
        if(!this.userToDelete) {
            return;
        }
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const params = Constants.deleteUser.replace('{userId}', this.userToDelete.userId);
        const url = `${Constants.baseURL}${params}`;
        fetch(url, {
            method: 'DELETE'
        }).then(response=>{
            console.log(response);
            if(response.status !== 204) {
                throw new Error();
            }
            dispatch(showLoader(false));
            toast.success("Success: User removed", toastOptions);
            this.loadUserData();
        }).catch(error=>{
            dispatch(showLoader(false));
            this.loadUserData();
            console.log(error);
            toast.error("Error: Operation failed", toastOptions);
        })
    }

    updateUserDetails(newUserDetails) {
        const { users, filteredUsers } = this.state;
        const usersUpdateLocation = users.findIndex(user=>user.userId === newUserDetails.userId);
        users[usersUpdateLocation] = newUserDetails;
        const filteredUsersUpdateLocation = filteredUsers.findIndex(user=>user.userId === newUserDetails.userId);
        filteredUsers[filteredUsersUpdateLocation] = newUserDetails;
        this.setState({
            users,
            filteredUsers
        })
    }

    populateUserData() {
        // console.log("final....." + this.state.users)
        const { roles, enableEdit, filteredUsers, sortBy, sortDirection } = this.state;
        const { classes, editable } = this.props;
        return filteredUsers.sort((a,b)=>{
            if(sortDirection === 'ascending')
                return String(a[sortBy]).localeCompare(String(b[sortBy]))
            return String(b[sortBy]).localeCompare(String(a[sortBy]))
        }).map((user, index) => {

            //console.log("final"+user[index].userId);
            return (<tr key={index}>
                <th scope="row">{user.userId}</th>
                <td>{user.firstName + ' ' + user.lastName}</td>
                <td>
                    <FormControl className="select-input" disabled={!enableEdit[user.userId]} >
                        <Select
                            className={'select-input'}
                            value={user.roleId || ''}
                            onChange={(event) => {
                                const roleId = event.target.value;
                                const role = roles.find(role => role.roleId === roleId);
                                user.roleId = roleId;
                                user.roleName = role.roleName;
                                this.editedUserIds.add(user.userId);
                                this.updateUserDetails(user);
                            }}
                            input={<Input classes={{
                                underline: classes.underline,
                                disabled: classes.disabled,
                            }} />}
                        >
                            {
                                roles.map(role =>
                                    <MenuItem key={role.roleName} value={role.roleId}>{role.roleName}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    {/* {user.roleName} */}
                </td>
                <td>
                    <FormControl className="select-input">
                        <FormControlLabel
                            disabled={!enableEdit[user.userId]}
                            control={
                                <WheatSwitch
                                    checked={JSON.parse(user.status)}
                                    color="default"
                                    onChange={(event) => {
                                        user.status = event.target.checked;
                                        this.editedUserIds.add(user.userId);
                                        this.updateUserDetails(user);
                                    }}
                                />
                            }
                            label={JSON.parse(user.status) ? "Active\u00A0\u00A0 " : "Inactive"}
                            className="status-switch"
                        />
                        <img src={JSON.parse(user.status) ? Active : Disabled} width="30px" height="30px" alt="status" className="status-icon" />
                    </FormControl>
                </td>
                <td>{user.email}</td>
                <td>{user.userName}</td>
                <td className="password-column">
                    <PasswordField
                        fullwidth
                        disabled={!enableEdit[user.userId]}
                        value={user.password}
                        onFocus={()=>{
                            filteredUsers[filteredUsers.findIndex(ob=>ob.userId === user.userId)].password = '';
                            this.setState({
                                filteredUsers
                            })
                        }}
                        onChange={(event)=>{
                            user.password = event.target.value;
                            this.editedUserIds.add(user.userId);
                            this.updateUserDetails(user);
                        }}
                    />
                </td>
                <td>{user.lastLoggedIn}</td>
                {editable && <td className='user-actions'>
                    <Tooltip title="Delete user">
                        <span
                            className="delete"
                            onClick={()=>{
                                this.userToDelete = user;
                                this.setState({
                                    showPopup: true
                                })
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Toggle edit">
                        <span className="edit" onClick={() => {
                            const { enableEdit } = this.state;
                            enableEdit[user.userId] = !enableEdit[user.userId];
                            this.setState({
                                enableEdit
                            })
                        }} />
                    </Tooltip>
                </td>}
            </tr>)
        })
    }

    searchUser() {
        const searchString = this.searchRef.current.value.toLowerCase();
        const { users } = this.state;
        let filteredUsers = [];
        if(searchString === '') {
            filteredUsers = users;
        } else {
            filteredUsers = users.filter(user=>{
                const userName = (user.firstName + ' ' + user.lastName).toLowerCase();
                return userName.startsWith(searchString);
            });
        }
        this.setState({
            filteredUsers
        });
    }

    /**
     * 
     * @param {function} arg - call back function
     */
    closePopup(arg = ()=>{}) {
        this.setState({
            showPopup: false
        })
        arg();
    }

    render() {
        const { showPopup, sortBy, sortDirection } = this.state;
        const { editable } = this.props;
        return (
            <div className="tab-pane fade users show active" id="nav-user" role="tabpanel"
                aria-labelledby="nav-user-tab">
                { showPopup &&
                    <Popup
                        onYes={()=>this.closePopup(this.deleteUser())}
                        onNo={()=>this.closePopup()}
                        message="Do you want to delete this user?"
                    />
                }
                <div >
                    <div className="txt-wrap user-table-search input-group" width="50%">
                        <input ref={this.searchRef} type="text" className="txt form-control" placeholder="Search for User" onChange={()=>this.searchUser()} />
                        <div className="input-group-append">
                            <button onClick={()=>this.searchUser()} className="btn search-button" type="submit"><img src={Search} alt="search" width="20" height="20" /></button>  
                        </div>
                    </div>
                   
                </div>
                <div className="listing-grid table-responsive table-container">
                    <table className="table">
                        <thead>
                            <tr>
                                <TableHeaers
                                    columns={columns}
                                    sortedColId={columns.findIndex(item=>item.key === sortBy)}
                                    sortTable={(column)=>{
                                        if(column.key === sortBy && sortDirection === 'ascending') {
                                            this.setState({
                                                sortDirection: 'descending'
                                            })
                                        } else {
                                            this.setState({
                                                sortBy: column.key,
                                                sortDirection: 'ascending'
                                            })
                                        }
                                    }}
                                    sortOrderAscending={sortDirection === 'ascending'}
                                />
                                {editable && <th scope="col">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredUsers.length > 0 && this.populateUserData()}
                        </tbody>
                    </table>
                    {this.state.filteredUsers.length === 0 && <div className="text-lg-center">No data</div>}
                </div>
                <div className="btn-wrap">
                    <button className="button add-user-button" data-toggle="modal" data-target="#addUserModal" disabled={!editable} >
                        <img src={AddUser} alt="save" width="20" height="20" />&nbsp;&nbsp;Add User
                    </button>
                    <button className="button" type="button" disabled={!editable} onClick={(() => {
                        this.saveAllChanges();
                    })}><img src={Save} alt="save" width="20" height="20" />&nbsp;&nbsp;Save
                    </button>
                </div>
                <AddUserModal
                    loadUserData={()=>this.loadUserData()}
                />
            </div>
        )
    }

}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('USERS')
})

export default withStyles(styles)(connect(mapStateToProps)(UserComponent));