import * as React from "react";
import cross from '../../images/icon-cross.png';
import acro from "../../images/icon-acro.png";
import { getDistributors, toastOptions, downloadFile } from '../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../redux/actions';
import Constants from '../util/Constants';
import { toast } from 'react-toastify';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { WheatSwitch } from '../util/Constants';
import FilterDropdown from './FilterComponents/FilterDropdown/FilterDropdown';
import '../../index.css'
import Multiselect from "./MultiselectComponents/Multiselect";
import Manufacturers from './Manufacturers';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
const today = new Date();

class ReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.monthOrQuarter = months[today.getMonth()];
        this.state = {
            distributors: [],
            distributor: '',
            month: '',
            year: '',
            monthOrQuarter: this.monthOrQuarter,
            reportName: '',
            type: [],
            otherDistributor: [],	
            otherDistributors: [],
            hqfilter: true,
            manufacturers: []
        }
    }

    async componentDidMount() {
        const { dispatch } = this.props;
        try {
            dispatch(showLoader(true));
            const distributors = await getDistributors();
            const otherDistributors =  await this.fetchData();
            this.setState({
                distributors,
                otherDistributors
            })
        } catch (error) {
            console.log(error);
        }
        dispatch(showLoader(false));
    }

    fetchData = async () => {
        return new Promise((resolve, reject)=>{
            const url = `${Constants.baseURL}${Constants.getHQFilterDistributors}`
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                return response.json();
            }).then(jsonData=>{
                resolve(jsonData);
            }).catch(error=>{
                console.log(error);
                reject(error);
            })
        });
    }

    generateExcel() {
        const { distributor, month, monthOrQuarter, year, reportName, hqfilter, otherDistributor, type, manufacturers } = this.state;
        const { dispatch} = this.props;
        if (monthOrQuarter === '' || year === '' || reportName === '') {
            toast.error("Please choose a distributor, month, year and report name to generate excel", toastOptions);
            return;
        }
        dispatch(showLoader(true));
        let downloadUrl;
        if(reportName === 'MfgTotals') {
            downloadUrl = Constants.downloadMfgTotalsReport;	
         } else if(reportName === 'CombinedSalesReport') {
            downloadUrl = Constants.downloadCombinedSalesReport;	
         } else {	
            downloadUrl = Constants.downloadMonthEndMasterReport;	
         }
        let url = `${Constants.baseURL}${downloadUrl}?reportName=${reportName}&year=${year}&distributor=${distributor}&filterHq=${hqfilter}&otherDistributor=${otherDistributor.join(',')}&type=${type.join(',')}&mfg=${manufacturers.join(',')}`;	
        if(months.map(month=>month).includes(monthOrQuarter)) {	
           url = `${url}&month=${monthOrQuarter}`	
        } else {	
           url = `${url}&quarter=${monthOrQuarter}`	
        }	
        downloadFile(url)
        .finally(()=>{
            dispatch(showLoader(false))
        })
    }

    render() {
        const { distributors, reportName, distributor, monthOrQuarter, hqfilter, otherDistributors, type, manufacturers,otherDistributor } = this.state;
        const { privileges } = this.props;
        const showHQFilter = distributor === 'Others';
        const othersAdditionalDropdowns = distributor === 'Others';
        return (
            <div className="tab-pane report show active" id="nav-report" role="tabpanel" aria-labelledby="nav-report-tab">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form">
                            <select className="select special-select" onChange={(event) => {
                                this.setState({
                                    distributor: event.target.value
                                })
                            }}>
                                <option value=''>Choose distributor *</option>
                                {
                                    distributors.filter(distributor => ['Grainger', 'MSC', 'Fastenal'].includes(distributor.distributionName)).map(distributor =>
                                        <option key={distributor} value={distributor.distributionName}>{distributor.distributionName}</option>
                                    )
                                }
                                <option value='Others'>Others</option>
                            </select>
                            <h3 className="report-header">Filter Criteria:</h3>
                            <select className="select special-select report-month-selector" value={monthOrQuarter} onChange={(event) => {
                                this.setState({
                                    monthOrQuarter: event.target.value
                                })
                            }}>
                                <optgroup label="Quarters">
                                    {
                                        quarters.map(quarter => <option key={quarter} value={quarter}>{quarter}</option>)
                                    }
                                </optgroup>
                                <optgroup label="Months">
                                    {
                                        months.map(month => <option key={month} value={month}>{month}</option>)
                                    }
                                </optgroup>
                            </select>
                            <select className="select special-select report-month-selector" onChange={(event) => {
                                this.setState({
                                    year: event.target.value
                                })
                            }}>
                                <option value=''>Choose year *</option>
                                <option value={2019}>2019</option>
                                <option value={2020}>2020</option>
								<option value={2021}>2021</option>
                                <option value={2022}>2022</option>
                                <option value={2023}>2023</option>
								<option value={2024}>2024</option>
                            </select>

                                    <select className="select special-select report-month-selector" value={reportName} onChange={(event) => {
                                        this.setState({
                                            reportName: event.target.value
                                        })
                                    }}>
                                        <option value=''>Choose report name</option>
                                        { privileges.readable.includes('MONTH_END_MASTER') &&
                                        <option value='MonthEndMaster'>MonthEndMaster</option>}
                                        { privileges.readable.includes('MFG_TOTALS') &&
                                        <option value='MfgTotals'>MfgTotals</option>}
                                        { privileges.readable.includes('MONTH_END_MASTER_FOR_FLUKE') &&
                                        <option value='MonthEndMasterForFluke'>MonthEndMasterForFluke</option>}
                                        { privileges.readable.includes('MONTH_END_MASTER') &&
                                        <option value='CombinedSalesReport'>CombinedSalesReport</option>}
                                        { privileges.readable.includes('MONTH_END_MASTER') &&
                                        <option value='KeyMonthEndMaster'>MonthEndMaster-Key</option>}
                                    </select>

                                {/* <li>
                                    <div className="date-wrap mr-xs-3">
                                        <input type="date"/>
                                    </div>
                                    <div className="date-wrap">
                                        <input type="date"/>
                                    </div>
                                </li>
                                <li>
                                    <input type="text" className="txt" placeholder="Criteria 2"/>
                                </li>
                                <li>
                                    <input type="text" className="txt" placeholder="Criteria 3"/>
                                </li> */}
                            <Manufacturers
                                width='100%'
                                fontSize = '14px'
                                marginBottom = '10px'
                                onChange={event => {
                                    this.manufacturer = event.target.value.join(',');
                                    this.setState({
                                        manufacturers: event.target.value
                                    })
                                }}
                                onClose={_ => {
                                    //write a fuction in this componet
                                }}
                                multiselect
                                value={manufacturers}
                            />
                            {showHQFilter &&
                                <FilterDropdown
                                multiple = 'true'
                                label="Choose other distributor"
                                listItems={otherDistributors.map(distributor=>({
                                    label: distributor
                                }))}
                                value={otherDistributor}
                                onChange={(object)=>{
                                    const labels = object.map((o) => o.label);
                                    this.setState({
                                        otherDistributor: labels
                                    })
                                }}
                                style={{marginBottom: '10px'}}
                            />
                            }
                            {showHQFilter &&
                                <Multiselect
                                    label="All types"
                                    values={['Key Purch', 'Purch', 'Key POS', 'POS', 'ISS'].map(type => ({
                                        value: type,
                                        id: type
                                    }))}
                                    value={type}
                                    onChange={event => {
                                        this.setState({
                                            type: event.target.value
                                        })
                                    }}
                                    labelStyle={{marginLeft: '15px', fontSize: '15px' }}
                                    containerStyle={{border: '1px solid gray', backgroundColor: 'rgba(78, 80, 84, 0.466)', marginBottom: '10px', height: '35px'}}
                                    inputProps={{
                                        'aria-label': 'Without label',
                                    }}
                                    fullWidth
                                />
                            }
                            {showHQFilter &&
                                <FormControl className="select-input">
                                    <FormControlLabel
                                        control={
                                            <WheatSwitch
                                                checked={hqfilter}
                                                color="default"
                                                onClick={(event) => {
                                                    this.setState({
                                                        hqfilter: event.target.checked
                                                    })
                                                }}
                                            />
                                        }
                                        label='HQ Filter'
                                        className="status-switch"
                                    />
                                </FormControl>
                            }
                            <div className="btn-wrap report-buttons">
                                <button className="button button--excel" onClick={() => {
                                    this.generateExcel();
                                }}>
                                    Generate
                                </button>
                                <button className="button button--pdf disabled">Generate</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="quick-report">
                            <h2>Quick Report</h2>
                            <ul>
                                <li className="current">
                                    <span>Current Mont Sales</span>
                                    <i><img src={cross} width="24" height="24" alt="" /></i>
                                </li>
                                <li className="ytd">
                                    <span>YTD Sales</span>
                                    <i><img src={acro} width="26" height="26" alt="" /></i>
                                </li>
                                <li className="pnl">
                                    <span>PnL Current Year</span>
                                    <i><img src={cross} width="24" height="24" alt="" /></i>
                                </li>
                                <li className="rep">
                                    <span>REP Sales Current</span>
                                    <i><img src={cross} width="24" height="24" alt="" /></i>
                                </li>
                                <li className="report">
                                    <span>Distribution Report</span>
                                    <i><img src={acro} width="26" height="26" alt="" /></i>
                                </li>
                                <li className="comparison">
                                    <span>Sales Comparison</span>
                                    <i><img src={cross} width="24" height="24" alt="" /></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    privileges: state.privileges
})

export default connect(mapStateToProps)(ReportComponent);
