export const mapPrivilegeDataToTable = (privilegeData) => {
    return privilegeData.map(privilege=>({
        ...privilege,
        read: privilege.privileges.find(ob=>ob.privilege.endsWith("_Read")) ? true : false,
        write: privilege.privileges.find(ob=>ob.privilege.endsWith("_Write")) ? true : false
    }))
}

export const mapReportsDataToTable = (reportsData, allPrivileges=[]) => {
    return allPrivileges.filter(screenObject => screenObject.screenName === 'Reports' && screenObject.privilege.startsWith('Reports_'))
        .map(screenObject => {
            if(reportsData && reportsData.privileges.find(ob=>ob.privilege === screenObject.privilege)) {
                return {
                    ...screenObject,
                    read: true
                }
            }
            return {
                ...screenObject,
                read: false
            }
        })
}

export const findPrivilege = (privilegeName, allPrivileges) => {
    return allPrivileges.find(privilege=>privilege.privilege === privilegeName)
}