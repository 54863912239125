import React from 'react';
import { Tabs, Tab, withStyles } from '@material-ui/core';

const StyledTabs = withStyles({
    indicator: {
        backgroundColor: 'white'
    }
})(Tabs)

const StyledTab = withStyles({
    root: {
        background: 'rgba(78, 80, 84, 0.466)',
        color: 'white !important',
        opacity: 1,
        '&:hover': {
            background: 'rgba(73, 120, 33, 0.5)'
        }
    },
    selected: {
        background: 'rgba(73, 120, 33, 0.466)'
    }
})(Tab);

export default class MasterTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, newValue) {
        this.setState({
            value: newValue
        })
        this.props.onChange(newValue);
    }

    render() {
        const { value } = this.state;
        return (
                <StyledTabs
                    value={value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <StyledTab label="PLANT MAPPER" />
                    <StyledTab label="REGION MAPPER" />
                    <StyledTab label="PRIVILEGE SCREEN" />
                    <StyledTab label="MANUFACTURERS" />
                    <StyledTab label="DISTRIBUTORS" />
                    <StyledTab label="GOAL SETTINGS" />
                    <StyledTab label="DIST-REP MAPPER" />
                    <StyledTab label="PRODUCT FAMILY MANAGEMENT" />
                    <StyledTab label="HQ FILTER" />
                    <StyledTab label="TerritoryCode Mapper" />
                    <StyledTab label="DIST-MFG FILTER" />
                    <StyledTab label="END USER TABLE" />
                </StyledTabs>
        )
    }
}

MasterTabs.defaultProps = {
    onChange: ()=>{}
}