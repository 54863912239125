import React from 'react';
import Constants from '../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../redux/actions';
import { FormControl, Select, MenuItem, Input } from '@material-ui/core';

class Distributors extends React.Component {

    state = {
        distributors: []
    }

    componentDidMount() {
        this.abortController = new AbortController();
        this.fetchData();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    fetchData() {
        const { dispatch, onFetchComplete, alldistributors } = this.props;
        let url = `${Constants.baseURL}${Constants.getDistributors}?isStrategic=${this.props.isStrategic}`;
        if (alldistributors) {
            url = `${Constants.baseURL}${Constants.getHQFilterDistributors}`;
        }
        dispatch(showLoader(true));
        fetch(url, {
            signal: this.abortController.signal
        })
            .then(data => data.json())
            .then(data => {
                dispatch(showLoader(false));
                let dataList = data;
                if (alldistributors && !!data) {
                    let i = 0;
                    dataList = data.map(v => {
                        i = i + 1;
                        let d = {
                            id: i,
                            distributionName: v
                        };
                        return d;
                    });
                }
                this.setState({ distributors: dataList });
                onFetchComplete(dataList);
            })
            .catch(error => {
                dispatch(showLoader(false));
                console.log('Error: ' + error);
            });

    }
   
    render() {
        const { onChange, disabled, label, multiselect, onClose, value, labelStyle } = this.props;
        const { distributors } = this.state;

        if (multiselect) {
            return (
                <FormControl style={{width: 150, border: '1px solid gray', backgroundColor: 'rgba(78, 80, 84, 0.466)'}}>
                    <Select
                        multiple
                        disableUnderline
                        displayEmpty
                        input={<Input style={{color: 'white', fontSize: '12px', paddingLeft: '3px'}} />}
                        value={value}
                        onChange={event => {
                            onChange(event)
                        }}
                        onClose={() => onClose()}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <div style={{color: 'white', fontSize: '12px'}}>All Distributors</div>;
                            }
                            return selected.join(', ');
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem disabled value="">
                            <em>All Distributors</em>
                        </MenuItem>
                        {
                            distributors.map(dist => {
                                return(
                                    <MenuItem
                                        key={dist.id}
                                        value={dist.distributionName}
                                        classes={{
                                            selected: 'gray-background'
                                        }}
                                    >
                                        {dist.distributionName}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            )
        }

        return (
            <div>
                <select disabled={disabled} className="select special-select" onChange={(event) => {
                    onChange(event)
                }}>
                    <option value="">{label || 'ALl Distributors'}</option>
                    {
                        distributors.map(distributor => <option key={distributor.id} value={distributor.distributionName}>{distributor.distributionName}</option>)
                    }
                </select>
            </div>
        )
    }
}

Distributors.defaultProps = {
    onChange: () => { },
    disabled: false,
    onFetchComplete: () => { },
    label: "",
    multiselect: false,
    onClose: () => {},
    value: undefined,
    isStrategic: false
}

export default connect()(Distributors);