import React from 'react';
import Constants, { toastOptions, downloadFile } from '../../util/Constants';
import DropDown from '../MasterComponent/DistRepMapper/DropDown/DropDown';
import './Budget.css';
import ExpenseHeadTable from './ExpenseHead/ExpenseHeadTable';
import Button from '../ZipReportComponent/Button';
import SaveIcon from '../../../images/icon-save.png';
import SyncIcon from '../../../images/sync-icon.jpg';
import AllocatedRepresentatives from './AllocatedRepresentatives/AllocatedRepresentatives';
import SpecialSelect from '../UploadVerification/SpecialSelect';
import { showLoader } from '../../../redux/actions';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import SimpleUploadComponent from '../SimpleUploadComponent/SimpleUploadComponent';
import UploadIcon from '../../../images/icon-upload.ico';
import DownloadIcon from '../../../images/icon-save.png';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const today = new Date();

class Budget extends React.Component {

    constructor(props) {
        super(props);
        this.month = months[today.getMonth()].toLowerCase();
        this.state = {
            expensesTableData: [],
            allocatedRepresentativesList: [],
            totalBudget: 0,
            territories: [],
            users: [],
            copyFrom: '',
            copyTo: '',
            budgetType: 'territory',
            month: this.month,
            lastSyncTime: '',
            searchValue: ''
        }
        this.changeIndices = new Set();
        this.currentTerritory = ''
        this.currentUser = ''
    }

    getQueryParams({ territory, user }) {
        if (territory !== undefined) {
            return `territory=${territory}`
        } else if (user !== undefined) {
            return `territory=${user}`
        }
        return ''
    }

    fetchExpenseHeadTable(params) {
        const url = `${Constants.expensesBaseURL}${Constants.getBudgets}?${this.getQueryParams(params)}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true))
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const expensesTableData = data.budgets.map(row => ({ ...row, budget: row.budget || 0 }))
                this.setState({
                    expensesTableData,
                    totalBudget: data.totalBudget || 0,
                    lastSyncTime: data.lastSyncDate
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                dispatch(showLoader(false))
            })
    }

    fetchAllocatedRepresentativesList(params) {
        const url = `${Constants.expensesBaseURL}${Constants.getExpensesMasterReps}?${this.getQueryParams(params)}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true))
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    allocatedRepresentativesList: data
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                dispatch(showLoader(false))
            })
    }

    saveExpenseHeadTable() {
        const { expensesTableData, totalBudget } = this.state;
        const alteredData = expensesTableData.filter((row, index) => this.changeIndices.has(index));
        const url = `${Constants.expensesBaseURL}${Constants.postBudgets}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true))
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                copy: false,
                budgetList: alteredData,
                totalBudget,
                territoryTo: this.currentTerritory,
                userTo: this.currentUser
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            // console.log(response);
            this.changeIndices.clear();
            toast.success('Success: Data saved', toastOptions)
        }).catch(error => {
            console.log(error);
            toast.error('Error: Save failed', toastOptions)
        }).finally(() => {
            dispatch(showLoader(false))
        })
    }

    copyData() {
        const { copyFrom, copyTo, budgetType, users } = this.state;
        if (copyFrom === '' || copyTo === '') {
            return;
        }
        const userList = users.map(({ value }) => value)
        const url = `${Constants.expensesBaseURL}${Constants.postBudgets}`;
        const requestBody = {
            copy: true,
            territoryFrom: copyFrom,
            territoryTo: copyTo,
            mode: budgetType,
            users: userList
        }
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        }).then(response => {
            toast.success('Success: Copy complete', toastOptions)
            console.log(response);
        }).catch(error => {
            console.log(error);
            toast.error('Error: Copy failed', toastOptions)
        }).finally(() => {
            dispatch(showLoader(false))
        })
    }

    syncData() {
        const url = `${Constants.expensesBaseURL}${Constants.syncExpenseData}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        fetch(url, {
            method: 'GET'
        }).then(response => {
            console.log(response);
            toast.success('Success: Sync success', toastOptions);
        }).catch(error => {
            console.log(error);
            toast.error('Error: Sync failed', toastOptions)
        }).finally(() => {
            dispatch(showLoader(false))
        })
    }

    fetchData({ territory, user }) {
        if (territory !== undefined) {
            this.currentTerritory = territory;
            this.currentUser = '';
            this.fetchExpenseHeadTable({ territory });
            this.fetchAllocatedRepresentativesList({ territory });
        } else if (user !== undefined) {
            this.currentTerritory = '';
            this.currentUser = user;
            this.fetchExpenseHeadTable({ user })
            //this.fetchAllocatedRepresentativesList({user});
            this.setState({
                allocatedRepresentativesList: []
            });
        }
    }

    render() {
        const { expensesTableData, allocatedRepresentativesList, totalBudget, territories, users, copyFrom, copyTo, budgetType, month, lastSyncTime, searchValue } = this.state;
        const totalAllocated = expensesTableData.reduce((t, c) => {
            if (c.amountType === 'flat') {
                t += parseFloat(c.budget);
            } else {
                t += parseFloat((totalBudget * parseFloat(c.budget) / 100).toFixed(2));
            }
            return t;
        }, 0);
        const { dispatch } = this.props;
        return (
            <div className="container-fluid budget-screen">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="budget-top-panel col-sm-12">
                                    <div className="budget-type-chooser col-sm-4">
                                        <select
                                            value={budgetType}
                                            className="select special-select"
                                            onChange={event => {
                                                this.setState({
                                                    budgetType: event.target.value
                                                })
                                            }}
                                        >
                                            <option value="territory">Territory</option>
                                            <option value="user">User</option>
                                        </select>&nbsp;:&nbsp;
                                        {
                                            budgetType === 'territory' &&
                                            <DropDown
                                                nolabel={true}
                                                url={`${Constants.expensesBaseURL}${Constants.getExpensesMasterTerritories}`}
                                                onChange={event => {
                                                    this.fetchData({ territory: event.target.value })
                                                }}
                                                onFetchComplete={data => {
                                                    data = data.sort((a, b) => String(a).localeCompare(String(b)))
                                                    this.setState({
                                                        territories: data.map(value => ({ label: value, value }))
                                                    })
                                                    if (data[0]) {
                                                        this.fetchData({ territory: data[0] })
                                                    }
                                                }}
                                            />

                                        }
                                        {
                                            budgetType === 'user' &&
                                            <DropDown
                                                nolabel={true}
                                                url={`${Constants.baseURL}${Constants.getExpensesMasterUsers}`}
                                                onChange={event => {
                                                    this.fetchData({ user: event.target.value })
                                                }}
                                                onFetchComplete={data => {
                                                    data = data.sort((a, b) => String(a).localeCompare(String(b)))
                                                    this.setState({
                                                        users: data.map(value => ({ label: value, value }))
                                                    })
												if (data[0]) {
                                                        this.fetchData({ user: data[0] })
                                                }
												}}
                                            />

                                        }
                                    </div>
                                    <div className="total-budget-container  col-sm-5">
                                        <div className="total-budget-label">Total Budget :&nbsp;</div>
                                        <input
                                            type="number"
                                            style={{ color: 'white' }}
                                            className="total-budget-textbox"
                                            value={totalBudget}
                                            onChange={event => {
                                                this.setState({
                                                    totalBudget: parseFloat(event.target.value)
                                                })
                                            }}
                                        ></input>
                                        <input
                                            type="text"
                                            className="budget-search-box"
                                            placeHolder="Search"
                                            value={searchValue}
                                            onChange={event => {
                                                this.setState({
                                                    searchValue: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="row zip-update-button-container col-sm-3">
                                        <SimpleUploadComponent
                                            uploadUrl={`${Constants.expensesBaseURL}${Constants.uploadBudgets}`}
                                            image={{
                                                src: UploadIcon,
                                                width: 20,
                                                height: 20
                                            }}
                                            label="UPLOAD"
                                        //disabled={!editable}
                                        />
                                        <Button
                                            onClick={() => {
                                                dispatch(showLoader(true));
                                                downloadFile(`${Constants.expensesBaseURL}${Constants.downloadBudgets}`, undefined, 'xlsx')
                                                    .finally(() => {
                                                        dispatch(showLoader(false));
                                                    })
                                            }}
                                            icon={DownloadIcon}
                                            label='Download'
                                            dimension='20'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row expenses-table-container">
                            <ExpenseHeadTable
                                data={expensesTableData.filter(row =>
                                    searchValue === '' || String(row.headName).toLowerCase().includes(searchValue.toLowerCase())
                                )}
                                onChange={(newData, changeIndex) => {
                                    this.setState({
                                        expensesTableData: newData
                                    })
                                    this.changeIndices.add(changeIndex)
                                }}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <Button
                                    label="Save"
                                    icon={SaveIcon}
                                    dimension={20}
                                    onClick={_ => {
                                        this.saveExpenseHeadTable();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 budget-tables-container">
                        <table>
                            <tbody>
                                <tr class="blank_row">
                                    <td>&nbsp;</td>
                                </tr><tr class="blank_row">
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="table-1-container">
                                        {/* table 1 */}
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>Summary</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Total Allocated:</td>
                                                    <td>{parseFloat(totalAllocated).toFixed(2)} $</td>
                                                </tr>
                                                <tr>
                                                    <td>Amount left:</td>
                                                    <td>{parseFloat(totalBudget - totalAllocated).toFixed(2)} $</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-1-container">
                                        {/* table 2 */}
                                        <AllocatedRepresentatives
                                            data={allocatedRepresentativesList}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-1-container">
                                        {/* table 3 */}
                                        <table className="table-1">
                                            <thead>
                                                <tr class="blank_row">
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={2}>Copy Expense Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Copy to</td>
                                                    <td>
                                                        <SpecialSelect
                                                            entries={(_ => {
                                                                if (budgetType === 'territory') {
                                                                    return [{
                                                                        label: 'ALL',
                                                                        value: 'ALL'
                                                                    }].concat(territories).filter(entry => entry.value !== copyFrom)
                                                                }
                                                                return [{
                                                                    label: 'ALL',
                                                                    value: 'ALL'
                                                                }].concat(users).filter(entry => entry.value !== copyFrom)
                                                            })()}
                                                            label="Select"
                                                            value={copyTo}
                                                            onChange={copyTo => {
                                                                this.setState({
                                                                    copyTo
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Copy from</td>
                                                    <td>
                                                        <SpecialSelect
                                                            entries={(_ => {
                                                                if (budgetType === 'territory') {
                                                                    return territories.filter(entry => entry.value !== copyTo)
                                                                }
                                                                return users.filter(entry => entry.value !== copyTo)
                                                            })()}
                                                            label="Select"
                                                            value={copyFrom}
                                                            onChange={copyFrom => {
                                                                this.setState({
                                                                    copyFrom
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <Button
                                                            label="Save"
                                                            icon={SaveIcon}
                                                            dimension={20}
                                                            onClick={_ => {
                                                                this.copyData();
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-1-container">
                                        <table className="table-1">
                                            <thead>
                                                <tr>
                                                    <th colSpan={3}>Expense Data Sync</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Last Sync Time</td>
                                                    <td>{lastSyncTime}</td>
                                                    <td colSpan="2">
                                                        <Button
                                                            label="Sync"
                                                            icon={SyncIcon}
                                                            dimension={20}
                                                            onClick={_ => {
                                                                this.syncData();
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(Budget);