import * as React from "react";
import login from '../../images/logo-login.png'
import { connect } from 'react-redux';
import { showLoader } from '../../redux/actions';
import Constants, {toastOptions, screens} from '../util/Constants';
import { toast } from 'react-toastify';
import { setUserDetails } from "../../redux/actions";
import { Redirect } from 'react-router';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
        };
        this.onChange = this.onChange.bind(this);
        this.checkKey = this.checkKey.bind(this);
    }

    setPrivileges(data) {
        const keys = Object.keys(screens);
        const readable = [], writable = [];
        Object.values(screens).forEach((screen, index)=>{
            const read = data.privileges.includes(screen+'_Read');
            const write = data.privileges.includes(screen+'_Write');
            const isReport = screen.startsWith(screens.REPORTS);
            const isReportReadable = data.privileges.includes(screen);
            if(read || write || (isReport && isReportReadable)) {
                readable.push(keys[index]);
            }
            if(write) {
                writable.push(keys[index]);
            }
        })
        if(data.privileges.find(privilege=>privilege.startsWith(screens.REPORTS))) {
            readable.push('REPORTS');
        }
        this.props.dispatch({
            type: 'SET_PRIVILEGES',
            privileges: {
                readable,
                writable
            }
        })
    }

    /**
     * This method is called when user clicks log in button. This method makes post request to the login api
     */
    login() {
        const { dispatch } = this.props;
        const url = `${Constants.baseURL}${Constants.userLogin}`;
        const {userName, password} = this.state;
        const options = {
            method: 'POST',
            body: JSON.stringify({
                userName,
                password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        dispatch(showLoader(true));
        fetch(url, options)
        .then(res=>{
            dispatch(showLoader(false));
            if(res.status === 200) {
                toast.success("Success: Login success", toastOptions);
            } else {
                toast.error("Error: Login failed", toastOptions);
                throw new Error("login failed");
            }
            return res.json();
        })
        .then(jsonData=>{
            jsonData.rep = `${jsonData.lastName}, ${jsonData.firstName}`;
            let profilePic = jsonData.profilePic;
            jsonData.profilePic = 'data:image/png[jpg];base64,'+ profilePic;
            dispatch(setUserDetails(jsonData));
            this.setPrivileges(jsonData);
        })
        .catch(error=>{
            dispatch(showLoader(false));
            toast.error("Error: Login failed", toastOptions);
            console.log(error);
        })
    }

    /**
     * This method is used to change the value of username and password stored in state when user edits the fields
     * 
     * @param {event} event 
     */
    onChange(event) {
        const key = event.target.name;
        const val = event.target.value;
        let obj = {};
        obj[key] = val;
        this.setState(obj)
    }

    /**
     * This method handles form submission when user press enter while inside an input box
     * 
     * @param {event} key_press_event 
     */
    checkKey(event) {
        const keyCode = event.which || event.keyCode;
        if(keyCode === 13) {
            this.login();
        }
    }

    render() {
        const { userDetails } = this.props;
        if(JSON.stringify(userDetails) === '{}')
        return (
            <div className="login">
                <div className="wrapper">
                    <div className="logo">
                        <a href="#"><img src={login} alt="Event Management"/></a>
                    </div>
                    <div className="content">
                        <h2 className="text-xs-center">LOGIN TO YOUR ACCOUNT</h2>
                        <div className="form-group">
                            <input type="text" placeholder="Username" className="txt" onChange={this.onChange}
                                   value={this.state.userName} onKeyPress={this.checkKey} name='userName'/>
                        </div>
                        <div className="form-group">
                            <input type="Password" placeholder="Password" className="txt" onChange={this.onChange}
                                   value={this.state.password} onKeyPress={this.checkKey} name='password'/>
                        </div>
                        <div className="form__action clearfix">
                            <button type="submit" className="button float-right" onClick={()=>{this.login()}}>Login</button>
                        </div>

                        <a href="#" className="forget">Forget Password?</a>
                    </div>
                </div>
            </div>)
        else
            return <Redirect to={'/'} />
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
})

export default connect(mapStateToProps)(LoginPage);