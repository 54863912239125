import React from 'react';
import * as d3 from 'd3';
import USA from '../../../util/MapData/us-states.json';
import './SalesResponsibility.css';

export default class SalesResponsibility extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        this.drawChart();
    }

    drawChart() {
        const { states, onClick, selectedState } = this.props;
        const chart = this.chartRef.current;
        const tooltip = d3.select(this.tooltipRef.current);
        const width = 960;
        const height = 500;

        // clean the board
        d3.select(chart).selectAll('svg').remove();

        // D3 Projection
        var projection = d3.geoAlbersUsa()
                            .translate([width/2, height/2])
                            .scale([1000]);

        // Define path generator
        var path = d3.geoPath().projection(projection);

        const svg = d3.select(chart)
                        .append('svg')
                        .attr("preserveAspectRatio", "xMinYMin meet")
                        .attr('viewBox', `0 0 ${width} ${height}`)
                        // .attr('width', width)
                        // .attr('height', height);

        const mouseover = ()=>{
            tooltip.style('opacity', 1);
        }

        const mouseleave = ()=>{
            tooltip.style('opacity', 0);
        }

        const mousemove = (d)=>{
            const { name, code } = d.properties;
            tooltip.html(`
                <div style="text-align: center; font-weight: 600;">
                    ${name} (${code})
                </div>
            `)
            const styles = tooltip.node().getBoundingClientRect();
            tooltip.style('left', `${d3.event.x + 5}px`).style('top', `${d3.event.y - styles.height-5}px`)
        }

        tooltip.on('mouseover', (d)=>{
            tooltip.style('left', 0);
        })
        
        svg.selectAll('path')
            .data(USA.features)
            .enter()
            .append('path')
            .attr('d', (data)=>{
                const centroid = path.centroid(data);

                // svg.append('rect')
                // .attr('x', centroid[0])
                // .attr('y', centroid[1])
                // .attr('width', 12)
                // .attr('height', 10)
                // .style('fill', 'white')
                // .style('transform', 'translate(-6px, -8px)')

                svg.append('text')
                .html(data.properties.code)
                .style('fill', 'white')
                .attr('x', centroid[0])
                .attr('y', centroid[1])
                .attr("text-anchor","middle")
                .attr('font-size','6pt');
                
                return path(data);
            })
            .style("stroke", "#fff")
            .style("stroke-width", d=>{
                const { code } = d.properties;
                return code === selectedState ? "3" : "1";
            })
            .attr("fill", d=> {
                const { code } = d.properties;
                // if(code === selectedState) {
                //     return "#00cfff";
                // }
                if(states.includes(code)) {
                    return "rgb(115, 230, 166)";
                }
                return "rgb(221, 109, 109)";
            })
            .attr('class', 'map-path')
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
            .on("click", onClick)

    }

    componentDidUpdate(pervProps) {
        if(JSON.stringify(pervProps.states) !== JSON.stringify(this.props.states) || this.props.selectedState !== pervProps.selectedState) {
            this.drawChart();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.tooltipRef} className="tooltip"></div>
                <div ref={this.chartRef}></div>
            </React.Fragment>
        )
    }
}

SalesResponsibility.defaultProps = {
    states: [],
    onClick: ()=>{},
    selectedState: ''
}