import React from 'react';
import InputFields from './InputFields';
import Update from '../../../images/icon-update.png';
import Clear from '../../../images/icon-clear.png';
import Button from './Button';

export default class ZipUpdateBox extends React.Component {

    constructor(props) {
        super(props);
        this.inputFieldsRef = React.createRef();
    }

    render() {
        const { columns } = this.props;
        return(
            <div className="container-fluid zip-search-box">
                <InputFields
                    ref={this.inputFieldsRef}
                    columns={columns}
                />
                <div className="row zip-update-button-container">
                    <Button
                        onClick={()=>{
                            this.inputFieldsRef.current.clearForm();
                        }}
                        label="Clear"
                        icon={Clear}
                        dimension='20'
                    />
                    <Button
                        onClick={()=>{
                            // this.saveAllChanges();
                        }}
                        label='Update'
                        icon={Update}
                        dimension='20'
                    />
                </div>
            </div>
        )
    }
}

ZipUpdateBox.defaultProps = {
    columns: []
}