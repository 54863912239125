import React from 'react';
import './FilterDropdown.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const StyledAutocomplete = withStyles({
    listbox: {
        background: 'gainsboro'
    }
})(Autocomplete)
export default class FilterDropdown extends React.Component {

    render() {
        const { listItems, label, onChange, disabled, style, multiple } = this.props;
        return (
            <StyledAutocomplete
                multiple = {multiple}
                options={listItems}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                   <TextField {...params}  placeholder={label}/>
                )
                }
                style={{background: 'rgba(78, 80, 84, 0.466)', ...style}}
                onChange={(event, value)=>{
                    onChange(value)
                }}
                disabled={disabled}
            />
        )
    }

}

FilterDropdown.defaultProps = {
    listItems: [],
    label: '',
    onChange: ()=>{},
    disabled: false,
    style: {}
}