import React from 'react';
import Map from './Map/Map';
import './HeatMap.css';
import Constants from '../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';
import DropDown from '../MasterComponent/DistRepMapper/DropDown/DropDown';
import Button from '../ZipReportComponent/Button'
import SearchIcon from '../../../images/icon-search.png'

const upperLimit = 100000;
const today = new Date();

class HeatMap extends React.Component {

    constructor(props) {
        super(props);
        this.abortController = new AbortController();
		this.year = today.getFullYear();
        this.state = {
            data: [],
            min: 0,
            max: 0,
			year: this.year,
            sliderPosition: upperLimit,
            sliderTemporaryPosition: upperLimit
        }
        this.searchParams = {}
    }

    fetchData() {
        const params = Object.keys(this.searchParams).reduce((params,key) => {
            const value = this.searchParams[key];
            if(value) {
                return `${params}&${key}=${this.searchParams[key]}`
            }
            return params;
        }, '');
        const url = `${Constants.baseURL}${Constants.getHeatmapData}?year=${this.year}${params}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        fetch(url, {
            signal: this.abortController.signal
        })
            .then(response=>response.json())
            .then(data=>{
                const amounts = data.map(city=>city.totalAmount);
                const min = Math.min(...amounts);
                const max = Math.max(...amounts);
                this.setState({
                    data,
                    min,
                    max
                })
            }).catch(error=>{
                console.log(error);
            }).finally(()=>{
                dispatch(showLoader(false));
            })
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {
        const { data, max, min, sliderPosition, sliderTemporaryPosition, year } = this.state;
        return (
            <div className="tab-pane show active" id="nav-cost" role="tabpanel" aria-labelledby="nav-cost-tab">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                        </div>
                        <div className="col-sm-2">
                            <DropDown
                                label="All Manufactures"
                                url={`${Constants.baseURL}${Constants.getHQFilterManufacturers}`}
                                onChange={(event)=>{
                                    this.searchParams.manufacturer = event.target.value;
                                    //this.fetchData();
                                }}
                            />
                        </div>
                        <div className="col-sm-2">
                            <DropDown
                                label="All Distributors"
                                url={`${Constants.baseURL}${Constants.getHQFilterDistributors}`}
                                onChange={(event)=>{
                                    this.searchParams.distributor = event.target.value;
                                    //this.fetchData();
                                }}
                            />
                        </div>
						<div className="dashboard-dropdown col-sm-1">
                                <select
                                    className="select special-select"
                                    value={year}
                                    onChange={(event) => {
                                        this.year = event.target.value;
                                        this.setState({
                                            year: this.year
                                        })
                                    }}
                                >
                                    {
                                        Array.from({ length: 3 }, (v, k) =>
                                            today.getFullYear() - k
                                        ).map(year => <option value={year}>{year}</option>)
                                    }
                                </select>
                        </div>
						<div className="dashboard-search-button">
                                <Button
                                    dimension={20}
                                    icon={SearchIcon}
                                    onClick={()=>{this.fetchData();}}
                                />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <Map
                                data={data}
                                max={sliderPosition === upperLimit ? Math.max(max, upperLimit) : sliderPosition}
                                min={min}
                                sliderPosition={sliderPosition}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heatmap-slider">
                                0&nbsp;
                                <input type="range" min={0} max={upperLimit} value={sliderTemporaryPosition}
                                    onChange={event=>{
                                        this.setState({
                                            sliderTemporaryPosition: event.target.value
                                        })
                                    }}
                                    onMouseUp={event => {
                                        this.setState({
                                            sliderPosition: event.target.value
                                        })
                                    }}
                                />&nbsp;
                                {upperLimit}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(HeatMap);