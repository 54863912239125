import React from 'react';
import TableHeaders from '../ZipReportComponent/TableHeaders';
import Constants, { toastOptions } from '../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';
import TableBody from '../ZipReportComponent/TableBody';
import TableFooter from '../ZipReportComponent/TableFooter';
import { toast } from 'react-toastify';

const columns = [
    {
        label: 'Manufacturer',
        key: 'mfg',
        nosort: true,
        type: 'enum',
        enum: {
            label: 'Choose Manufacturer',
            values: []
        }
    },
    {
        label: 'Distributor',
        key: 'distributor',
        nosort: true,
        type: 'enum',
        enum: {
            label: 'Choose Distributor',
            values: []
        }
    },
    {
        label: 'State Code',
        key: 'location',
        nosort: true
    },
    {
        label: 'Status',
        key: 'status',
        type: 'boolean'
    }
]

const dummyRow = {
    id: 0,
    location: '',
    distributor: '',
    mfg: '',
    status: true
}

class MFGLookupTable extends React.Component {

    static id = 0;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPages: 0,
            page: 1,
            columns,
            resetEditSwitch: false
        }
        this.index = 0;
    }

    componentDidMount() {
        this.abortController = new AbortController();
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const { searchString, manufacturers, distributors } = this.props;
        if(prevProps.searchString !== searchString) {
            this.fetchData(0);
        }
        if(JSON.stringify(prevProps.manufacturers) !== JSON.stringify(manufacturers) ||
            JSON.stringify(prevProps.distributors) !== JSON.stringify(distributors)) {
            columns[0].enum.values = manufacturers;
            columns[1].enum.values = distributors;
            this.setState({
                columns
            })
        }
    }

    addRow() {
        const { data } = this.state;
        data.push(JSON.parse(JSON.stringify({...dummyRow, id: `temp${MFGLookupTable.id++}`})));
        this.setState({
            data
        })
    }

    fetchData(index) {
        if(index === undefined) {
            index = this.index;
        } else {
            this.index = index;
            this.setState({
                page: index+1
            });
        }
        this.newData = [];
        const { dispatch, searchString } = this.props;
        const url = `${Constants.baseURL}${Constants.getMfgLookupDetails}?index=${index}&limit=${Constants.maxMfgLookupRows}${searchString}`;
        dispatch(showLoader(true));
        fetch(url, {
            signal: this.abortController.signal
        })
        .then(response=>{
            dispatch(showLoader(false));
            return response.json();
        }).then(jsonData => {
            this.setState({
                data: jsonData.response,
                totalPages: Math.ceil(jsonData.totalCount/Constants.maxMfgLookupRows)
            });
        }).catch(error=>{
            dispatch(showLoader(false));
            console.log("Error: "+error);
        })
    }

    deleteHistory(id) {
        console.log(id)
        if(String(id).startsWith('temp')) {
            const { data } = this.state;
            const newData = data.filter(row=>row.id!==id);
            this.setState({
                data: newData
            })
            return;
        }
        const path = `${Constants.deleteMfgLookup.replace('{id}', id)}`
        const url = `${Constants.baseURL}${path}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        fetch(url, {
            method: 'DELETE',
            signal: this.abortController.signal
        }).then(response=>{
            dispatch(showLoader(false));
            if(response.status===200) {
                toast.success("Success: Delete operation success", toastOptions);
            } else {
                throw new Error("Delete failed");
            }
            this.fetchData();
        }).catch(error=>{
            dispatch(showLoader(false));
            toast.error("Error: Delete operation failed");
            console.log("Error "+error);
            this.fetchData();
        })
    }

    saveMfgLookupData() {
        if(this.newData) {
            const { dispatch } = this.props;
            dispatch(showLoader(true));
            const url = `${Constants.baseURL}${Constants.updateMfgLookupData}`;
            const body = JSON.parse(JSON.stringify(this.newData)).map(row=>{
                if(String(row.id).startsWith('temp')) {
                    delete row.id;
                }
                return row;
            })
            fetch(url, {
                method: 'PUT',
                body: JSON.stringify(body),
                signal: this.abortController.signal,
                headers: {
                    'Content-type': 'application/json'
                }
            }).then(response=>{
                dispatch(showLoader(false));
                this.newData = null;
                this.fetchData();
                this.setState({
                    resetEditSwitch: !this.state.resetEditSwitch
                })
            }).catch(error=>{
                dispatch(showLoader(false));
                this.newData = null;
                this.fetchData();
                console.log("Error: "+error);
            })
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {
        const { manufacturers, distributors, editable } = this.props;
        const { data, totalPages, page, columns, resetEditSwitch } = this.state;
        return(
            <div className="listing-grid table-responsive table-container mfg-lookup-table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders columns={columns} />
                            {editable && <th className="history-table-header" scope="col" >Action</th >}
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody
                            columns={columns}
                            data={data}
                            page={page}
                            onDataChange={(newData)=>this.newData = newData}
                            deleteHistory={(id)=>this.deleteHistory(id)}
                            hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                                if(String(currentRow.id).startsWith('temp')) {
                                    resultArray.push(index);
                                }
                                return resultArray;
                            }, [])}
                            disableActions={!editable}
                            resetEditSwitch={resetEditSwitch}
                        />
                        <TableFooter
                            totalPages={totalPages}
                            page={page}
                            changePage={(index)=>this.fetchData(index)}
                        />
                    </tbody>
                </table>
            </div>
        )
    }
}

MFGLookupTable.defaultProps = {
    searchString: '',
    manufacturers: [],
    distributors: [],
    editable: true
}

export default connect(null, null, null, { forwardRef: true })(MFGLookupTable);