import * as React from "react";
import MasterTabs from "./MasterTabs";
import PlantMapper from './PlantMapper/PlantMapper';
import RegionMapper from "./RegionMapper/RegionMapper";
import PrivilegeScreen from "./PrivilegeScreen/PrivilegeScreen";
import DistributorsPage from "./DistributorsPage/DistributorsPage";
import ManufacturersPage from "./ManufacturersPage/ManufacturersPage";
import GoalSettings from "./GoalSettings/GoalSettings";
import DistRepMapper from "./DistRepMapper/DistRepMapper";
import ProductFamilyManagement from "./ProductFamilyManagement/ProductFamilyManagement";
import HQFilter from "./HQFilter/HQFilter";
import TerritoryCodeMapper from "./TerritoryCodeMapper/TerritoryCodeMapper";
import DistMfgMapper from "./DistMfgMapper/DistMfgMapper";
import EndUserTable from "./EndUserTable/EndUserTable";

export default class MasterComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0
        }
    }

    render() {
        const { currentTab } = this.state;
        return (
            <div className="tab-pane show active" id="nav-master" role="tabpanel" aria-labelledby="nav-master-tab">
                <div className="container-fluid">
                    <div className="row master-data-tabs-container">
                        <div className="col-sm-12">
                            <MasterTabs onChange={(newTab)=>this.setState({
                                currentTab: newTab
                            })}  />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {
                                (()=>{
                                   switch(currentTab) {
                                       case 0:
                                           return(
                                               <PlantMapper />
                                           )
                                        case 1:
                                            return(
                                                <RegionMapper />
                                            )
                                        case 2:
                                            return(
                                                <PrivilegeScreen />
                                            )
                                        case 3:
                                            return(
                                                <ManufacturersPage />
                                            )
                                        case 4:
                                            return(
                                                <DistributorsPage />
                                            )
                                        case 5:
                                            return(
                                                <GoalSettings />
                                            )
                                        case 6:
                                            return(
                                                <DistRepMapper />
                                            )
                                        case 7:
                                            return(
                                                <ProductFamilyManagement />
                                            )
                                        case 8:
                                            return(
                                                <HQFilter />
                                            )
                                        case 9:
                                            return(
                                                <TerritoryCodeMapper />
                                            )
                                        case 10:
                                            return(
                                               <DistMfgMapper />
                                            )
                                        case 11:
                                            return(
                                                <EndUserTable />
                                            )
                                        default:
                                            return(
                                                "invalid tab value"
                                            )
                                   } 
                                })()
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}