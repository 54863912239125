import React from 'react';
import './HorizontalBarChart.css';

export default class HorizontalBarChart extends React.Component {
    render() {
        const { data, maxValue, className } = this.props;
        return(
            <table className={"flags-bar-table "+className}>
                <tbody>
                    {
                        data.map(row=>{
                            return(
                                <tr>
                                    <td className="label">
                                        {row.headName}
                                    </td>
                                    <td className="bars">
                                        <div className="bar" style={{width: `${row.expense*100/maxValue}%`}}>&nbsp;{row.expense}%</div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }
}

HorizontalBarChart.defaultProps = {
    data: [],
    maxValue: 1,
    className: ""
}