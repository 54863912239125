import * as React from "react";
import FileUpload from './FileUpload/FileUpload';
import { connect } from 'react-redux';
import Constants from '../../util/Constants';
import { showLoader } from '../../../redux/actions';
import UploadHistory from "./UploadHistory";
import SearchBox from "./SearchBox";
import './UploadComponent.css';

class UploadComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            distributors: [],
            manufacturers: [],
            manufacturer: '',
            distributor: '',
            multiDistrSpec: false,
            searchParams: {
                type: 'SalesData'
            }
        }
        this.callCompleted = false;
        this.historyComponentRef = React.createRef();
    }

    /**
     * Parent component level hide loader method that handles hiding loader when multiple parallel fetch calls need to be completed
     */
    hideLoader() {
        const { dispatch } = this.props;
        if(this.callCompleted) {
            dispatch(showLoader(false));
            return;
        }
        this.callCompleted = true;
    }

    // this function is to refresh the history table from parent level component
    refreshHistoryTable() {
        const historyComponent = this.historyComponentRef.current;
        if(historyComponent) {
            historyComponent.getHistory();
        }
    }

    /**
     * This function is for making get calls to fetch data from database
     * 
     * @param {string} url 
     */
    async makeApiCall(url) {
        try {
            const response = await fetch(url, {
                method: 'GET',
            });
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    /**
     * Manages fetching list of distributors and manufacturers after component mounts
     */
    async componentDidMount() {
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const urlGetDistributors = `${Constants.baseURL}${Constants.getDistributors}`;
        const urlGetManufacturs = `${Constants.baseURL}${Constants.getManufacturers}`;
        const distributors = await this.makeApiCall(urlGetDistributors);
        const manufacturers = await this.makeApiCall(urlGetManufacturs);
        this.hideLoader();
        this.setState({
            distributors,
            manufacturers
        })
    }

    handleCheckbox(event) {
        this.setState({
            multiDistrSpec: event.target.checked
        })
    }

    render() {
        const { distributors, manufacturers, manufacturer, distributor, multiDistrSpec, searchParams } = this.state;
        const { editable } = this.props;
        return (
            <div className="tab-pane upload show active" id="nav-upload" role="tabpanel" aria-labelledby="nav-upload-tab">
                { editable &&
                    <div className="form row">
                        <div className="col-sm-3" >
                            <select disabled={multiDistrSpec} className="select special-select" onChange={(event)=>{
                                const distributor = event.target.value;
                                this.setState({
                                    distributor
                                })
                            }}>
                                <option value="">Choose Distributor</option>
                                {
                                    distributors.map(distributor => <option key={distributor.id} value={distributor.distributionName}>{distributor.distributionName}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-sm-3">
                            <select className="select special-select" disabled={multiDistrSpec} onChange={(event)=>{
                                const manufacturer = event.target.value;
                                this.setState({
                                    manufacturer
                                })
                            }}>
                                <option value="">Choose Manufacturer</option>
                                {
                                    manufacturers.map(manufacturer => <option key={manufacturer.id} value={manufacturer.mfgName}>{manufacturer.mfgName}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-sm-3" style={{marginLeft: '10px'}}>
                            <input type="checkbox" className="upload-checkbox" onChange={(event)=>this.handleCheckbox(event)} /> Multi Distributor Spec
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                }
                <div className="form">
                    <div className="container-fluid">
                        { editable &&
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2 className="upload-header">Files to upload:</h2>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <ul>
                                        <li>
                                            <div className="drag-wrap">
                                                <label htmlFor="upload-photo">Drag drop / Browse</label>
                                                <input type="file" name="photo" id="upload-photo"/>
                                            </div>
                                            <div className="progress"></div>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="row" style={!multiDistrSpec && (manufacturer=='' || distributor=='') ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                                    <div className="col-sm-12">
                                        <FileUpload manufacturer={multiDistrSpec ? 'nill' : manufacturer} distributor={multiDistrSpec ? 'nill' : distributor} multiDistrSpec={multiDistrSpec} refreshHistoryTable={()=>this.refreshHistoryTable()} />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="upload-header">Upload History:</h2>
                            </div>
                        </div>
                        <div className="row upload-data-search-container">
                            <SearchBox
                                onSearch={(searchParams)=>{
                                    this.setState({
                                        searchParams
                                    })
                                }}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <UploadHistory
                                    ref={this.historyComponentRef}
                                    rows={Constants.maxHistoryRows}
                                    hideLoader={()=>this.hideLoader()}
                                    editable={editable}
                                    searchParams={searchParams}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('UPLOAD_DATA')
})

export default connect(mapStateToProps)(UploadComponent);