import React from 'react';
import Constants from '../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../redux/actions';
import { FormControl, Select, MenuItem, Input } from '@material-ui/core';

class Territories extends React.Component {

    state = {
        territories: []
    }

    componentDidMount() {
        this.abortController = new AbortController();
        this.fetchData();
    }

    fetchData() {
        const { dispatch, onFetchComplete } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.getAllTerritories}`;
        fetch(url, {
            signal: this.abortController.signal
        })
            .then(data => data.json())
            .then(data => {
                dispatch(showLoader(false));
                this.setState({ territories: data });
                onFetchComplete(data);
            })
            .catch(error => {
                dispatch(showLoader(false));
                console.log('Error: ' + error);
            });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {
        const { onChange, disabled} = this.props;
        const { territories } = this.state;
        return (
            <select
                className="select special-select"
                disabled={disabled}
                onChange={(event) => {
                    onChange(event);
                }}
            >
                <option value="">Choose Territory</option>
                {
                    territories.map(territories => <option key={territories.id} value={territories.territoryCode}>{territories.territoryCode}</option>)
                }
            </select>
        )
    }
}

Territories.defaultProps = {
    onChange: () => { },
    onClose: () => {},
    disabled: false,
    onFetchComplete: () => { },
    value: undefined
}

export default connect()(Territories);