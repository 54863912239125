import React from 'react';

export default class Select extends React.Component {
    render() {
        const { values, onChange, className, disabled, value } = this.props;
        return(
            <select
                disabled={disabled}
                className={`select special-select ${className}`}
                onChange={onChange}
                value={value}
            >
                {
                    values.map(value=><option value={value}>{value}</option>)
                }
            </select>
        )
    }
}

Select.defaultProps = {
    values: [],
    onChange: ()=>{},
    className: '',
    disabled: false,
    value: undefined
}