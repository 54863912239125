import React from 'react';
import Constants from '../../../../util/Constants';

/**
 * This component renders a dropdown by fetching data from the url provided in the props
 * The data retrieved should be an array
 */
export default class DropDown extends React.Component {

    state={
        data: []
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.url !== this.props.url) {
            this.fetchData();
        }
    }

    fetchData() {
        const { url, onFetchComplete } = this.props;
        fetch(url)
        .then(response=>{
            if(response.status !== 200) {
                throw new Error();
            }
            return response.json()
        })
        .then(data=>{
            if(url === `${Constants.baseURL}${Constants.getManufacturers}`){
               data = data.map(manufacturer=>manufacturer.mfgName);
            }
            onFetchComplete(data);
            this.setState({
                data: data
            })
        }).catch(error=>{
            console.log(error)
        })
    }

    render() {
        const { label, onChange, nolabel, hiddenValues, disabled, reset } = this.props;
        const { data } = this.state;
        let { value } = this.props;
        if(!!reset) {
            value = '';
        }
        return(
            <select
                className="select special-select"
                onChange={onChange}
                value={value}
                disabled={disabled}
            >
                {!nolabel && <option value=''>{label}</option>}
                {
                    data.filter(option=>
                        !hiddenValues.includes(option)
                    ).sort((a,b)=>String(a).localeCompare(String(b))).map(option=><option value={option}>{option}</option>)
                }
            </select>
        )
    }
}

DropDown.defaultProps = {
    url: '',
    label: '',
    nolabel: false,
    onChange: ()=>{},
    dataKey: '',
    dataLabel: '',
    value: undefined,
    onFetchComplete: ()=>{},
    hiddenValues: [],
    disabled: false
}