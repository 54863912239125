import React from 'react';
import Manufacturers from '../../../Manufacturers';
import UserSelect from '../../../UserSelect';
import './Goal.css';

export default class Goal extends React.Component {

    handleChange(newValue, key) {
        const { goal, onChange } = this.props;
        goal[key] = newValue;
        onChange(goal);
    }

    render() {
        return(
            <div className="row">
                <div className="col-sm-3">
                    <Manufacturers
                        onChange={(event)=>{
                            this.handleChange(event.target.value, 'mfgName')
                        }}
                    />
                </div>
                <div className="col-sm-3">
                    <UserSelect
                        onChange={(event)=>{
                            this.handleChange(event.target.value, 'territory')
                        }}
                    />
                </div>
                <div className="col-sm-3">
                    <input
                        type="number"
                        className="special-input"
                        placeholder="Target revenue"
                        onChange={(event)=>{
                            this.handleChange(event.target.value, 'targetRevenue');
                        }}
                    />
                </div>
                <div className="col-sm-3">
                    <select
                        className="select special-select"
                        onChange={(event)=>{
                            this.handleChange(event.target.value, 'year');
                        }}
                    >
                        <option value="">Choose a year</option>
                        {
                            Array.from({length: 10}, (v,k)=><option value={2019+k}>{2019+k}</option>)
                        }
                    </select>
                </div>
            </div>
        )
    }
}

Goal.defaultProps = {
    goal: {},
    onChange:  ()=>{}
}