import React from 'react';
import Constants, { downloadFile, toastOptions } from '../../util/Constants';
import { toast } from 'react-toastify';
import {Tooltip} from '@material-ui/core';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions'
import Popup from '../Popup/Popup';
import DownloadExcel from '../../../images/icon-excel-download.png';
import SyncIcon from '../../../images/icon-sync.png'

const columns = [
    {
        label: 'Id',
        key: 'id',
    },
    {
        label: 'File Name',
        key: 'fileName'
    },
    {
        label: 'Uploaded Time',
        key: 'uploadedTime'
    },
    {
        label: 'Uploaded By',
        key: 'uploadedBy'
    },
    {
        label: "Count",
        key: 'dataCount'
    },
    {
        label: 'Status',
        key: 'status'
    },
    {
        label: 'SyncStatus',
        key: 'syncStatus'
    },
    {
        label: 'Remarks',
        key: 'remarks'
    },
    {
        label: 'Completed',
        key: 'completed'
    }
]

class UploadHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadHistory: [],
            sortedColId: 0,
            sortOrderAscending: false,
            totalCount: props.rows,
            page: 1,
            showPopup: false
        }
    }

    componentDidMount() {
        this.getHistory();
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.searchParams) !== JSON.stringify(this.props.searchParams)) {
            this.getHistory();
        }
    }

    getQueryParams() {
        const { searchParams } = this.props;
        let queryParams = '';
        if(searchParams.filename) {
            queryParams += `&fileName=${searchParams.filename}`
        }
        if(searchParams.type) {
            queryParams += `&type=${searchParams.type}`
        }
        return queryParams;
    }

    /**
     * This function does the initial api call to fetch the history table data
     */
    getHistory(page) {
        if(!page) {
            page = this.state.page;
        } else {
            this.setState({
                page
            });
        }
        const { rows, dispatch, hideLoader } = this.props;
        dispatch(showLoader(true));
        const index = page-1;
        const getHistoryUrl = `${Constants.baseURL}${Constants.uploadHistory}?index=${index}&limit=${rows}${this.getQueryParams()}`;
        fetch(getHistoryUrl)
            .then(response =>{
                hideLoader();
                return response.json()
            })
            .then(jsonData => {
                    if(!jsonData.status)
                    this.setState({
                        uploadHistory: jsonData.response,
                        totalCount: jsonData.totalCount
                    });
                    else
                    console.log(jsonData);
            })
            .catch(error => {
                hideLoader();
                console.log(error);
            })
    }

    hidePopup() {
        this.setState({
            showPopup: false
        })
    }

    // function which executes when user press yes in delete confirmation popup
    onYes() {
        this.hidePopup();
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.deleteHistory.replace('{id}', this.deleteId)}`;
        fetch(url, {
            method: 'DELETE',
        })
        .then(response=>{
            this.getHistory();
        })
        .catch(error=>console.log(error));
    }

    // function which executes when user press no in delete confirmation popup
    onNo() {
        this.hidePopup();
    }

    // function that deletes a particular row in history table
    // sets state to show delete confirmation popup
    deleteRow(id) {
        this.setState({
            showPopup: true
        });
        this.deleteId = id;
    }

    downloadExcel(details) {
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.downloadExcelFromUploadPage.replace('{fileName}', details.fileName).replace('{uId}', details.uid)}`;
        downloadFile(url, undefined, 'xlsx')
        .finally(()=>{
            dispatch(showLoader(false));
        })
    }

    syncData(id) {
        const { dispatch } = this.props;
        const url = `${Constants.baseURL}${Constants.syncUploadHistory.replace('{id}', id)}`
        let success = false;
        dispatch(showLoader(true))
        fetch(url, {
            method: 'POST'
        }).then(response=>response.json())
        .then(data=>{
            if(data.status=='Success') {
                success = true;
                this.getHistory()
            }
            
        }).catch(error=>{
            console.log(error)
        }).finally(()=>{
            dispatch(showLoader(false))
            if(success) {
                toast.success('Success: Sync complete', toastOptions);
            } else {
                toast.error('Error: Sync failed', toastOptions);
            }
        })
    }

    /**
     * This function builds the table body
     */
    buildTableBody() {
        const { uploadHistory, totalCount, page } = this.state;
        const { rows, editable } = this.props;
        const totalPages = parseInt(Math.ceil(totalCount/rows));
        return (
            uploadHistory.map((row, index) => {
                const showSyncStatus = (row.syncStatus !== 'Success');
                return(
                    <tr key={'row' + index}>
                        {
                            columns.map((column, index) =>
                                <td key={index}>
                                    {row[column.key] !== null ? JSON.stringify(row[column.key]).replace(/^"/, '').replace(/"$/, '') : ''}
                                </td>
                            ).concat(
                                !editable ? [] :
                                <td key={'row'+index+1} className="user-actions">
                                    {row.dataCount !== null &&
                                        <React.Fragment>
                                            <Tooltip title="Download as Excel">
                                                <img
                                                    src={DownloadExcel}
                                                    width="25px"
                                                    height="25px"
                                                    style={{marginBottom: "10px", marginRight: "10px", cursor: 'pointer'}}
                                                    onClick={()=>{
                                                        this.downloadExcel(row);
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="This will delete corresponding uploaded data in the DB">
                                                <span className="delete" style={{marginTop: "10px"}} onClick={()=>{
                                                    this.deleteRow(row.id);
                                                }} />
                                            </Tooltip>
                                            <Tooltip title="Sync">
                                                <img
                                                    src={SyncIcon}
                                                    width="25px"
                                                    height="25px"
                                                    style={{
                                                        marginBottom: "10px",
                                                        marginLeft: "10px",
                                                        cursor: showSyncStatus ? 'pointer' : 'no-drop',
                                                        filter: showSyncStatus ? 'grayscale(0)' : 'grayscale(1)'
                                                    }}
                                                    onClick={()=>{
                                                        if(showSyncStatus) {
                                                            this.syncData(row.uid)
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                </td>
                            )
                        }
                    </tr>
                )
            }).concat(
                <tr>
                    <td colSpan={100} className="history-table-footer">
                        <span className={page===1?"disabled":""} onClick={()=>{this.getHistory(page-1)}}>&#9664;</span>&nbsp;&nbsp;
                        <select
                            onChange={(event)=>{
                                this.getHistory(parseInt(event.target.value));
                            }}
                            value={page}
                        >
                            {
                                Array(totalPages).fill(1).map((v, k) => <option key={'page'+k} value={k+1}>{k+1}</option>)
                            }
                        </select>
                        &nbsp;&nbsp;<span className={totalPages===page ? "disabled" : ""} onClick={()=>{this.getHistory(page+1)}}>&#9654;</span>
                    </td>
                </tr>
            )
        )
    }

    /**
     * This function is to sort the table based on the column object passed to it
     * 
     * @param {object} column object
     * @param {integer} index index of column to be sorted
     */
    sortTable(column, index=0) {
        let { uploadHistory, sortOrderAscending, sortedColId } = this.state;
        if(sortedColId === index) {
            sortOrderAscending = !sortOrderAscending;
        } else {
            sortOrderAscending = true;
            sortedColId = index;
        }
        uploadHistory = uploadHistory.sort((row1, row2)=>{
            const string1 = JSON.stringify(row1[column.key]);
            const string2 = JSON.stringify(row2[column.key]);
            const diff = string1.localeCompare(string2);
            return sortOrderAscending ? diff : -1*diff;
        });
        this.setState({
            uploadHistory,
            sortOrderAscending,
            sortedColId
        })
    }

    render() {
        const { uploadHistory, sortOrderAscending, sortedColId, showPopup } = this.state;
        const { editable } = this.props;
        return (
            <div className="listing-grid table-responsive table-container">
                { showPopup &&
                    <Popup onYes={()=>this.onYes()} onNo={()=>this.onNo()} />
                }
                <table className="table">
                    <thead>
                        <tr>
                            {
                                columns.map((column, index) =>
                                    <th key={'column'+index} className="history-table-header" scope="col" onClick={()=>{
                                        this.sortTable(column, index);
                                    }}>
                                        {column.label}&nbsp;&nbsp;
                                        <span className="sort-triangles">
                                            { (sortedColId !== index || (sortedColId === index && sortOrderAscending)) &&
                                                <span className="up-arrow">&#9652;</span>
                                            }
                                            { (sortedColId !== index || (sortedColId === index && !sortOrderAscending)) &&
                                                <span className="down-arrow">&#9662;</span>
                                            }
                                        </span>
                                    </th>
                                )
                            }
                            {editable && <th scope="col">Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {uploadHistory.length > 0 && this.buildTableBody()}
                    </tbody>
                </table>
                {uploadHistory.length === 0 && <div className="text-lg-center">No data</div>}
            </div>
        )
    }
}

UploadHistory.defaultProps = {
    editable: true,
    searchParams: {}
}

export default connect(null, null, null, {forwardRef: true})(UploadHistory);