import React from 'react';
import './styles.css';
import HorizontalBarChart from '../Dashboard/HorizontalBarChart/HorizontalBarChart';
import YTD from '../Dashboard/YTD/YTD';
import Constants, { downloadFile, numberWithComas, roles } from '../../util/Constants';
import Distributors from '../Distributors';
import ArrowIcon from '../../../images/icon-right-arrow.png';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';
import DownloadIcon from '../../../images/icon-fileDL.png';
import { Tooltip,FormControl, FormControlLabel } from '@material-ui/core';
import $ from 'jquery';
import Button from '../ZipReportComponent/Button'
import SearchIcon from '../../../images/icon-search.png'
import { WheatSwitch } from '../../util/Constants';
import Multiselect from '../MultiselectComponents/Multiselect';
import LeadershipGraph from './LeadershipLineGraph';
//import * as d3 from 'd3';

const tempData = [
    {
        currentYtd: 100,
        previousYtd: 200,
        mfg: 'man 1'
    },
    {
        currentYtd: 500,
        previousYtd: 300,
        mfg: 'man 2'
    },
    {
        currentYtd: 700,
        previousYtd: 900,
        mfg: 'man 3'
    },
    {
        currentYtd: 600,
        previousYtd: 800,
        mfg: 'man 4'
    },
    {
        currentYtd: 240,
        previousYtd: 850,
        mfg: 'man 5'
    }
]

const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

const colors = ['#bad0af', '#f1f1f1', '#f0b8b8', '#e67f83', '#de425b', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#488f31', '#83af70',];

const tooltipTemplate = `
    <div style="text-align: center; font-weight: 600;">
        #{data[0].name}
    </div>
    <div>
        <svg width='10px' height='10px'>
            <rect width='10px' height='10px' fill='#{data[0].bars[0].fill}'></rect>
        </svg>
        #{numberWithComas(data[0].bars[0].value)}
    </div>
`

const today = new Date();

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

class SAMDashboard extends React.Component {

    constructor(props) {
        super(props);
        if (today.getMonth()===0) {
            this.year = today.getFullYear()-1;
            this.monthOrQuarter = months[11].toLowerCase();
        } else {
            this.year = today.getFullYear();
            this.monthOrQuarter = months[today.getMonth()-1].toLowerCase();
        }
        const { userDetails } = props;
        this.currentUserRole = userDetails.role;
        this.distributor = '';
        this.samDashboardRef = React.createRef();
        this.samDashboardFirstRow = React.createRef();
        this.type = '';
        this.hqfilter = true;
        this.state = {
            chartData: [],
            ytdData: [],
            year: this.year,
            monthOrQuarter: this.monthOrQuarter,
            horizontalBarPage: 1,
            verticalBarPage: 1,
            distributor: '',
            type: [],
            distributors: [],
            hqfilter: this.hqfilter,
            leadershipGraphData: []
        }
    }

    onWindowResize() {
        if (this.samDashboardFirstRow && this.samDashboardFirstRow.current) {
            let height = document.documentElement.clientHeight - $(this.samDashboardRef.current).offset().top - 80;
            $(this.samDashboardFirstRow.current).css('min-height', `${height}px`);
        }
    }

    calculateElementHeight() {
        this.resizeListener = window.addEventListener('resize', this.onWindowResize)
        this.onWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize)
    }

    componentDidMount() {
        this.calculateElementHeight();
        this.refetchData();
    }

    static loading = []

    /**
     * Function to show or hide loader
     * @param {boolean} load - show or hide loader
     */
    showLoader(load = false) {
        const { dispatch } = this.props;
        if (load) {
            SAMDashboard.loading.push(load);
            dispatch(showLoader(true));
        } else {
            SAMDashboard.loading.pop();
        }
        if (SAMDashboard.loading.length === 0) {
            dispatch(showLoader(false));
        }
    }

    refetchData() {
        this.fetchSalesData();
        this.fetchYtdData();
    }

    fetchYtdData() {
        const url = `${Constants.baseURL}${Constants.dashboardGetSamYTD}${this.getQueryParamString()}`;
        this.showLoader(true);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if(data.status !== 500)
                this.setState({
                    ytdData: data
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.showLoader(false);
            })
    }

    /**
     * Function to return query parameters for get request
     * 
     * @param {boolean} yearOnly - returns only year if this is true
     */
    getQueryParamString(yearOnly = false) {
        const { year, monthOrQuarter, type } = this;
        let url = `?year=${year}&type=${type}`;
        if(months.map(month => month.toLowerCase()).includes(monthOrQuarter.toLowerCase())) {
            url = `${url}&month=${monthOrQuarter}`
        } else {
            url = `${url}&quarter=${monthOrQuarter}`;
        }
        if (yearOnly) {
            return url;
        }
        return `${url}&role=${this.currentUserRole}&distributor=${this.distributor}&hqfilter=${this.hqfilter}`;
    }

    fetchSalesData() {
        const url = `${Constants.baseURL}${Constants.getSamDashboardData}${this.getQueryParamString()}`;
        this.showLoader(true);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const chartData =
                    data.map((manufacturer, index) => ({
                        name: manufacturer.mfgName,
                        bars: [
                            {
                                value: manufacturer.achievedRevenue,
                                fill: colors[index % colors.length]
                            }
                        ]
                    }));
                this.setState({
                    chartData
                })
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.showLoader(false);
            })
    }
	
    downloadMySales() {
        const url = `${Constants.baseURL}${Constants.downloadSamSales}${this.getQueryParamString()}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));

        downloadFile(url, "my-sales", "xlsx")
            .finally(() => {
                dispatch(showLoader(false));
            })
    }

    downloadMonthlyTrends(rep, hqfilter) {
        if(hqfilter===undefined){
            hqfilter=true;
        }
        let queryParams = ''
        queryParams += `role=${this.currentUserRole}&hqfilter=${hqfilter}`
        if (rep) {
            queryParams += `${queryParams ? '&' : ''}repName=${rep}`
        }
        if(this.currentUserRole=='SalesManager' && !rep){
            queryParams += `${queryParams ? '&' : ''}manager=${this.currentUserName}`
        }
        if(this.currentUserRole=='SalesRep' && !rep){
            queryParams += `${queryParams ? '&' : ''}repName=${this.currentUserName}`
        }
        const url = `${Constants.baseURL}${Constants.downloadMonthlyTrends}${queryParams ? `?${queryParams}` : ''}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));     

        downloadFile(url, "monthly-trends", "xlsx")
            .finally(() => {
                dispatch(showLoader(false));
            })
    }

    fetchLeadershipGraphData(manufacturer, distributor, types, states, reps, hqfilter) {
        if(hqfilter===undefined){
            hqfilter=true;
        }
        let queryParams = ''
        queryParams += `role=SAM&hqfilter=${hqfilter}`
        if (manufacturer) {
            queryParams += `${queryParams ? '&' : ''}manufacture=${manufacturer}`
        }
        if (distributor) {
            queryParams += `${queryParams ? '&' : ''}distributor=${distributor}`
        }
        if (types) {
            queryParams += `${queryParams ? '&' : ''}type=${types}`
        }
        if (states) {
            queryParams += `${queryParams ? '&' : ''}state=${states}`
        }
        if (reps) {
            queryParams += `${queryParams ? '&' : ''}repName=${reps}`
        }
        if(this.currentUserRole=='SalesManager' && !reps){
            queryParams += `${queryParams ? '&' : ''}manager=${this.currentUserName}`
        }

        const url = `${Constants.baseURL}${Constants.getLeadershipGraphData}${queryParams ? `?${queryParams}` : ''}`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    leadershipGraphData: data
                })
            }).catch(error => {
                console.log(error);
            })
    }


    render() {

        const { chartData, monthOrQuarter, year, horizontalBarPage, ytdData, verticalBarPage, type, hqfilter, distributors, leadershipGraphData } = this.state;
        const horizontalBarCount = chartData.length;
        const verticalBarCount = ytdData.length;
        return (
            <React.Fragment>
                <div ref={this.samDashboardRef} className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 dashboard-dropdowns-container">
                            <div className="dashboard-dropdown">
							<FormControl className="select-input">
                                    <FormControlLabel
                                        control={
                                            <WheatSwitch
                                                checked={hqfilter}
                                                color="default"
                                                onClick={(event) => {
													this.hqfilter= event.target.checked;
                                                    this.setState({
                                                        hqfilter: event.target.checked
                                                    })
                                                }}
                                            />
                                        }
                                        label='HQ Filter'
                                        className="status-switch"
                                    />
                            </FormControl>
							</div>
							<div className="dashboard-dropdown">
                            <Multiselect
                                    label="All Types"
                                    values={['Purch', 'POS'].map(type => ({
                                        value: type,
                                        id: type
                                    }))}
                                    value={type}
                                    onChange={event => {
                                        this.type = event.target.value.join(',')
                                        this.setState({
                                            type: event.target.value
                                        })
                                    }}
                                    labelStyle={{ marginLeft: '2px', fontSize: '15px' }}
                                    containerStyle={{ border: '1px solid gray', backgroundColor: 'rgba(78, 80, 84, 0.466)', width: 150 }}
                                    inputProps={{
                                        'aria-label': 'Without label',
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className="dashboard-dropdown">
                                <Distributors
                                    label="All Distributors"
                                    multiselect
                                    value={distributors}
                                    onChange={event => {
                                        this.distributor = event.target.value.join(',');
                                        this.setState({
                                            distributors: event.target.value
                                        })
                                    }}
                                    labelStyle={{ marginLeft: '2px', fontSize: '15px' }}
                                isStrategic= "true"
                                />
                            </div>
                            <div className="dashboard-dropdown">
                                <select
                                    className="select special-select"
                                    value={year}
                                    onChange={(event) => {
                                        this.year = event.target.value;
                                        this.setState({
                                            year: event.target.value
                                        })

                                    }}
                                >
                                    {
                                        Array.from({ length: 3 }, (v, k) =>
                                            today.getFullYear() - k
                                        ).map(year => <option value={year}>{year}</option>)
                                    }
                                </select>
                            </div>
                            <div className="dashboard-dropdown">
                                <select
                                    className="select special-select"
                                    value={monthOrQuarter}
                                    onChange={(event) => {
                                        this.monthOrQuarter = event.target.value;
                                        this.setState({
                                            monthOrQuarter: event.target.value
                                        })

                                    }}
                                >
                                    <optgroup label="Quarters">
                                        {
                                            quarters.map(quarter => <option key={quarter} value={quarter}>{quarter}</option>)
                                        }
                                    </optgroup>
                                    <optgroup label="Months">
                                        {
                                            months.map(month => <option key={month} value={month.toLowerCase()}>{month}</option>)
                                        }
                                    </optgroup>
                                </select>
                            </div>
                            <div className="dashboard-search-button">
                                <Button
                                    dimension={20}
                                    icon={SearchIcon}
                                    onClick={() => { this.refetchData(); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid dashboard-container">
                    <div className="inner-dashboard-container">
                        <div className="row first-dashboard-row">
                            <div className="col-sm-8 dashboard-cell-1">
                                <div className="graph-container type-sales">
                                    <h2>SALES - {this.year}</h2>
                                    <div className="icons">
                                        {horizontalBarCount > Constants.maxSAMHorizontalBars &&
                                            <>
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={`rotate180 ${horizontalBarPage === 1 ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        this.setState({
                                                            horizontalBarPage: horizontalBarPage - 1
                                                        })
                                                    }}
                                                />
                                                {horizontalBarPage + " "}
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={horizontalBarPage === Math.ceil(horizontalBarCount / Constants.maxSAMHorizontalBars) ? 'disabled' : ''}
                                                    onClick={() => {
                                                        this.setState({
                                                            horizontalBarPage: horizontalBarPage + 1
                                                        })
                                                    }}
                                                />
                                            </>
                                        }


                                    </div>
                                    <div className="col-sm-12 dashboard-cell-4" style={{paddingLeft: "0", paddingRight: "0"}}>

                                        <HorizontalBarChart
                                            data={chartData.slice((horizontalBarPage - 1) * Constants.maxSAMHorizontalBars, horizontalBarPage * Constants.maxSAMHorizontalBars)}
                                            tooltipTemplate={tooltipTemplate}
                                        />

                                    </div>
                                    <div className="sub_div" widht="100%" >
                                        <Tooltip title="Download" >
                                            <img className="float-right" src={DownloadIcon} width="40px" height="40px" onClick={() => {
                                                this.downloadMySales();
                                            }} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-5 dashboard-cell-2">
                                <div className="graph-container type-ytd" style={{paddingBottom: "20px"}}>
                                    <h2>YTD COMPARISON</h2>
                                    <div className="icons">
                                        {verticalBarCount > Constants.maxSAMVerticalBars &&
                                            <>
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={`rotate180 ${verticalBarPage === 1 ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        this.setState({
                                                            verticalBarPage: verticalBarPage - 1
                                                        })
                                                    }}
                                                />
                                                {verticalBarPage + " "}
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={verticalBarPage === Math.ceil(verticalBarCount / Constants.maxSAMVerticalBars) ? 'disabled' : ''}
                                                    onClick={() => {
                                                        this.setState({
                                                            verticalBarPage: verticalBarPage + 1
                                                        })
                                                    }}
                                                />
                                            </>
                                        }
                                    </div>
                                    <YTD
                                        data={ytdData.slice((verticalBarPage - 1) * Constants.maxSAMVerticalBars, verticalBarPage * Constants.maxSAMVerticalBars)}
                                        aspectRatio='none'
                                        width='100%'
                                        height='100%'
                                        parentHeight='100%'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row third-dashboard-row">
                                        <div className="graph-container">
                                        <h2>Monthly Trends</h2>
                                            <LeadershipGraph
                                                data={leadershipGraphData}
                                                fetchData={(manufacturer, distributor, type, stateCode, rep, hqfilte) => {
                                                    this.fetchLeadershipGraphData(manufacturer, distributor, type, stateCode, rep, hqfilte);
                                                }}
                                                download={(rep) => {
                                                    this.downloadMonthlyTrends(rep, hqfilter);
                                                }}
                                            />
                                        </div>
                                    </div>
                </div>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
})

export default connect(mapStateToProps)(SAMDashboard);