import React from 'react';

export default class TableHeaders extends React.Component {
    render() {
        const { columns, sortedColId, sortOrderAscending, sortTable } = this.props;
        if (columns) {
            return (
                columns.map((column, index) =>
                    <th key={'column' + index} className="history-table-header" scope="col" onClick={() => {
                        if(!column.nosort) {
                            sortTable(column, index);
                        }
                    }}>
                        {column.label}&nbsp;&nbsp;
                        { !column.nosort &&
                            <span className="sort-triangles">
                                {(sortedColId !== index || (sortedColId === index && sortOrderAscending)) &&
                                    <span className="up-arrow">&#9652;</span>
                                }
                                {(sortedColId !== index || (sortedColId === index && !sortOrderAscending)) &&
                                    <span className="down-arrow">&#9662;</span>
                                }
                            </span>
                        }
                    </th>
                )
            )
        } else {
            return null;
        }
    }
}

TableHeaders.defaultProps = {
    sortTable: ()=>{},
    columns: []
}