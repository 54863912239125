import React from 'react';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import  TableBody from '../../ZipReportComponent/TableBody';
import TableFooter from '../../ZipReportComponent/TableFooter';

const columns = [
    {
        label: 'Rep Name',
        key: 'repName',
        nosort: true,
    },
    {
        label: 'Manager',
        key: 'manager',
        nosort: true,
    },
    {
        label: 'Manager Region',
        key: 'managerRegion',
        nosort: true
    },
    {
        label: 'Manager ID',
        key: 'managerId',
        nosort: true,
    },
    {
        label: 'Territory ID',
        key: 'territoryId',
        nosort: true,
    },
    {
        label: 'Territory #',
        key: 'territoryNo',
        nosort: true,
    },
    {
        label: 'Territory Code',
        key: 'territoryCode',
        nosort: true,
    },
    {
        label: 'Territory Desc',
        key: 'territoryDesc',
        nosort: true,
    }
]

export default class TerritoryCodeTable extends React.Component {

    render() {
        const { editable, data, onDataChange, deleteRow, resetEditSwitch, totalPages, currentPage, changePage } = this.props;
        return(
            <div className="listing-grid table-responsive table-container mfg-lookup-table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders columns={columns} />
                            {editable && <th className="history-table-header" scope="col" >Action</th >}
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody
                            columns={columns}
                            data={data}
                            onDataChange={onDataChange}
                            hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                                if(String(currentRow.id).startsWith('temp')) {
                                    resultArray.push(index);
                                }
                                return resultArray;
                            }, [])}
                            deleteHistory={deleteRow}
                            disableActions={!editable}
                            resetEditSwitch={resetEditSwitch}
                        />
                    </tbody>
                    <tfoot>
                        <TableFooter
                            totalPages={totalPages}
                            page={currentPage}
                            changePage={changePage}
                        />
                    </tfoot>
                </table>
            </div>
        )
    }
}

TerritoryCodeTable.defaultProps = {
    data: [],
    editable: false,
    onDataChange: () => {},
    deleteRow: () => {},
    resetEditSwitch: false,
    totalPages: 0,
    currentPage: 1,
    changePage: ()=>{}
}