import React from "react";
import RoleSelect from "../../../RoleSelect";
import SearchIcon from "../../../../../images/icon-search.png";
import Button from "../../../ZipReportComponent/Button";
import Table1 from "../UserPrivileges/Table1";
import Constants from "../../../../util/Constants";
import * as Utils from "../Utils";
import { connect } from "react-redux";
import ReportsTable from "../Reports/ReportsTable";

class RolePrivileges extends React.Component {
  state = {
    roleName: "",
    privilegeData: [],
    reportsData: {},
  };

  componentDidMount() {
    this.abortController = new AbortController();
    this.fetchPrivilegeData();
  }

  fetchPrivilegeData(roleName) {
    const { userDetails, showLoader } = this.props;
    const rname = roleName || userDetails.role;
    const url = `${
      Constants.baseURL
    }${Constants.getPrivilegesByRoleName.replace("{roleName}", rname)}`;
    showLoader(true);
    fetch(url, {
      signal: this.abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        const privilegeData = data.filter((privilege) => {
          const condition =
            privilege.screenName !== Constants.reportsScreenName;
          if (!condition) {
            this.setState({
              reportsData: privilege,
            });
          }
          return condition;
        });
        this.setState({
          privilegeData,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        showLoader(false);
      });
  }

  mapTableDataToRequest(data) {
    const { userDetails, allPrivileges, roles } = this.props;
    const { roleName } = this.state;
    const rname = roleName || userDetails.role;
    const role = roles.find((role) => role.roleName === rname);
    const requestBody = [];
    data.forEach((privilege) => {
      if (privilege.privilege && privilege.privilege.startsWith("Reports_")) {
        const priv = Utils.findPrivilege(privilege.privilege, allPrivileges);
        if (priv) {
          requestBody.push({
            privilegeId: priv.privilegeId,
            roleId: role.roleId,
            isAssign: privilege.read,
          });
        }
      } else {
        const readPrivilege = Utils.findPrivilege(
          privilege.screenName + "_Read",
          allPrivileges
        );
        const writePrivilege = Utils.findPrivilege(
          privilege.screenName + "_Write",
          allPrivileges
        );
        if (readPrivilege) {
          requestBody.push({
            privilegeId: readPrivilege.privilegeId,
            roleId: role.roleId,
            isAssign: privilege.read || false,
          });
        }
        if (writePrivilege) {
          requestBody.push({
            privilegeId: writePrivilege.privilegeId,
            roleId: role.roleId,
            isAssign: privilege.write || false,
          });
        }
      }
    });
    return requestBody;
  }

  saveAllChanges(data) {
    if (data.length === 0) {
      return;
    }
    const requestBody = JSON.stringify(this.mapTableDataToRequest(data));
    const url = `${Constants.baseURL}${Constants.setPrivilegeToRole}`;
    const { showLoader } = this.props;
    showLoader(true);
    fetch(url, {
      method: "POST",
      body: requestBody,
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        showLoader(false);
      });
  }

  render() {
    const { userDetails, editable, allPrivileges, roles } = this.props;
    const { roleName, privilegeData, reportsData } = this.state;
    return (
      <div className="container-fluid role-privileges-container user-privileges-container">
        <h6>Role Privileges</h6>
        <div className="row search-container">
          <div className="col-sm-3">
            <RoleSelect
              nolabel={true}
              roles={roles}
              value={roleName || userDetails.role}
              onChange={(event) => {
                this.setState({
                  roleName: event.target.value,
                });
                this.fetchPrivilegeData(event.target.value);
              }}
            />
          </div>
          <div className="col-sm-9">
            <Button
              label="Search"
              icon={SearchIcon}
              dimension={20}
              onClick={() => {}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table1
              privilegeData={Utils.mapPrivilegeDataToTable(privilegeData)}
              onChange={(newData) => {
                this.saveAllChanges(newData);
              }}
              disableActionsWithoutHiding={!editable}
            />
          </div>
        </div>
        {JSON.stringify(reportsData) !== "{}" && (
          <div className="row">
            <div className="col-sm-12">
              <ReportsTable
                data={Utils.mapReportsDataToTable(reportsData, allPrivileges)}
                onChange={(newData) => {
                  this.saveAllChanges(newData);
                }}
                editable={editable && reportsData.editable}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

RolePrivileges.defaultProps = {
  userDetails: {},
  allPrivileges: [],
  showLoader: () => {},
};

const mapStateToProps = (state) => ({
  editable: state.privileges.writable.includes("MASTER"),
});

export default connect(mapStateToProps)(RolePrivileges);
