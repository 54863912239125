import React from 'react';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import TableBody from '../../ZipReportComponent/TableBody';
import TableFooter from '../../ZipReportComponent/TableFooter';
import Constants, { toastOptions,downloadFile } from '../../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../../redux/actions';
import AddAndSaveButtons from '../AddAndSaveButtons';
import { toast } from 'react-toastify';
import Popup from '../../Popup/Popup';
import UploadIcon from '../../../../images/icon-upload.ico';
import DownloadIcon from '../../../../images/icon-save.png';
import Button from '../../ZipReportComponent/Button';
import SimpleUploadComponent from '../../SimpleUploadComponent/SimpleUploadComponent';

const columns = [
    {
        label: 'Potential End User',
        key: 'potentialEndUser',
        nosort: true
    },
    {
        label: 'Zip Code',
        key: 'zip',
        nosort: true
    },
    {
        label: 'State',
        key: 'state',
        nosort: true
    },
    {
        label: 'Industry Type',
        key: 'industryType',
        nosort: true
    },
    {
        label: 'Multi Site',
        key: 'multiSite',
        nosort: true
    },
    {
        label: 'Data Source',
        key: 'dataSource',
        nosort: true
    },
    {
        label: 'Confirmed Distributor',
        key: 'confirmedDistributor',
        nosort: true
    },
    {
        label: 'Confirmed Manufacturer',
        key: 'confirmedManufacturer',
        nosort: true
    }
]

const rowsPerPage = Constants.maxEndUserTableRows;

const dummyRow = {
    confirmedDistributor: "",
    confirmedManufacturer: "",
    createdBy: "",
    createdTime: Date.now(),
    dataSource: "",
    industryType: "",
    lastModifiedBy: "",
    lastModifiedTime: null,
    multiSite: "",
    potentialEndUser: "",
    state: "",
    zip: ""
}

class EndUserTable extends React.Component {

    static tempId = 1;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPages: 0,
            currentPage: 1,
            resetEditSwitch: false,
            showPopup: false
        }
        this.newData = [];
        this.deletedIds = [];
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData(index = 0) {
        const { showLoader, hideLoader } = this.props;
        const { resetEditSwitch } = this.state;
        const url = `${Constants.baseURL}${Constants.getAllEndUserData}?index=${index}&limit=${rowsPerPage}`
        let dataFetchFailed = false;
        showLoader();
        fetch(url)
            .then(response => {
                if (response.status !== 200) {
                    dataFetchFailed = true;
                }
                return response.json()
            }).then(jsonData => {
                const totalPages = Math.ceil(jsonData.totalCount / rowsPerPage)
                this.setState({
                    data: jsonData.response,
                    totalPages,
                    currentPage: index + 1,
                    resetEditSwitch: !resetEditSwitch
                })
            }).catch(error => {
                console.log(error)
            }).finally(_ => {
                hideLoader();
                if (dataFetchFailed) {
                    this.setState({
                        data: [],
                        totalPages: 0,
                        currentPage: 1,
                        resetEditSwitch: !resetEditSwitch
                    })
                    toast.error("Error: Data fetch failed", toastOptions);
                }
                this.deletedIds = []
            })
    }

    saveAllChanges() {
        this.newData = this.newData.filter(data => !this.deletedIds.includes(data.id))
        if (this.newData.length === 0) {
            toast.info("No new data to save", toastOptions);
            return;
        }
        const { showLoader, hideLoader } = this.props;
        const url = `${Constants.baseURL}${Constants.saveAllEndUserData}`
        showLoader();
        let requestSucceeded = false;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(this.newData.map(row => {
                if (String(row.id).startsWith('temp')) {
                    delete row.id
                }
                return row;
            })),
            headers: {
                'Content-type': 'application/json'
            }
        }).then(response => {
            if (response.status === 204) {
                requestSucceeded = true;
            }
        }).catch(error => {
            console.log(error);
        }).finally(_ => {
            hideLoader();
            if (requestSucceeded) {
                this.fetchData(this.state.currentPage - 1)
                toast.success("Success: Data saved successfully", toastOptions);
            } else {
                toast.error("Error: Data save failed", toastOptions);
            }
        })
    }

    deleteEntry(id) {
        const { data } = this.state;
        this.deletedIds.push(id);
        const dataAfterDeletion = data.filter(row => row.id !== id);
        const updateDataConditionally = _ => {
            toast.success("Success: Deleted entry", toastOptions);
            if (dataAfterDeletion.length === 0) {
                this.fetchData()
            } else {
                this.setState({
                    data: dataAfterDeletion
                })
            }
        }
        if (String(id).startsWith('temp')) {
            updateDataConditionally();
            return;
        }
        const { showLoader, hideLoader } = this.props;
        const url = `${Constants.baseURL}${Constants.deleteEndUserData.replace('{id}', id)}`
        showLoader();
        let deleteFailed = true;
        fetch(url, {
            method: 'DELETE'
        }).then(response => {
            if (response.status === 204) {
                deleteFailed = false;
            }
        }).catch(error => {
            console.log(error)
        }).finally(_ => {
            hideLoader();
            if (deleteFailed) {
                toast.error("Error: Delete failed", toastOptions);
            } else {
                updateDataConditionally();
            }
        })
    }

    addEntry() {
        const { data } = this.state;
        this.setState({
            data: [
                ...data,
                {
                    ...dummyRow,
                    id: `temp${EndUserTable.tempId++}`
                }
            ]
        })
    }

    render() {
        const { editable, showLoader, hideLoader } = this.props;
        const { data, totalPages, currentPage, resetEditSwitch, showPopup } = this.state;
        return (
            <div className="container-fluid">
                { showPopup &&
                    <Popup
                        onYes={_ => {
                            this.deleteCallback();
                        }}
                        finally={_ => {
                            this.setState({
                                showPopup: false
                            })
                        }}
                    />
                }
                        <div className="row zip-update-button-container">
                            <SimpleUploadComponent
                                uploadUrl={`${Constants.baseURL}${Constants.uploadEndUserData}`}
                                image={{
                                    src: UploadIcon,
                                    width: 20,
                                    height: 20
                                }}
                                label="UPLOAD"
                                disabled={!editable}
                            />
                            <Button
                                onClick={() => {
                                    showLoader();
                                    downloadFile(`${Constants.baseURL}${Constants.downloadEndUserData}`, 'EndUser', 'xlsx')
                                        .finally(()=>{
                                           hideLoader(); 
                                        })
                                }}
                                icon={DownloadIcon}
                                label='Download'
                                dimension='20'
                            />
                        </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="listing-grid table-responsive table-container mfg-lookup-table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <TableHeaders
                                            columns={columns}
                                        />
                                        {editable && <th className="history-table-header" scope="col" >Action</th >}
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableBody
                                        columns={columns}
                                        data={data}
                                        resetEditSwitch={resetEditSwitch}
                                        onDataChange={newData => {
                                            this.newData = newData;
                                        }}
                                        deleteHistory={id => {
                                            this.setState({
                                                showPopup: true
                                            })
                                            this.deleteCallback = _ => {
                                                this.deleteEntry(id);
                                            }
                                        }}
                                        hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index) => {
                                            if (String(currentRow.id).startsWith('temp')) {
                                                resultArray.push(index);
                                            }
                                            return resultArray;
                                        }, [])}
                                    />
                                </tbody>
                                <tfoot>
                                    <TableFooter
                                        totalPages={totalPages}
                                        page={currentPage}
                                        changePage={newPageIndex => {
                                            this.fetchData(newPageIndex);
                                        }}
                                    />
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={_ => {
                        this.addEntry();
                    }}
                    onSaveClick={_ => {
                        this.saveAllChanges();
                    }}
                    disabled={!editable}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('MASTER')
})

const mapDispatchToProps = dispatch => ({
    showLoader: _ => showLoader(true),
    hideLoader: _ => showLoader(false)
})

EndUserTable.defaultProps = {
    editable: true
}

export default connect(mapStateToProps, mapDispatchToProps)(EndUserTable);