import React from 'react';
import TableHeaders from '../../../ZipReportComponent/TableHeaders';
import TableBody from '../../../ZipReportComponent/TableBody';

const columns = [
    {
        label: "Screen Name",
        nosort: true,
        key: 'screenName'
    },
    {
        label: "Read",
        nosort: true,
        type: 'boolean',
        key: 'read'
    },
    {
        label: "Edit",
        nosort: true,
        type: 'boolean',
        key: 'write'
    }
]

export default class Table1 extends React.Component {

    render() {
        const { privilegeData, onChange, disableActionsWithoutHiding } = this.props;
        const hiddenEditRowIndexes = []
        privilegeData.forEach((privilege, index) => {
            if(privilege.editable === true || privilege.editable === null) {
                hiddenEditRowIndexes.push(index);
            }
        });
        return(
            <div className="listing-grid table-responsive table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders
                                columns={columns}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody
                            columns={columns}
                            data={privilegeData}
                            disableActions={true}
                            hiddenEditRowIndexes={hiddenEditRowIndexes}
                            onDataChange={newData=>onChange(newData)}
                            readOnlyFields={[0]}
                            disableActionsWithoutHiding={disableActionsWithoutHiding}
                        />
                    </tbody>
                </table>
            </div>
        )
    }
}

Table1.defaultProps = {
    privilegeData: [],
    onChange: ()=>{},
    disableActionsWithoutHiding: false
}