import React from 'react';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import  TableBody from '../../ZipReportComponent/TableBody';

const columns = [
    {
        label: 'Distributor',
        key: 'distributor',
        nosort: true,
        type: 'enum'
    },
    {
        label: 'Manufacturer',
        key: 'mfg',
        nosort: true,
        type: 'enum'
    },
    {
        label: 'Plants',
        key: 'plants',
        nosort: true
    },
    // {
    //     label: 'Last Modified By',
    //     key: 'lastModified',
    //     nosort: true
    // },
    // {
    //     label: 'Time',
    //     key: 'time',
    //     nosort: true
    // }
]

export default class PlantMapperTable extends React.Component {

    incaseSensitiveEqual(string1, string2) {
        return String(string1).toUpperCase() === String(string2).toUpperCase();
    }

    filter(data=[]) {
        const { manufacturer, distributor } = this.props;
        return data.filter(row=>(manufacturer === '' || this.incaseSensitiveEqual(row.mfg, manufacturer)) && (distributor === '' || this.incaseSensitiveEqual(row.distributor, distributor)));
    }

    render() {
        const { data, onDataChange, deleteRow, manufacturers, distributors, editable, resetEditSwitch } = this.props;
        return(
            <div className="listing-grid table-responsive table-container mfg-lookup-table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders columns={columns} />
                            {editable && <th className="history-table-header" scope="col" >Action</th >}
                        </tr>
                    </thead>
                    <TableBody
                        columns={(()=>{
                            columns[0].enum = {
                                label: 'Select Distributor',
                                values: distributors.map(distributor=>distributor.distributionName)
                            }
                            columns[1].enum = {
                                label: 'Select Manufacturer',
                                values: manufacturers.map(manufacturer=>manufacturer.mfgName)
                            }
                            return columns;
                        })()}
                        data={this.filter(data)}
                        onDataChange={onDataChange}
                        hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                            if(String(currentRow.id).startsWith('temp')) {
                                resultArray.push(index);
                            }
                            return resultArray;
                        }, [])}
                        deleteHistory={deleteRow}
                        disableActions={!editable}
                        resetEditSwitch={resetEditSwitch}
                    />
                </table>
            </div>
        )
    }
}

PlantMapperTable.defaultProps = {
    editable: true
}