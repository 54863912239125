import React from 'react';
import ShowFiles from './ShowFiles';
import FileDrop from './FileDrop';

/**
 * This is the root component that renders both the file dropbox and the file list
 */
export default class FileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
        }
    }

    render() {
        const { files } = this.state;
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <ShowFiles {...this.props} files={files} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <FileDrop addFiles={(newFiles) => {
                            files.push(...newFiles);
                            this.setState({
                                files
                            })
                        }} />
                    </div>
                </div>
            </div>
        )
    }
}