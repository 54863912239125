import React from 'react';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import  TableBody from '../../ZipReportComponent/TableBody';

const columns = [
    {
        label: 'Manufacturer Name',
        key: 'mfgName',
        nosort: true
    },
    {
        label: 'Manufacturer Code',
        key: 'mfgCode',
        nosort: true
    },
    {
        label: 'Status',
        key: 'status',
        type: 'boolean',
        nosort: true
    },
    {
        label: 'Sort Order',
        key: 'sortOrder',
        type: 'String',
        nosort: true
    },
    {
        label: 'Priority',
        key: 'priority',
        type: 'String',
        nosort: true
    }
]

export default class ManufacturersTable extends React.Component {

    incaseSensitiveSearch(string1, string2) {
        return String(string1).toUpperCase().startsWith(String(string2).toUpperCase());
    }

    filter(data=[]) {
        const { manufacturer } = this.props;
        return data.filter(row=>(manufacturer === '' || this.incaseSensitiveSearch(row.mfgName, manufacturer)));
    }

    render() {
        const { data, onDataChange, deleteRow, editable, resetEditSwitch } = this.props;
        return(
            <div className="listing-grid table-responsive table-container mfg-lookup-table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders columns={columns} />
                            {editable && <th className="history-table-header" scope="col" >Action</th >}
                        </tr>
                    </thead>
                    <TableBody
                        columns={columns}
                        data={this.filter(data)}
                        onDataChange={onDataChange}
                        hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                            if(String(currentRow.id).startsWith('temp')) {
                                resultArray.push(index);
                            }
                            return resultArray;
                        }, [])}
                        deleteHistory={deleteRow}
                        disableActions={!editable}
                        resetEditSwitch={resetEditSwitch}
                    />
                </table>
            </div>
        )
    }
}

ManufacturersTable.defaultProps = {
    data: [],
    onDataChange: ()=>{},
    deleteRow: ()=>{},
    editable: true
}