// this reducer is to handle privileges of logged in user
const savedPrivileges = window.sessionStorage.getItem('privileges');
const initialState = savedPrivileges ? JSON.parse(savedPrivileges) : {
  readable: [],
  writable: []
}

const privileges = (state=initialState, action) => {
    switch(action.type) {
        case 'SET_PRIVILEGES':
            window.sessionStorage.setItem('privileges', JSON.stringify(action.privileges));
            return {
                ...state,
                ...action.privileges
            }
        default:
            return {...state}
    }
}

export default privileges;