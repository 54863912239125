// This method is the loader reducer which handles showing and hiding the loader
const showLoader = (state = false, action) => {
    switch(action.type) {
        case 'TOGGLE_LOADER':
            return action.showLoader;
        default:
            return state;
    }
}

export default showLoader;