import React from 'react';
import SearchButton from '../ZipReportComponent/SearchButton';
import Constants, { toastOptions } from '../../util/Constants';
import SyncIcon from '../../../images/sync-icon.jpg';
import Button from '../ZipReportComponent/Button';
import { toast } from 'react-toastify';
import { showLoader } from '../../../redux/actions'


const types = [
    "SalesData",
    "ProdFamilyMapping",
    "Goal",
    "HQFilter",
    "DistributorRepMapping",
    "MfgRepMapping",
    "ZipRepMapping"
]

export default class SearchBox extends React.Component {
    constructor(props) {
        super(props);
    }
    state={
        filename: '',
        type: 'SalesData',
        lastSyncTime: ''
    }
    componentDidMount() {
        this.fetchLastSyncTime();
    }

    fetchLastSyncTime() {
        const url = `${Constants.baseURL}${Constants.getLastSyncTime}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    lastSyncTime: data.lastSyncTime
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
            })
    }

    syncData() {
       // const { dispatch } = this.props;
        let success = false;
        //dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.syncDashBoard}`;
        fetch(url,{
            method: 'POST'
        })
            .then(response=>response.json())
            .then(data=>{
                if(data.status=='Success') {
                    success = true;
                    this.getHistory()
                }
                
            }).catch(error=>{
                console.log(error)
            }).finally(()=>{
               // dispatch(showLoader(false))
                if(success) {
                    toast.success('Success: Sync complete', toastOptions);
                } else {
                    toast.error('Error: Sync failed', toastOptions);
                }
            })
    }

    render() {
        const { filename, type, lastSyncTime } = this.state;
        const { onSearch } = this.props;
        return(
            <React.Fragment>
                <div className="col-sm-3">
                    <select
                        className="select special-select"
                        value={type}
                        onChange={event=>{
                            this.setState({
                                type: event.target.value
                            })
                        }}
                    >
                        {
                            types.map(type=><option key={type} value={type}>{type}</option>)
                        }
                    </select>
                </div>
                <div className="col-sm-3">
                    <input
                        value={filename}
                        placeholder="File Name"
                        className="txt form-control"
                        type="text"
                        onChange={event=>{
                            this.setState({
                                filename: event.target.value
                            })
                        }}
                    />
                </div>
                <div className="col-sm-1">
                    <SearchButton
                        onClick={()=>{
                            onSearch({filename, type})
                        }}
                    />
                </div>
                <div className="col-sm-1"/>
                <div className="col-sm-2" classname="margin-top:2px"> <span classname="margin-top:2px">Last Sync Time : {lastSyncTime}</span>
                </div>
                <div className="col-sm-1"><Button
                                                            label="Sync"
                                                            icon={SyncIcon}
                                                            dimension={20}
                                                            onClick={_ => {
                                                                this.syncData();
                                                            }}
                                                        />
                                                                        <div className="col-sm-1"/>
                 </div>
            </React.Fragment>
        )
    }
}

SearchBox.defaultProps = {
    onSearch: ()=>{}
}