import React from 'react';
import * as d3 from 'd3';

export default class Speedometer extends React.Component {
    constructor(props) {
        super(props);
        this.speedometerRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify({value: prevProps.percentage}) !== JSON.stringify({value: this.props.percentage})) {
            this.drawChart();
        }
    }

    componentDidMount() {
        this.drawChart();
    }

    drawChart() {
        const { size, dx, dy, percentage } = this.props;
        if(isNaN(percentage)) {
            return;
        }
        const arcStart = 1.35;
        const arcEnd = 2.65;
        const markerStart = (()=>{
            if(percentage > 100) {
                return 2.645;
            }
            return arcStart - 0.005 + (arcEnd - arcStart)*percentage/100;
        })()
        // clear arc
        d3.select(this.speedometerRef.current).selectAll('svg').remove();
        const svg = d3.select(this.speedometerRef.current)
            .append('svg')
            .attr('width', size + dx)
            .attr('height', size + dy)
            // .attr("preserveAspectRatio", "xMinYMin meet")
            .attr('viewBox', `0 0 ${size + dx} ${size + dy}`)
        const linearGradient = svg.append('defs')
            .append('linearGradient')
            .attr('id', 'my-gradiant')
            .attr('x1', '0%')
            .attr('y1', '100%')
            .attr('x2', '100%')
            .attr('y2', '100%')
            
        linearGradient.append('stop')
            .attr('offset', '0%')
            .style('stop-color', '#ce2d2d');
        linearGradient.append('stop')
            .attr('offset', '100%')
            .style('stop-color', '#a6cf4b');
        const arcGenerator = d3.arc();
        const pathData = arcGenerator({
            startAngle: arcStart * Math.PI,
            endAngle: arcEnd * Math.PI,
            innerRadius: size * 0.35,
            outerRadius: size / 2,
        })
        const marker = arcGenerator({
            startAngle: markerStart * Math.PI,
            endAngle: (markerStart + 0.01) * Math.PI,
            innerRadius: size * 0.3,
            outerRadius: size/2
        })
        const g = svg.append('g')
            .style('transform', `translate(50%, 63%)`)
            .style('fill', 'white')
        g.append('text')
            .html(`${percentage}%`)
            .attr('x', 0)
            .attr('y', 0)
            .attr('text-anchor', 'middle');
        g.append('text')
            .html('0%')
            .attr('x', '-45%')
            .attr('y', '25%')
        g.append('text')
            .html('100%')
            .attr('x', size/2-10)
            .attr('y', '25%')
        g.append('text')
            .html('50%')
            .attr('x', 0)
            .attr('y', -size/2-5)
            .style('transform', `translate(-8px)`)
       // g.append('text')
         //   .html('Overall Achievement')
            .attr('x', 0)
            .attr('y', (size/2)-30)
            .attr('text-anchor', 'middle');
        g.append('path')
            .attr('d', pathData)
            .attr('fill', 'url(#my-gradiant)')
        g.append('path')
            .attr('d', marker)
            .style('fill', 'white')
    }

    render() {
        return (
            <div ref={this.speedometerRef}></div>
        )
    }
}

Speedometer.defaultProps = {
    size: 300,
    dx: 20,
    dy: 20,
    percentage: 0
}