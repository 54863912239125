import * as React from "react";
import user from '../../images/user-imag.png';
import logo from "../../images/logo-main.png";
import { connect } from 'react-redux';
import { setUserDetails, showLoader } from '../../redux/actions';
import { Tooltip } from '@material-ui/core';
import Constants, { toastOptions, constructErrorBody } from '../util/Constants';
import axios from 'axios';
import { toast } from 'react-toastify';

class HeaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
    }

    uploadProfilePicture(event) {
        const pic = event.target.files[0];
        if(pic && ['image/jpeg', 'image/png'].includes(pic.type)) {

            const { userDetails, dispatch } = this.props;

            // read file and set it as current image
            const fileReader = new FileReader();
            fileReader.addEventListener('load', ()=>{
                userDetails.profilePic = fileReader.result;
                dispatch(setUserDetails(userDetails));
            }, false)
            fileReader.readAsDataURL(pic);

            // upload file
            dispatch(showLoader(true));
            const url = `${Constants.baseURL}${Constants.uploadProfilePicture}`;
            const formData = new FormData();
            formData.append('file', pic);
            axios.post(url, formData, { headers: {
                'Content-type': 'multipart/form-data'
            }}).then(response=>{
                console.log(response)
                toast.success('Success: Image uploaded successfully', toastOptions);
            }).catch(error=>{
                console.log(error);
                // let errorMessage = '';
                // if(error.response) {
                //     errorMessage = constructErrorBody(error.response.status, error.response.data.error, error.response.data.message);
                // }
                // toast.error(errorMessage, toastOptions);
                toast.error("Error: Image upload failed", toastOptions);
            }).finally(()=>{
                dispatch(showLoader(false));
            })
        } else {
            toast.error('Error: Please select JPEG or PNG image', toastOptions);
        }
    }

    render() {

        const { userDetails, profilePic, dispatch } = this.props;

        return (
            <header className="header">
                <input ref={this.fileRef} type="file" style={{display: 'none'}} onChange={event=>this.uploadProfilePicture(event)} />
                
                <img src={logo} alt="Event Management" width='13%' className="logo desktop"/>
                <div className="logo mobile">
                    <img src={logo} alt="Event Management"/>
                </div>
                    
                <div className="user-info">
                    <ul>
                        <li><span>Logged in As :</span><strong>{userDetails.name}</strong></li>
                        <li><span>Role :</span><strong>{userDetails.role}</strong></li>
                        <li><span>Last Login :</span><strong>{new Date(userDetails.lastLoggedIn).toLocaleString('en-US', {day: '2-digit', month: 'short', year:'numeric', hour:'2-digit', minute:'2-digit'})}</strong></li>
                    </ul>
                    <div className="user-img">

                        <img src={profilePic} width="50" height="50" alt=""/>
                        <div className="header-icon-tray">
                            <Tooltip title="Change Profile Picture">
                                <strong className="fa fa-pencil-square-o" onClick={()=>{
                                    this.fileRef.current.click();
                                }} />
                            </Tooltip>
                            <Tooltip title="Logout">
                                <strong className="fa fa-sign-out" onClick={()=>{
                                    dispatch(setUserDetails({}));
                                    window.location.href="/";
                                }} />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </header>);
    }

}

const mapStateToProps = state => ({
    userDetails: state.userDetails,
    profilePic: state.userDetails.profilePic || user
})

export default connect(mapStateToProps)(HeaderComponent);