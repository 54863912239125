import * as React from "react";
import ZipDataTable from "./ZipDataTable";
import ZipSearchBox from './ZipSearchBox';
import SimpleUploadComponent from '../SimpleUploadComponent/SimpleUploadComponent';
import Constants, { toastOptions, downloadFile } from '../../util/Constants';
import Save from '../../../images/icon-save.png';
import { connect } from "react-redux";
import { showLoader } from '../../../redux/actions';
import { toast } from "react-toastify";
import './ZipReportComponent.css';
import ZipUpdateBox from "./ZipUpdateBox";
import UploadIcon from '../../../images/icon-upload.ico';
import DownloadIcon from '../../../images/icon-save.png';
import Button from '../ZipReportComponent/Button';
import AddAndSaveButtons from '../MasterComponent/AddAndSaveButtons';

const columns = [
    {
        label: 'Id',
        key: 'id'
    },
    {
        label: 'Zip Code',
        key: 'zipCode',
        searchKey: 'zip'
    },
    {
        label: 'Primary City',
        key: 'primaryCity',
        searchKey: 'city'
    },
    {
        label: 'State',
        key: 'state',
        searchKey: 'state'
    },
    {
        label: 'County',
        key: 'county',
        searchKey: 'county'
    },
    {
        label: 'Rep',
        key: 'rep',
        searchKey: 'rep'
    },
    {
        label: 'Iss',
        key: 'iss',
        searchKey: 'iss'
    },
    {
        label: 'Rsm',
        key: 'rsm',
        searchKey: 'rsm'
    },
    {
        label: 'Region',
        key: 'region',
        searchKey: 'region'
    },
    {
        label: 'Territory',
        key: 'territory',
        searchKey: 'territory'
    }
];

const searchableColumns = columns.filter(column => column.key !== 'id');

const bulkUpdateColumns = columns.filter(column => ['rsm', 'iss', 'rep', 'region'].includes(column.key));

class ZipReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
            searchObject: {},
            resetEditSwitch: false
        }
        this.zipDataTableRef = React.createRef();
    }

    /**
     * Function to save all changes made to data table
     */
    saveAllChanges() {
        if (this.dataArray) {
            const { dispatch } = this.props;
            dispatch(showLoader(true));
            this.dataArray = this.dataArray.map(row=>{
                if(String(row.id).startsWith('temp')) {
                    delete row.id;
                }
                return row;
            })
            const body = JSON.stringify(this.dataArray);
            const url = `${Constants.baseURL}${Constants.zipUpdate}`;
            fetch(url, {
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                dispatch(showLoader(false));
                console.log(response);
                if (response.status === 200) {
                    toast.success("Success: Save success", toastOptions);
                } else {
                    throw new Error("Save failed");
                }
                this.zipDataTableRef.current.fetchHistory();
                this.setState({
                    resetEditSwitch: !this.state.resetEditSwitch
                })
            }).catch(error => {
                dispatch(showLoader(false));
                console.log(error);
                toast.error("Error: Save failed", toastOptions);
                this.zipDataTableRef.current.fetchHistory();
            })
        }
    }

    render() {
        const { searchString, resetEditSwitch } = this.state;
        const { dispatch, editable } = this.props;

        return (<div className="tab-pane show active" id="nav-zip" role="tabpanel" aria-labelledby="nav-zip-tab">
            <div className="container-fluid">
                <div className="row zip-update-button-container">
                    <SimpleUploadComponent
                        uploadUrl={`${Constants.baseURL}${Constants.zipUpload}`}
                        image={{
                            src: UploadIcon,
                            width: 20,
                            height: 20
                        }}
                        label="UPLOAD"
                        disabled={!editable}
                    />
                    <Button
                        onClick={() => {
                            const url = `${Constants.baseURL}${Constants.zipDownload}`;
                            dispatch(showLoader(true));
                            downloadFile(url, "zip-rep-mapping", 'xlsx')
                                .finally(()=>{
                                    dispatch(showLoader(false));
                                })
                        }}
                        icon={DownloadIcon}
                        label='Download'
                        dimension='20'
                    />
                </div>
                <div className="col-sm-12 search-box-container">
                    <fieldset>
                        <legend><h6>&nbsp;Search&nbsp;</h6></legend>
                        <ZipSearchBox
                            columns={searchableColumns}
                            onSearch={(searchObject) => {
                                // create search string from search object
                                const searchString = Object.keys(searchObject).reduce((total, current) => {
                                    total = `${total}&${current}=${searchObject[current]}`;
                                    return total;
                                }, '');
                                this.setState({
                                    searchString
                                });
                            }}
                        />
                    </fieldset>
                </div>
                <div className="col-sm-12 search-box-container">
                    <fieldset>
                        <legend><h6>&nbsp;Update&nbsp;</h6></legend>
                        <ZipUpdateBox
                            columns={bulkUpdateColumns}
                        />
                    </fieldset>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <ZipDataTable className='table'
                            ref={this.zipDataTableRef}
                            searchString={searchString}
                            onDataChange={(newDataArray) => this.dataArray = newDataArray}
                            resetEditSwitch={resetEditSwitch}
                        />
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={()=>{
                        this.zipDataTableRef.current.addRow();
                    }}
                    onSaveClick={()=>{
                        this.saveAllChanges();
                    }}
                    disabled={!editable}
                />
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('ZIP_REP')
})

export default connect(mapStateToProps)(ZipReportComponent);