import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
// import 'bootstrap/dist/css/bootstrap.min.css';
import fetchIntercept from 'fetch-intercept';
import axios from 'axios';
import './fonts/Montserrat-Regular.ttf';
import store from './redux/store';

const unregister = fetchIntercept.register({
    request: function (url, config = {}) {
        const { userDetails } = store.getState();
        const token = userDetails.auth ? userDetails.auth.accessToken : '';
        const auth = {
            Authorization: `Bearer ${token}`
        }
        if(config.headers) {
            config.headers = {
                ...config.headers, ...auth
            }
        } else {
            config.headers = auth
        }
        return [url, config];
    },
 
    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },
 
    response: function (response) {
        // Modify the reponse object
        return response;
    },
 
    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});

// unregister()

axios.interceptors.request.use(config=>{
    const { userDetails } = store.getState();
    const token = userDetails.auth ? userDetails.auth.accessToken : '';
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
}, error=>{
    Promise.reject(error);
})

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
