import React from 'react';
import FileCard from './FileCard';

/**
 * This component renders all the files in a loop
 */
export default class ShowFiles extends React.Component {
    render() {
        const { files } = this.props;
        return(
            <div className="container-fluid">
                {
                    files.map((file, index)=>
                        <div key={index} className="row">
                            <FileCard {...this.props} file={file} />
                        </div>
                    )
                }
            </div>
        )
    }
}