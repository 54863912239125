import React, { Fragment } from 'react';

export default class Button extends React.Component {
    render() {

        const { onClick, icon, label, dimension, disabled } = this.props;

        return (
            <div className="btn-wrap">
                <button className="button" type="button" onClick={()=>onClick()} disabled={disabled}>
                    {icon &&
                        <Fragment>
                            <img src={icon} width={dimension} height={dimension} />&nbsp;
                        </Fragment>
                    }
                    {label}
                </button>
            </div>
        )
    }
}

Button.defaultProps = {
    onClick: ()=>{},
    icon: '',
    label: '',
    dimension: 0,
    disabled: false
}