import React from 'react';
import Save from '../../../images/icon-save.png';

export default class AddAndSaveButtons extends React.Component {
    render() {
        const { onAddClick, onSaveClick, disabled } = this.props;
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="btn-wrap">
                        <button disabled={disabled} className="button add-user-button" data-toggle="modal" data-target="#addUserModal"
                            onClick={() => {
                                onAddClick();
                            }}
                        >
                            Add
                            </button>
                        <button disabled={disabled} className="button" type="button" onClick={(() => {
                            onSaveClick();
                        })}><img src={Save} alt="save" width="20" height="20" />&nbsp;&nbsp;Save
                            </button>
                    </div>
                </div>
            </div>
        )
    }
}

AddAndSaveButtons.defaultProps = {
    onAddClick: ()=>{},
    onSaveClick: ()=>{},
    disabled: false
}