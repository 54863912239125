import React from 'react';
import './Popup.css';

export default class Popup extends React.Component {

    render() {
        const { onYes, onNo, message, onOk, showOk } = this.props;
        return(
            <div className="popup-container">
                <div>
                    <h5 className="popup-header">{message || "Are you sure you want to delete the entry?"}</h5>
                    {
                        showOk ?
                        <button className="btn btn-success" onClick={()=>{
                            onOk();
                            this.props.finally();
                        }}>Ok</button>
                        :
                        <React.Fragment>
                            <button className="btn btn-danger" onClick={()=>{
                                onYes();
                                this.props.finally();
                            }}>Yes</button>
                            <button className="btn btn-warning" onClick={()=>{
                                onNo();
                                this.props.finally();
                            }}>No</button>
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }
}

Popup.defaultProps = {
    showOk: false,
    finally: () => {},
    onYes: () => {},
    onNo: () => {}
}