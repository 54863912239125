import React from 'react';

export default class DashboardTable extends React.Component {
    render() {
        const { data, columns } = this.props;
        return(
            <table className='kpiTable' >
                <tbody>
                    {
                        data.map((row, index)=>{
                            return(
                                <tr key={`kpitr-${index}`}>
                                    {
                                        columns.map((column, index)=>{
                                            const cell = row[column];
                                            return(
                                                <td key={`kpitd-${index}`} style={cell.style || {}}>
                                                    {cell.value}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }
}

DashboardTable.defaultProps = {
    data: [],
    columns: []
}