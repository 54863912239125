import React from 'react';
import Button from './Button';
import Search from '../../../images/icon-search.png';

export default class SearchButton extends React.Component {

    render() {
        const { onClick } = this.props;
        return (
            <div className="row zip-update-button-container">
                <Button icon={Search} dimension={20} label="Search" onClick={()=>onClick()} />
            </div>
        )
    }
}

SearchButton.defaultProps = {
    onClick: ()=>{}
}