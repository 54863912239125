import React from 'react';
import Distributors from '../Distributors';
import Manufacturers from '../Manufacturers';
import TableHeaders from '../ZipReportComponent/TableHeaders';
import SpecialSelect from './SpecialSelect';
import Search from '../../../images/icon-search.png';
import './UploadVerification.css';
import TableBody from '../ZipReportComponent/TableBody';
import TableFooter from '../ZipReportComponent/TableFooter';
import axios from 'axios';
import Constants from '../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';

const columns = [
    {
        label: 'Distributor',
        key: 'distributor',
        nosort: true
    },
    {
        label: 'Manufacturer',
        key: 'mfg',
        nosort: true
    },
    {
        label: 'Year, Month',
        key: 'date',
        nosort: true
    },
    {
        label: 'Total',
        key: 'totalSoldAmnt',
        nosort: true
    }
]

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(month => {
    return (
        {
            label: month,
            value: month
        }
    )
})

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2018 + 1 }, (v, k) => {
    const year = 2018 + k;
    return {
        label: year,
        value: year
    }
})

class UploadVerification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPages: 0,
            currentPage: 1
        }
        this.manufacturer = '';
        this.distributor = '';
        this.year = '';
        this.month = '';
    }

    componentDidMount() {
        this.search();
    }

    /**
     * Function to perform search operation
     */
    search() {
        const { manufacturer, distributor, month, year } = this;
        const { dispatch } = this.props;
        let url = Constants.uploadVerification;
        const all = 'all';
        url = url.replace('{distributor}', distributor || all);
        url = url.replace('{mfg}', manufacturer || all);
        url = url.replace('{year}', year || all);
        url = url.replace('{month}', month || all);
        url = `${Constants.baseURL}${url}`;
        dispatch(showLoader(true));
        axios.get(url)
        .then(response=>{
            const totalPages = response.data.length > 0 ? 1 : 0;
            this.setState({
                data: response.data,
                totalPages
            })
            dispatch(showLoader(false));
        }).catch(error=>{
            console.log(error);
            dispatch(showLoader(false));
        })
    }

    /**
     * Function to change page
     * @param {integer} newPage - new page number
     */
    changePage(newPage) {
        console.log(newPage);
    }

    render() {

        const { data, totalPages, currentPage } = this.state;

        return (
            <div className="tab-pane show active" id="nav-verify" role="tabpanel" aria-labelledby="nav-verify-tab">
                <div className="container-fluid">
                    <div className="row upload-verify-pannel">
                        <div className="col-sm-3">
                            <Distributors
                                onChange={(event)=>{
                                    this.distributor = event.target.value;
                                }}
                            />
                        </div>
                        <div className="col-sm-3">
                            <Manufacturers
                                onChange={(event)=>{
                                    this.manufacturer = event.target.value;
                                }}
                            />
                        </div>
                        <div className="col-sm-3">
                            <SpecialSelect
                                label='Choose year'
                                entries={years}
                                onChange={(value)=>{
                                    this.year = value;
                                }}
                            />
                        </div>
                        <div className="col-sm-3">
                            <SpecialSelect
                                label='Choose month'
                                entries={months}
                                onChange={(value)=>{
                                    this.month = value;
                                }}
                            />
                        </div>
                    </div>
                    <div className="row upload-verify-pannel">
                        <div className="col-sm-12">
                            <div className="btn-wrap">
                                <button className="button" type="button" onClick={(() => {
                                    this.search();
                                })}><img src={Search} alt="save" width="20" height="20" />&nbsp;&nbsp;Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="listing-grid table-responsive table-container">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                columns={columns}
                                            />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody
                                            readOnlyFields={[0,1,2,3]}
                                            columns={columns}
                                            data={data}
                                            disableActions={true}
                                        />
                                    </tbody>
                                    <tfoot>
                                        <TableFooter
                                            totalPages={totalPages}
                                            page={currentPage}
                                            changePage={(newPage)=>{
                                                this.changePage(newPage);
                                            }}
                                        />
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(UploadVerification);