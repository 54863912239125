import React from 'react';
import Constants, { roles } from '../../../util/Constants';
import DropDown from '../../MasterComponent/DistRepMapper/DropDown/DropDown';
import SearchIcon from '../../../../images/icon-search.png';
import Select from './Select';
import './Filters.css';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default class Filters extends React.Component {

    constructor(props) {
        super(props);
        const today = new Date();
        this.state = {
            queryParams: {
                year: today.getFullYear(),
                month: months[today.getMonth()]
            },
            salesRegionDetails: [],
            representatives: [],
            regions: [],
            territories: [],
            managers: [],
            isLeadership: false
        }
    }

    componentDidMount() {
        this.fetchSalesRegionDetails();
    }

    clearFilters() {
        const { salesRegionDetails } = this.state;
        const newQueryParams = this.initializeQueryParams(salesRegionDetails);
        this.setState({
            queryParams: newQueryParams
        })
    }

    initializeQueryParams(salesRegionDetails) {
        const { queryParams } = this.state;
        const { userDetails } = this.props;
        queryParams.role = userDetails.role;
        const today = new Date();
        queryParams.year = today.getFullYear();
        queryParams.month = months[today.getMonth()];
        const currentUserData = salesRegionDetails.find(row => row.rep === userDetails.rep)
        const filteredData = salesRegionDetails.filter(row => row.manager === userDetails.rep)
        switch (userDetails.role) {
            case roles.SALES_REP:
                queryParams.rep = userDetails.rep;
                queryParams.region = currentUserData.region;
                queryParams.territory = currentUserData.territory;
                this.setState({
                    representatives: [userDetails.rep],    // fixed
                    regions: [currentUserData.region],  // fixed
                    territories: [currentUserData.territory],    // fixed
                    queryParams,
                    isLeadership: false
                })
                break;
            case roles.SALES_MANAGER:
                queryParams.rep = 'ALL';
                queryParams.region = currentUserData.region;
                queryParams.territory = 'ALL';
                this.setState({
                    representatives: ['ALL'].concat(filteredData.map(row => row.rep)),
                    regions: [currentUserData.region],  // fixed
                    territories: ['ALL'].concat(filteredData.map(row => row.territory)),
                    queryParams,
                    isLeadership: false
                })
                break;
            case roles.KEY_DIR:
                queryParams.rep = 'ALL';
                queryParams.region = currentUserData.region;
                queryParams.territory = 'ALL';
                this.setState({
                    representatives: ['ALL'].concat(filteredData.map(row => row.rep)),
                    regions: [currentUserData.region],  // fixed
                    territories: ['ALL'].concat(filteredData.map(row => row.territory)),
                    queryParams,
                    isLeadership: false
                })
                break;
            case roles.LEADERSHIP:
                queryParams.manager = 'ALL';
                queryParams.rep = 'ALL';
                queryParams.region = 'ALL';
                queryParams.territory = 'ALL';
                this.setState({
                    managers: ['ALL', ...new Set(salesRegionDetails.map(row => row.manager))],
                    representatives: ['ALL', ...new Set(salesRegionDetails.map(row => row.rep))],
                    regions: ['ALL', ...new Set(salesRegionDetails.map(row => row.region))],
                    territories: ['ALL', ...new Set(salesRegionDetails.map(row => row.territory))],    // fixed // now all territories
                    queryParams,
                    isLeadership: true
                })
                break;
			case roles.SAM:
                queryParams.rep = userDetails.rep;
                queryParams.region = 'ALL';
                queryParams.territory = 'ALL';
                this.setState({
                    representatives: [userDetails.rep],    // fixed
                    regions: ['ALL', ...new Set(salesRegionDetails.map(row => row.region))],
                    territories: ['ALL', ...new Set(salesRegionDetails.map(row => row.territory))],    // fixed // now all territories
                    queryParams,
                    isLeadership: false
                })
                break;
			case roles.KEY:
                queryParams.rep = userDetails.rep;
                queryParams.region = 'ALL';
                queryParams.territory = 'ALL';
                this.setState({
                    representatives: [userDetails.rep],    // fixed
                    regions: ['ALL', ...new Set(salesRegionDetails.map(row => row.region))],
                    territories: ['ALL', ...new Set(salesRegionDetails.map(row => row.territory))],    // fixed // now all territories
                    queryParams,
                    isLeadership: false
                })
                break;
			case roles.SENIOR_LEADERSHIP:
                queryParams.manager = 'ALL';
                queryParams.rep = userDetails.rep;
                queryParams.region = 'ALL';
                queryParams.territory = 'ALL';
                this.setState({
                    managers: ['ALL', ...new Set(salesRegionDetails.map(row => row.manager))],
                    representatives: ['ALL', ...new Set(salesRegionDetails.map(row => row.rep))],
                    regions: ['ALL', ...new Set(salesRegionDetails.map(row => row.region))],
                    territories: ['ALL', ...new Set(salesRegionDetails.map(row => row.territory))],    // fixed // now all territories
                    queryParams,
                    isLeadership: false
                })
                break;
        }
        this.setState({
            salesRegionDetails
        })
        return queryParams;
    }

    fetchSalesRegionDetails() {
        const { onSearch } = this.props;
        const { userDetails } = this.props;
        const url = `${Constants.expensesBaseURL}${Constants.getSalesRegionDetails}?user=${userDetails.rep}`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                onSearch(this.initializeQueryParams(data))
            }).catch(error => {
                console.log(error)
            })
    }

    render() {
        const { onSearch, userDetails } = this.props;
        const {
            queryParams,
            territories,
            regions,
            representatives,
            isLeadership,
            managers,
            salesRegionDetails
        } = this.state;
        const currentYear = new Date().getFullYear();
        const managersDisabled = ![queryParams.territory, queryParams.region, queryParams.rep].every(value=>value==='ALL');
        const repDisabled = queryParams.territory !== 'ALL' || queryParams.manager === 'ALL';
        const territoryDisabled = queryParams.rep !== 'ALL' || queryParams.manager === 'ALL';
        const regionDisabled = ![queryParams.territory, queryParams.rep].every(value=>value === 'ALL');
        return (
            <React.Fragment>
                { isLeadership &&
                    <div className="col-sm-3 select-rep">
                        <span>Expense Groups: </span>
                        <Select
                            disabled={managersDisabled}
                            values={managers}
                            value={queryParams.manager}
                            onChange={event=>{
                                queryParams.manager = event.target.value;
                                if(queryParams.manager === 'ALL') {
                                    this.initializeQueryParams(salesRegionDetails);
                                } else {
                                    const filteredData = salesRegionDetails.filter(row=>row.manager === queryParams.manager);
                                    this.setState({
                                        representatives: ['ALL', ...new Set(filteredData.map(row=>row.rep))],
                                        regions: ['ALL', ...new Set(filteredData.map(row=>row.region))],
                                        territories: ['ALL', ...new Set(filteredData.map(row=>row.territory))]
                                    })
                                }
                            }}
                        />
                    </div>
                }
                <div className={`col-sm-${isLeadership ? '3' : '0'} select-rep`}>
                    {/* <DropDown
                        label='Name'
                        url={`${Constants.expensesBaseURL}${Constants.getExpensesMasterReps}`}
                        onChange={event=>{
                            queryParams.rep = event.target.value;
                            this.setState({
                                queryParams
                            })
                        }}
                    /> */}
                    <span>Rep: </span>
                    <Select
                        disabled={repDisabled}
                        values={representatives}
                        onChange={event => {
                            queryParams.rep = event.target.value;
                            this.setState({
                                queryParams
                            })
                        }}
                        value={queryParams.rep}
                    />
                </div>
				<div className="col-sm-1">
                    <select
                        className="select special-select"
                        onChange={event => {
                            queryParams.year = event.target.value;
                            this.setState({
                                queryParams
                            })
                        }}
                        value={queryParams.year}
                    >
                        {
                            Array.from({ length: 3 }, (v, k) => <option key={k} value={currentYear - k}>{currentYear - k}</option>)
                        }
                    </select>
                </div>
				<div className="col-sm-1">
                    <select
                        className="select special-select"
                        onChange={event => {
                            queryParams.month = event.target.value;
                            this.setState({
                                queryParams
                            });
                        }}
                        value={queryParams.month}
                    >
                        {
                            months.map(month => <option key={month} value={month}>{month}</option>)
                        }
                    </select>
                </div>
                <div className="col-sm-2 filter-button-container">
                    <button className="button" 
                        onClick={_ => onSearch(queryParams)}
                    >
                        <img src={SearchIcon} width="15" height="15" />
                    </button>
                    <button className="button" 
                        onClick={_ => this.clearFilters()}
                        style={{ marginLeft: '5px' }}
                    >
                        clear
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

Filters.defaultProps = {
    onSearch: () => { },
    userDetails: {}
}