import React from 'react';
import './Flags.css';
import HorizontalBarChart from './HorizontalBarChart/HorizontalBarChart';

export default class UnderUtilized extends React.Component {
    render() {
        const { data, maxBars } = this.props;
        return(
            <table className="flags-table">
                <thead>
                    <tr>
                        <th colSpan={3}>UNDER UTILIZED</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <HorizontalBarChart
                            maxValue={Math.max(...data.map(row=>row.expense))}
                            data={data.slice(0, maxBars)}
                            className="under-utilized"
                        />
                    </tr>

                </tbody>
            </table>
        )
    }
}

UnderUtilized.defaultProps = {
    data: [],
    maxBars: 5
}