import React from "react";

export default class RoleSelect extends React.Component {
  componentDidMount() {
    this.abortController = new AbortController();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const { onChange, disabled, nolabel, value, roles } = this.props;
    return roles && roles.length > 0 ? (
      <select
        className="select special-select"
        disabled={disabled}
        onChange={(event) => {
          onChange(event, roles[event.target.selectedIndex]);
        }}
        value={value}
      >
        {!nolabel && <option value="">Roles</option>}
        {roles.map((role, index) => {
          return <option key={index}>{role.roleName}</option>;
        })}
      </select>
    ) : (
      <></>
    );
  }
}

RoleSelect.defaultProps = {
  onChange: () => {},
  disabled: false,
  nolabel: false,
  value: undefined,
  onFetchComplete: () => {},
};
