// This method constructs the action used to show and hide loading component
export const showLoader = showLoader => ({
    type: 'TOGGLE_LOADER',
    showLoader
})

// This method constructs the action used to set user details when a user login
export const setUserDetails = userDetails => ({
    type: 'SET_USER_DETAILS',
    userDetails
})

export const setPrivileges = privileges => ({
    type: 'SET_PRIVILEGES',
    privileges
})