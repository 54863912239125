import React from "react";
import UserSelect from "../../../UserSelect";
import "./UserPrivileges.css";
import Table1 from "./Table1";
import RoleSelect from "../../../RoleSelect";
import { constants as Constants } from "../../../../util/Constants";
import SearchIcon from "../../../../../images/icon-search.png";
import SaveIcon from "../../../../../images/icon-save.png";
import Button from "../../../ZipReportComponent/Button";
import * as Utils from "../Utils";
import { connect } from "react-redux";
import ReportsTable from "../Reports/ReportsTable";

class UserPrivileges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      privilegeData: [],
      reportsData: {},
      roleName: "",
      roleId: "",
      allUserData: [],
    };
  }

  componentDidMount() {
    this.abortController = new AbortController();
    this.postData = [];
    this.reportsPostData = [];
    this.previousPostData = [];
    this.previousReportsPostData = [];
  }

  fetchPrivilegeData(userName) {
    const uname = userName;
    if (!uname) {
      return;
    }
    const url = `${
      Constants.baseURL
    }${Constants.getPrivilegesByUserName.replace("{username}", uname)}`;
    this.props.showLoader(true);
    fetch(url, {
      signal: this.abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        const privilegeData = data.filter((privilege) => {
          const condition =
            privilege.screenName !== Constants.reportsScreenName;
          if (condition === false) {
            this.setState({
              reportsData: privilege,
            });
          }
          return condition;
        });
        this.setState({
          privilegeData,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.showLoader(false);
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  mapTableDataToRequest() {
    const { userName } = this.state;
    const { userDetails, allPrivileges } = this.props;
    const username = userName || userDetails.userName;
    const user = this.state.allUserData.find(
      (user) => user.userName === username
    );
    const requestBody = [];
    this.postData.forEach((privilege) => {
      const readPrivilege = Utils.findPrivilege(
        privilege.screenName + "_Read",
        allPrivileges
      );
      const writePrivilege = Utils.findPrivilege(
        privilege.screenName + "_Write",
        allPrivileges
      );
      if (readPrivilege) {
        requestBody.push({
          privilegeId: readPrivilege.privilegeId,
          userId: user.userId,
          isAssign: privilege.read,
        });
      }
      if (writePrivilege) {
        requestBody.push({
          privilegeId: writePrivilege.privilegeId,
          userId: user.userId,
          isAssign: privilege.write,
        });
      }
    });
    this.reportsPostData.forEach((privilege) => {
      const priv = Utils.findPrivilege(privilege.privilege, allPrivileges);
      if (priv) {
        requestBody.push({
          privilegeId: priv.privilegeId,
          userId: user.userId,
          isAssign: privilege.read,
        });
      }
    });
    return requestBody;
  }

  savePrivileges() {
    if (this.postData.length !== 0 || this.reportsPostData.length !== 0) {
      const request = JSON.stringify(this.mapTableDataToRequest());
      const url = `${Constants.baseURL}${Constants.setPrivilegeToUser}`;
      this.props.showLoader(true);
      fetch(url, {
        method: "POST",
        body: request,
        headers: {
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.props.showLoader(false);
        });
    }
  }

  saveAllChanges() {
    const { roleName, roleId, userName } = this.state;
    // if(this.postData.length !== 0 || this.reportsPostData.length !== 0) {
    //     const request = JSON.stringify(this.mapTableDataToRequest())
    //     const url = `${Constants.baseURL}${Constants.setPrivilegeToUser}`;
    //     this.props.showLoader(true);
    //     fetch(url, {
    //         method: 'POST',
    //         body: request,
    //         headers: {
    //             'Content-type': 'application/json'
    //         }
    //     }).then(response=>{
    //         console.log(response)
    //     }).catch(error=>{
    //         console.log(error);
    //     }).finally(()=>{
    //         this.props.showLoader(false);
    //     })
    // }

    // save role
    if (roleName) {
      const selectedUser = this.state.allUserData.find(
        (user) => user.userName === userName
      );
      const url = `${Constants.baseURL}${Constants.setRolesToUsers}`;
      const requestBody = JSON.stringify([
        {
          roleId: roleId,
          userId: selectedUser.userId,
        },
      ]);
      this.props.showLoader(true);
      console.log(requestBody);
      fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: requestBody,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.props.showLoader(false);
        });
    }
  }

  render() {
    const { editable, allPrivileges, roles } = this.props;
    // console.log(allPrivileges);
    const { userName, privilegeData, reportsData, roleName } = this.state;
    return (
      <div className="container-fluid user-privileges-container">
        <h6>User Privileges</h6>
        <div className="row search-container">
          <div className="col-sm-3">
            <UserSelect
              userNames={true}
              nolabel={true}
              onChange={(event, object) => {
                this.setState({
                  userName: event.target.value,
                  userId: object.userId,
                  roleName: object.roleName,
                });
                this.fetchPrivilegeData(object.nameId);
              }}
              value={userName || this.state.allUserData[0]?.userName}
              onFetchComplete={(data) => {
                this.setState({
                  allUserData: data,
                  userName: data[0].userName,
                });
                this.fetchPrivilegeData(data[0].nameId);
              }}
            />
          </div>
          <div className="col-sm-3">
            <RoleSelect
              nolabel
              roles={roles}
              value={roleName || this.state.allUserData[0]?.roleName}
              onChange={(event, object) => {
                this.setState({
                  roleId: object.roleId,
                  roleName: event.target.value,
                });
              }}
            />
          </div>
          <div className="col-sm-6">
            <Button
              label="Save"
              icon={SaveIcon}
              dimension={20}
              onClick={() => {
                this.saveAllChanges();
              }}
              disabled={!editable}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table1
              privilegeData={Utils.mapPrivilegeDataToTable(privilegeData)}
              onChange={(newData) => {
                this.postData = [];
                this.reportsPostData = [];
                newData.forEach((screenObject) => {
                  const oldScreenObject = this.previousPostData.find(
                    (ob) => ob.screenName === screenObject.screenName
                  );
                  if (
                    !oldScreenObject ||
                    JSON.stringify(oldScreenObject) !==
                      JSON.stringify(screenObject)
                  ) {
                    this.postData.push(screenObject);
                  }
                });
                this.previousPostData = JSON.parse(JSON.stringify(newData));
                this.savePrivileges();
              }}
              disableActionsWithoutHiding={!editable}
            />
          </div>
        </div>
        {JSON.stringify(reportsData) !== "{}" && (
          <div className="row">
            <div className="col-sm-12">
              <ReportsTable
                data={Utils.mapReportsDataToTable(reportsData, allPrivileges)}
                onChange={(newData) => {
                  this.postData = [];
                  this.reportsPostData = [];
                  newData.forEach((screenObject) => {
                    const oldScreenObject = this.previousReportsPostData.find(
                      (ob) => ob.screenName === screenObject.screenName
                    );
                    if (
                      !oldScreenObject ||
                      JSON.stringify(oldScreenObject) !==
                        JSON.stringify(screenObject)
                    ) {
                      this.reportsPostData.push(screenObject);
                    }
                  });
                  this.previousReportsPostData = JSON.parse(
                    JSON.stringify(newData)
                  );
                  this.savePrivileges();
                }}
                editable={editable && reportsData.editable}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

UserPrivileges.defaultProps = {
  userDetails: {},
  allPrivileges: [],
  showLoader: () => {},
};

const mapStateToProps = (state) => ({
  editable: state.privileges.writable.includes("MASTER"),
});

export default connect(mapStateToProps)(UserPrivileges);
