import React from "react";
import { withStyles, TextField, Switch } from "@material-ui/core";
import { toast } from "react-toastify";

// this object stores the constant strings like URLs used in the project
export const constants = {
  baseURL: process.env.REACT_APP_BASE_URL,
  getAllUsers: "/user",
  getAllUserDetails: "/user/details",
  getAllRoles: "/roles",
  setRolesToUsers: "/roles/user-roles",
  getAllGoals: "/goal",
  updateUsers: "/user",
  userLogin: "/user/signin",
  deleteUser: "/user/{userId}",
  fileUpload:
    "/salesData/uploadFile/distributor/{distributor}/mfg/{mfg}/{multiDistrSpec}",
  zipUpload: "/zipDetails/upload",
  getDistributors: "/masterData/distributor",
  createOrUpdateDistributors: "/masterData/distributor",
  deleteDistributors: "/masterData/distributor/{distributorId}",
  getManufacturers: "/masterData/manufacture",
  createOrUpdateManufacturers: "/masterData/manufacture",
  deleteManufacturers: "/masterData/manufacture/{manufactureId}",
  getMfgLookupDetails: "/mfgRepDetails/findAll",
  downloadMfgLookupData: "/mfgRepDetails/download",
  getPlantDetails: "/masterData/mfgPlantMappers",
  createOrUpdatePlantMapper: "/masterData/mfgPlantMappers",
  deletePlantMapper: "/masterData/mfgPlantMappers/{mfgPlantMapperId}",
  getRegionDetails: "/masterData/region",
  createOrUpdateRegion: "/masterData/region",
  deleteRegionMapper: "/masterData/region/{regionId}",
  getDistRepMapper: "/masterData/distributorRepMapper",
  deleteDistRepMap: "/masterData/distributorRepMapper/{distrubutorRepMapperId}",
  addDistRepMapper: "/masterData/distributorRepMapper",
  modifyDistRepMapper: "/masterData/distributorRepMapper",
  getDistRepDistributors: "/masterData/distributorRepMapper/distributors",
  getDistRepGroups: "/masterData/distributorRepMapper/groups",
  getDistRepPersons: "/masterData/distributorRepMapper/persons",
  getDistRepTeams: "/masterData/distributorRepMapper/teams",
  downloadDistRepMapperData: "/masterData/distributorRepMapper/download",
  uploadDistRepMapperData: "/masterData/distributorRepMapper/upload",
  getFamilyCodes: "/familyCode/lookup",
  postFamilyCodes: "/familyCode/lookup",
  deleteFamilyCode: "/familyCode/lookup/{id}",
  getProdFamilyMapper: "/familyCode/prodFamilyMapper",
  downloadProdFamilyData: "/familyCode/prodFamilyMapper/download",
  uploadProdFamilyData: "/familyCode/prodFamilyMapper/upload",
  postProdFamilyMapper: "/familyCode/prodFamilyMapper",
  deleteProdFamilyMapper: "/familyCode/prodFamilyMapper/{id}",
  getHQFilterDistributors: "/hqfilter/distributors",
  getHQFilterMappings: "/hqfilter",
  createHQFilterMapping: "/hqfilter",
  deleteHQFilterMapping: "/hqfilter/{id}",
  getHQFilterManufacturers: "/hqfilter/mfg",
  getDistMfgMappingDistributors: "/distMfgMapping/distributors",
  getDistMfgMappings: "/distMfgMapping",
  createDistMfgMapping: "/distMfgMapping",
  deleteDistMfgMapping: "/distMfgMapping/{id}",
  getDistMfgMappingManufacturers: "/distMfgMapping/mfg",
  uploadHistory: "/salesData/fetchHistory",
  zipUploadHistory: "/zipDetails/findAll",
  getStatesForRep: "/home/findStatesByRep",
  getIssUnderRep: "/home/findRepsUnderRsm",
  getMfgLookupForState: "/home/findMfgRepLookUp",
  createUser: "/user/signup",
  createGoal: "/goal",
  downloadGoalsData: "/goal/download",
  uploadGoalsData: "/goal/upload",
  deleteGoal: "/goal/{goalId}",
  deleteHistory: "/salesData/remove/salesData/{id}",
  deleteZipHistory: "/zipDetails/{id}",
  deleteMfgLookup: "/mfgRepDetails/{id}",
  downloadMfgTotalsReport: "/reports/mfgTotals",
  downloadMonthEndMasterReport: "/reports/monthEndMaster",
  downloadCombinedSalesReport: "/reports/combinedSalesReport",
  zipUpdate: "/zipDetails/update",
  zipDownload: "/zipDetails/download",
  updateMfgLookupData: "/mfgRepDetails/update",
  mfgLookupUploadFile: "/mfgRepDetails/upload",
  uploadVerification:
    "/salesData/verifyData/distributor/{distributor}/mfg/{mfg}/year/{year}/month/{month}",
  getDashboardData: "/dashboard/mySales",
  getSamDashboardData: "/dashboard/samSales",
  getKeyPerson: "/dashboard/person",
  downloadSamSales: "/dashboard/download/samSales",
  dashboardGetKPI: "/dashboard/kpi",
  dashboardGetLeaderboard: "/dashboard/leaderBoard",
  dashboardGetYTD: "/dashboard/ytdDetails",
  dashboardGetSamYTD: "/dashboard/samYtdDetails",
  downloadMySales: "/dashboard/download/mySales",
  downloadMonthlyTrends: "/dashboard/download/monthlyTrends",
  downloadHQFilter: "/hqfilter/download",
  uploadHQFilter: "/hqfilter/upload",
  downloadDistMfgMapping: "/distMfgMapping/download",
  uploadDistMfgMapping: "/distMfgMapping/upload",
  getHeatmapData: "/dashboard/heatMapDetails",
  getPrivilegesByUserName: "/privileges/user-privileges/{username}",
  getPrivilegesByRoleName: "/privileges/role-privileges/{roleName}",
  getAllPrivileges: "/privileges",
  setPrivilegeToUser: "/privileges/user-privileges",
  setPrivilegeToRole: "/privileges/role-privileges",
  downloadExcelFromUploadPage: "/salesData/download/{fileName}/{uId}",
  uploadProfilePicture: "/user/upload",
  getTerritoryCodeData: "/territories/territoryMapping/",
  getAllTerritories: "/territories/territory",
  postTerritoryCodeData: "/territories/territoryMapping/",
  deleteTerritoryCodeData: "/territories/territoryMapping/{id}",
  downloadTerritoryCodeData: "/territories/territoryMapping/download",
  uploadHQTerritoryCodeData: "/territories/territoryMapping/upload",
  getManagers: "/territories/findManagers",
  getSalesReps: "/territories/findReps",
  getLeadershipGraphData: "/dashboard/leadershipYoy",
  syncUploadHistory: "/salesData/sync/{id}",
  syncDashBoard: "/salesData/sync",
  getAllEndUserData: "/endUser",
  saveAllEndUserData: "/endUser",
  uploadEndUserData: "/endUser/upload",
  downloadEndUserData: "/endUser/download",
  deleteEndUserData: "/endUser/{id}",
  defaultHomeScreen: "dashboard", // this is the default home screen if no home specified in login response
  maxHistoryRows: 20,
  maxZipHistoryRows: 10,
  maxMfgLookupRows: 10,
  distRepMapperRows: 20,
  maxHomePageRows: 15,
  maxHorizontalBars: 14,
  maxSAMHorizontalBars: 16,
  maxVerticalBars: 8,
  maxSAMVerticalBars: 8,
  maxGoalRows: 10,
  maxTerritoryCodeMapperRows: 10,
  maxEndUserTableRows: 10,
  reportsScreenName: "Reports",
  getLastSyncTime: "/salesData/lastSyncTime",
  // Expenses section
  expensesBaseURL: process.env.REACT_APP_EXPENSES_BASE_URL,
  getHeadwiseExpenses: "/dashboard/headWiseExpense",
  getExpensesYOY: "/dashboard/expenseYOY",
  getExpensesMasterReps: "/master/reps",
  getExpensesMasterTerritories: "/master/territories",
  getExpensesMasterUsers: "/user/fetchNonRegionUsers",
  getExpensesMasterRegions: "/master/regions",
  getExpensesProfitAndLossData: "/data/profitAndLoss",
  getExpensesProfitAndLossReport: "/data/report/profitAndLoss",
  getBudgets: "/budgets/",
  postBudgets: "/budgets/",
  uploadBudgets: "/budgets/upload",
  downloadBudgets: "/budgets/download",
  getSalesRegionDetails: "/master/SalesRegionDetails",
  syncExpenseData: "/data/sync",
  uploadExpenseDate: "/data/upload",
  maxExpensesBarGraphBars: 8,
  maxExpensesLineGraphDataPoints: 8,
};

// this object stores the options applied to toasts in the project
export const toastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

// this method is used to construct the body of error toasts
export const constructErrorBody = (statusCode, error, message) => {
  return (
    <div>
      <div>
        <b>Error: {error || "An error has occured"}</b>
      </div>
      {statusCode && <div>Status code: {statusCode}</div>}
      {message && <div>Message: {message}</div>}
    </div>
  );
};

/**
 * This function is to fetch the list of all distributors
 */
export const getDistributors = async () => {
  return new Promise((resolve, reject) => {
    const url = `${constants.baseURL}${constants.getDistributors}`;
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        resolve(jsonData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

/**
 * These are styles for custom text fields created with material-ui
 */
export const CustomTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid white",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "2px solid wheat",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "3px solid white",
    },
    "& input": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      padding: "0px",
    },
    "& .Mui-disabled.MuiInput-underline:before": {
      borderBottom: "1px solid gray",
    },
  },
})(TextField);

/**
 * These are styles for custom switch buttons created with material-ui
 */
export const WheatSwitch = withStyles({
  switchBase: {
    color: "wheat",
    opacity: 1,
    "&$checked": {
      color: "wheat",
      opacity: 1,
    },
    "&$checked + $track": {
      backgroundColor: "#c1ae38",
      opacity: 1,
    },
  },
  checked: {},
  track: {
    opacity: 1,
    backgroundColor: "gainsboro",
  },
  disabled: {
    "&$disabled + $track": {
      opacity: 1,
      backgroundColor: "gray",
    },
    "&$checked + $track": {
      backgroundColor: "wheat",
    },
  },
})(Switch);

/**
 * Function to download file
 * @param {string} url - download url
 * @param {string} name - file name
 * @param {string} extension - extension of file ( type of file )
 * @param {string} success_message - the success message that will be displayed on successful download
 * @param {string} error_message - the error message that will be displayed if download fails
 */
export const downloadFile = (
  url,
  name = "report",
  extension = "json",
  success_message = "Success: Download successfully",
  error_message = "Error: Download failed"
) => {
  let fileName = "";
  return fetch(url)
    .then((response) => {
      fileName = response.headers.get("File-Name") || `${name}.${extension}`;
      return new Response(response.body);
    })
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((objectUrl) => {
      let downloadLink = window.document.createElement("a");
      downloadLink.href = objectUrl;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      toast.success(success_message, toastOptions);
    })
    .catch((error) => {
      console.log(error);
      toast.error(error_message, toastOptions);
    });
};

/**
 * Function to convert number to number with comas
 * @param {integer} number - the number that will be converted to coma separated string
 * @param {string} prefix - this prefix will be added to the resultant coma separated string
 */
export const numberWithComas = (number, prefix = "$") => {
  if (isNaN(parseInt(number))) {
    return number;
  }
  return prefix + String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// mapping from internal screen name to privilege name --- do not change internal screen name
// change values to change privilege
// read privilege granted if <value>_Read is present in user's privilege list
// write privilege granted if <value>_Write is present in user's privilege list
// reports screen read privilege granted is user's privilege list has at least one report access
export const screens = {
  HOME: "Home",
  DASHBOARD: "Dashboard",
  SAM_DASHBOARD: "SamDashboard",
  USERS: "Master",
  REPORTS: "Reports",
  MASTER: "Master",
  ZIP_REP: "ZipRep",
  UPLOAD_DATA: "UploadData",
  LOOKUP: "Master",
  HEAT_MAP: "HeatMap",
  UPLOAD_VERIFY: "UploadData",
  EXPENSE: "Expense",
  BUDGET: "Budget",
  //*** REPORT PRIVILEGES ***//
  // can download these reports if these values are present in user's privilege list
  MONTH_END_MASTER: "Reports_MonthEndMaster",
  MFG_TOTALS: "Reports_MfgTotals",
  MONTH_END_MASTER_FOR_FLUKE: "Reports_MonthEndMasterForFluke",
};

// mapping from internal role name to database role name --- do not change internal role name
export const roles = {
  SALES_MANAGER: "SalesManager", // User dropdown in Dashboard displayed if user's role is equal to this value
  LEADERSHIP: "Leadership", // User dropdown in Dashboard displayed if user's role is equal to this value
  SALES_REP: "SalesRep",
  SAM: "SAM",
  SENIOR_LEADERSHIP: "SeniorLeadership",
  ADMIN: "Admin",
  KEY: "Key", // this value is compared against user role to find if user is having 'KEY' role
  KEY_DIR: "KeyDirector", // this value is compared against user role to find if user is having 'KEY' role
};

export default constants;
