import React from 'react';
import Goal from './Goal/Goal';
import './GoalSettings.css';
import Constants, { toastOptions, downloadFile } from '../../../util/Constants';
import { toast } from 'react-toastify';
import GoalTable from './GoalTable/GoalTable';
import UploadIcon from '../../../../images/icon-upload.ico';
import DownloadIcon from '../../../../images/icon-save.png';
import Button from '../../ZipReportComponent/Button';
import AddAndSaveButtons from '../AddAndSaveButtons';
import SimpleUploadComponent from '../../SimpleUploadComponent/SimpleUploadComponent';
import { connect } from 'react-redux';
import { showLoader } from '../../../../redux/actions';
import Territories from '../../Territories';
import Popup from '../../Popup/Popup';
import Manufacturers from '../../Manufacturers';
import Distributors from '../../Distributors';

const dummyGoal = {
    manufacturers: "",
    distributors: "",
    repName: "",
    targetRevenue: 0,
    year: ""
}


const maxRows = Constants.maxGoalRows;

class GoalSettings extends React.Component {

    static id = 0;

    state = {
        manufacturers: '',
        distributors: '',
        goals: [],
        repNames: [],
        totalPages: 0,
        currentPage: 1,
        showPopup: false,
        territory: '',
        resetEditSwitch: false
    }

    constructor(props) {
        super(props);
        this.updatedData = [];
        this.searchRef = React.createRef();
        this.idForDelete = '';
    }

    componentDidMount() {
        this.fetchTerritory();
        this.territory = '';
        this.distributor = '';
        this.manufacturer = '';
        this.fetchData();
    }

    fetchTerritory() {
        const url = `${Constants.baseURL}${Constants.getAllTerritories}`;
        fetch(url)
            .then(response=>response.json())
            .then(data=>{
                const territory = data.map(territory=>territory.territoryCode);
                this.setState({
                    territoryData:  territory
                })
            })
            .catch(error=>console.log(error));
    }

    fetchData(index=0) {
        const url = `${Constants.baseURL}${Constants.getAllGoals}?limit=${maxRows}&index=${index}&territoryCode=${this.territory}&mfg=${this.manufacturer}&distributor=${this.distributor}`;
        this.updatedData = [];
        fetch(url)
        .then(response=>response.json())
        .then(data=>{
            const { response, totalCount, currentCount } = data;
            this.setState({
                totalPages: Math.ceil(totalCount/maxRows),
                currentPage: index+1,
                goals: response
            })
        }).catch(error=>{
            console.log(error);
        })
    }

    addGoal() {
        const { goals } = this.state;
        const myDummyGoal = {...dummyGoal, goalId: `temp${GoalSettings.id++}` }
        goals.push(myDummyGoal);
        this.setState({
            goals
        })
    }

    saveAllChanges() {
        if(JSON.stringify(this.updatedData) === '[]') {
            return;
        }
        this.updatedData = this.updatedData.map(goal=>{
            if(String(goal.goalId).startsWith('temp')) {
                delete goal.goalId;
            }
            return goal;
        })
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.createGoal}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(this.updatedData),
            headers: {
                'Content-type': 'application/json'
            }
        }).then(response => {
            toast.success("Success: Goals added", toastOptions);
            this.clearSearchResults();
            this.setState({
                resetEditSwitch: !this.state.resetEditSwitch
            })
        }).catch(error=>{
            toast.error("Error: Goal addition failed");
            console.log(error);
        }).finally(()=>{
            dispatch(showLoader(false));
        })
    }

    clearSearchResults() {
        this.territory = '';
        this.searchRef.current.reset();
        this.fetchData()
    }

    deleteRow() {
        const id = this.idForDelete;
        if(!id) {
            return;
        }
        const deleteData = () => {
            const { goals } = this.state;
            const newGoals = goals.filter(goal=>goal.goalId !== id);
            this.updatedData = this.updatedData.filter(goal=>goal.goalId !== id);
            this.setState({
                goals: newGoals
            })
            this.idForDelete = '';
            toast.success("Success: Delete successful", toastOptions);
        }
        if(String(id).startsWith('temp')) {
            deleteData();
        } else {
            const { dispatch } = this.props;
            const url = `${Constants.baseURL}${Constants.deleteGoal.replace('{goalId}', id)}`;
            fetch(url, {
                method: 'DELETE'
            }).then(response=>{
                console.log(response);
                if(parseInt(response.status) === 204) {
                    deleteData();
                } else {
                    throw new Error();
                }
            }).catch(error=>{
                console.log(error);
                toast.error("Error: Delete failed", toastOptions);
            })
        }
    }

    render() {
        const { goals, repNames, manufacturer, distributor, totalPages, currentPage, showPopup, resetEditSwitch } = this.state;
        const { dispatch, editable } = this.props;
        return (
            <div className="container-fluid goal-settings-container">
                { showPopup &&
                    <Popup
                        onYes={()=>{
                            this.deleteRow();
                            this.setState({
                                showPopup: false
                            })
                        }}
                        onNo={()=>{
                            this.setState({
                                showPopup: false
                            })
                            this.idForDelete = '';
                        }}
                    />
                }
                <div className="row">
                    <div className="col-sm-2">
                        <form ref={this.searchRef}>
                        <Territories
                            onChange={event => {
                                this.territory = event.target.value;
                                this.setState({
                                    territory: event.target.value
                                    })
                                }}
                            value={this.territory}
                        />
                        </form>
                    </div>
                    <div className="col-sm-2">
                    <Manufacturers
                                width='100%'
                                fontSize = '14px'
                                marginBottom = '10px'
                                onChange={event => {
                                   this.manufacturer = event.target.value;
                                    this.setState({
                                        manufacturer: event.target.value
                                    })
                                }}
                                onClose={_ => {
                                    //write a fuction in this componet
                                }}
                                //multiselect
                                value={this.manufacturer}
                            />
                            </div>
                    <div className="col-sm-2">
                        <Distributors
                            onChange={event => {
                                this.distributor = event.target.value;
                                this.setState({
                                    distributor: event.target.value
                                })
                                //fetchData(this.manufacturer, this.distributor)
                            }}
                            value={distributor}
                            onClose={_ => {
                            }}
                            //multiselect
                            isStrategic= "true"
                        />
                    </div>
                    <div className="col-sm-3 goal-search-button">
                        <Button
                            label="SEARCH"
                            onClick={()=>{
                                this.fetchData();
                            }}
                        />
                        <Button
                            label="CLEAR"
                            onClick={()=>{
                                this.clearSearchResults();
                            }}
                        />
                    </div>
                    <div className="col-sm-3">
                        <div className="row zip-update-button-container">
                            <SimpleUploadComponent
                                uploadUrl={`${Constants.baseURL}${Constants.uploadGoalsData}`}
                                image={{
                                    src: UploadIcon,
                                    width: 20,
                                    height: 20
                                }}
                                label="UPLOAD"
                                disabled={!editable}
                            />
                            <Button
                                onClick={() => {
                                    dispatch(showLoader(true));
                                    downloadFile(`${Constants.baseURL}${Constants.downloadGoalsData}`, undefined, 'xlsx')
                                        .finally(()=>{
                                            dispatch(showLoader(false));
                                        })
                                }}
                                icon={DownloadIcon}
                                label='Download'
                                dimension='20'
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <GoalTable
                            goals={goals}
                            repNames={repNames}
                            onDataChange={(newData)=>{
                                this.updatedData = newData;
                                newData.forEach(goal=>{
                                    const changeIndex = goals.indexOf(goal.goalId);
                                    goals[changeIndex] = goal;
                                })
                                this.setState({
                                    goals
                                })
                            }}
                            deleteRow={(object)=>{
                                this.idForDelete = object.goalId;
                                this.setState({
                                    showPopup: true
                                })
                            }}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            changePage={(index)=>{
                                this.fetchData(index);
                            }}
                            editable={editable}
                            resetEditSwitch={resetEditSwitch}
                        />
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={()=>{
                        this.addGoal();
                    }}
                    onSaveClick={()=>{
                        this.saveAllChanges();
                    }}
                    disabled={!editable}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('MASTER')
})

export default connect(mapStateToProps)(GoalSettings);