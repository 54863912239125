import React from 'react';
import Search from '../../../images/icon-search.png';
import Clear from '../../../images/icon-clear.png';
import InputFields from './InputFields';
import Button from './Button';

export default class ZipSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.data = {};
        this.inputFieldsRef = React.createRef();
    }
    render() {
        const { onSearch,  columns } = this.props;
        return (
            <div className="container-fluid zip-search-box">
                
                <InputFields
                    ref={this.inputFieldsRef}
                    columns={columns}
                    onChange={(data)=>{
                        this.data = data;
                    }}
                />

                <div className="row zip-update-button-container">
                    <Button
                        onClick={()=>{
                            this.inputFieldsRef.current.clearForm();
                        }}
                        label="Clear"
                        icon={Clear}
                        dimension='20'
                    />
                    <Button
                        onClick={()=>{
                            onSearch(this.data);
                        }}
                        label='Search'
                        icon={Search}
                        dimension='20'
                    />
                </div>
            </div>
        )
    }
}

ZipSearchBox.defaultProps = {
    onSearch: ()=>{},
    columns: []
}