import React from 'react';
import './HeadwiseExpense.css';
import * as d3 from 'd3';
import { numberWithComas } from '../../../util/Constants';
import ArrowIcon from '../../../../images/icon-right-arrow.png';

export default class HeadwiseExpense extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        // this.drawChart();
    }

    drawChart() {
        const chart = this.chartRef.current;
        
        // clean the board
        d3.select(chart).selectAll('svg').remove();
        
        // define width and height
        const width=100, height=100;

        // append svg
        const svg = 
            d3.select(chart)
                .append('svg')
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr('viewBox', `0 0 ${width} ${height}`);
    }

    setupTooltip(expense, budget, index) {
            const { mouseover, mousemove, mouseleave} = this.props;
            const bar = document.getElementById(index);
            d3.select(bar)
                .on("mouseover", mouseover)
                .on("mousemove", _ => mousemove(`
                    <div>
                        <svg width='10px' height='10px'>
                            <rect width='10px' height='10px' fill='goldenrod'></rect>
                        </svg>
                        Expense : ${numberWithComas(expense)}
                    </div>
                    <div>
                        <svg width='10px' height='10px'>
                            <rect width='10px' height='10px' fill='olivedrab'></rect>
                        </svg>
                        Budget : ${numberWithComas(budget)}
                    </div>
                `))
                .on("mouseleave", mouseleave)
        }

    render() {
        const { data, page, onLeftClick, onRightClick, leftDisabled, rightDisabled } = this.props;
        return(
            // <div ref={this.chartRef} style={{position: 'relative', width: '100%'}}></div>
            <table className="headwise-expense-table">
                <thead>
                    <tr>
                        <th colSpan={2}>T&amp;E EXPENSE</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, index)=>{
                            let expensePercent = Math.floor(row.expense*100/row.budget);
                            let budgetPercent = 100-expensePercent;
                            if(expensePercent > 100) {
                                budgetPercent = 0;
                                expensePercent = 100;
                            }
                            return(
                                <tr key={index}>
                                    <td className="label">
                                        {row.name}
                                    </td>
                                    <td className="bars" onMouseOver={() => this.setupTooltip(row.expense, row.budget, index)} id={index} >
                                        <div className="expense-bar" style={{width: `${expensePercent}%`}}>
                                            {numberWithComas(row.expense)}
                                        </div>
                                        <div className="budget-bar" style={{width: `${budgetPercent}%`}}>
                                            {numberWithComas(row.budget)}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td className="legends">
                            <span className="expense-square">Expense</span>
                            <span className="budget-square">Budget Amount</span>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <img src={ArrowIcon} widht="20px" height="20px"
                                className={`rotate180${leftDisabled ? ' disabled' : ''}`}
                                onClick={()=>{
                                    onLeftClick();
                                }}
                            />
                            &nbsp;{page}&nbsp;
                            <img src={ArrowIcon} widht="20px" height="20px"
                                className={rightDisabled ? 'disabled' : ''}
                                onClick={()=>{
                                    onRightClick();
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        )
    }
}

HeadwiseExpense.defaultProps = {
    page: 1,
    onLeftClick: ()=>{},
    onRightClick: ()=>{},
    leftDisabled: true,
    rightDisabled: true
}