import React from 'react';
import './FileDrop.css';
import $ from 'jquery';

/**
 * This component renders a dropbox which can accept files dropped into it
 */
export default class FileDrop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
    }

    componentDidMount() {
        // check if drag and drop is supported
        const isAdvancedUpload = function () {
            const div = document.createElement('div');
            return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
        }();

        const $form = $('.box');

        if (isAdvancedUpload) {
            $form.addClass('has-advanced-upload');
            var droppedFiles = false;

            const { addFiles } = this.props;

            // Part that handles drag and drop of files
            $form.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
                e.preventDefault();
                e.stopPropagation();
            })
                .on('dragover dragenter', function () {
                    $form.addClass('is-dragover');
                })
                .on('dragleave dragend drop', function () {
                    $form.removeClass('is-dragover');
                })
                .on('drop', function (e) {
                    droppedFiles = e.originalEvent.dataTransfer.files;
                    // send dropped files for upload
                    addFiles(Array.from(droppedFiles));
                });
        }
    }

    render() {
        return (
            <form className="box" enctype="multipart/form-data">
                <div className="box__input">
                    <input className="box__file" type="file" name="files[]" id="file" data-multiple-caption="{count} files selected" webkitdirectory directory multiple onChange={(event)=>{
                        this.props.addFiles(Array.from(event.target.files))
                    }} />
                    <label for="file"><span className="fa fa-upload box__icon" /></label>
                    <label for="file"><strong>Choose a file</strong><span className="box__dragndrop"> or drag it here</span>.</label>
                    <button className="box__button" type="submit">Upload</button>
                </div>
                <div className="box__uploading">Uploading&hellip;</div>
                <div className="box__success">Done!</div>
                <div className="box__error">Error! <span></span>.</div>
            </form>
        )
    }
}