// This is the reducer which sets user details when user login
const savedUserDetails = window.sessionStorage.getItem('userDetails');
const initialState =  savedUserDetails ? JSON.parse(savedUserDetails) : {}

const userDetails = (state=initialState, action) => {
    switch(action.type) {
        case 'SET_USER_DETAILS':
            window.sessionStorage.setItem('userDetails', JSON.stringify(action.userDetails));
            return action.userDetails;
        default:
            return state;
    }
}

export default userDetails;