import React from 'react';
import * as d3 from 'd3';
import './EventExpenseYOY.css';
import { axisBottom, axisLeft, transition } from 'd3';
import ArrowIcon from '../../../../images/icon-right-arrow.png';

export default class EventExpenseYOY extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.drawChart();
        }
    }

    drawChart() {
        const { data, mouseover, mousemove, mouseleave } = this.props;
        const chartRef = this.chartRef.current;
        //define width and height
        const width = 170*6, height = 100*6;
        //define left and right side clearance of graph
        const leftClearance = 100, rightClearance = 100;
        // define top and bottom clearance of graph
        const topClearance = 50, bottomClearance = 40;
        // clean the graph
        d3.select(chartRef).selectAll('svg').remove();
        // create svg
        const svg =
            d3.select(chartRef)
                .append('svg')
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr('viewBox', `0 0 ${width} ${height}`);
        
        // create x scale - constant values
        const allNames = data.map(row=>row.name);
        const scaleX =
            d3.scalePoint()
                .domain(allNames)
                .range([leftClearance, width-rightClearance]);   // left and right clearance. ( width-100 for right clearance)

        // create y scale - values in dolar
        const allExpenses = data.map(row=>row.expense);
        const allPreviousExpenses = data.map(row=>row.prevExpense);
        const maxValue = d3.max([...allExpenses, ...allPreviousExpenses]);
        const scaleY =
            d3.scaleLinear()
                .domain([0, maxValue])
                .range([height-bottomClearance, topClearance]);  // top and bottom clearances of y axis ( height-30 is bottom clearance)
        
        // add x axis of graph
        svg.append('g')
            .attr('transform', `translate(0, ${height-bottomClearance})`)   // this is for positioning x axis with bottom clearance
            .call(axisBottom(scaleX));

        // add y axis of graph
        const yaxis = svg.append('g')
            .attr('transform', `translate(${leftClearance}, 0)`)  // this is for positioning y axis with left clearance
            .call(axisLeft(scaleY))

        // extend y axis line from each marking
        yaxis.selectAll('line')
            .attr('x1', width-leftClearance-rightClearance) // total width of graph
            .attr('stroke', 'darkgray')
        
        // line generator for generating the expense line
        const expenseLineGenerator =
            d3.line()
            .x(d=>scaleX(d.name))
            .y(d=>scaleY(d.expense))

        // append line for expense to svg
        svg.append('path')
            .datum(data)
            .attr('d', expenseLineGenerator)
            .attr('class', 'yoy-expense-line')
        
        // append circles to all expense data points
        svg.selectAll('expense-circle')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', 5)
            .attr('cx', d=>scaleX(d.name))
            .attr('cy', d=>scaleY(d.expense))
            .attr('fill', 'white')
            .on("mouseover", mouseover)
            .on("mousemove", d=>{
                mousemove(`
                    <div>Current Expense: ${d.expense || 0}</div>
                `)
            })
            .on("mouseleave", mouseleave)

        // line generator for generating the previous expense line
        const previousExpenseLineGenerator =
            d3.line()
            .x(d=>scaleX(d.name))
            .y(d=>scaleY(d.prevExpense))

        // append line for previous expense to svg
        svg.append('path')
            .datum(data)
            .attr('d', previousExpenseLineGenerator)
            .attr('class', 'yoy-prev-expense-line')

        // append circles to all previous expense data points
        svg.selectAll('prev-expense-circle')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', 5)
            .attr('cx', d=>scaleX(d.name))
            .attr('cy', d=>scaleY(d.prevExpense))
            .attr('fill', 'white')
            .on("mouseover", mouseover)
            .on("mousemove", d=>{
                mousemove(`
                    <div>Previous Expense: ${d.prevExpense || 0}</div>
                `)
            })
            .on("mouseleave", mouseleave)
    }

    render() {
        const { page, onLeftClick, onRightClick, leftDisabled, rightDisabled } = this.props;
        return(
            <table className="yoy-table">
                <thead>
                    <tr>
                        <th>EVENT YOY</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div ref={this.chartRef}></div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <span className="series1">Current Year</span>
                            <span className="series2">Previous Year</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src={ArrowIcon} widht="20px" height="20px"
                                className={`rotate180${leftDisabled ? ' disabled' : ''}`}
                                onClick={()=>{
                                    onLeftClick();
                                }}
                            />
                            &nbsp;{page}&nbsp;
                            <img src={ArrowIcon} widht="20px" height="20px"
                                className={rightDisabled ? 'disabled' : ''}
                                onClick={()=>{
                                    onRightClick();
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        )
    }
}

EventExpenseYOY.defaultProps = {
    page: 1,
    onLeftClick: ()=>{},
    onRightClick: ()=>{},
    mouseover: ()=>{},
    mousemove: ()=>{},
    mouseleave: ()=>{},
    leftDisabled: true,
    rightDisabled: true
}