import React from 'react';
import TableHeaders from '../../../ZipReportComponent/TableHeaders';
import TableBody from '../../../ZipReportComponent/TableBody';

const columns = [
    {
        label: 'Report Name',
        nosort: true,
        key: 'privilege'
    },
    {
        label: 'Read',
        nosort: true,
        key: 'read',
        type: 'boolean'
    }
]

export default class ReportsTable extends React.Component {
    render() {
        const { data, onChange, disableActionsWithoutHiding, editable } = this.props;
        const hiddenEditRowIndexes = Array.from({length: data.length}, (v,k)=>k);
        return(
            <div className="listing-grid table-responsive table-container">
                 <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders
                                columns={columns}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody
                            columns={columns}
                            data={data}
                            disableActions={true}
                            hiddenEditRowIndexes={editable ? hiddenEditRowIndexes : []}
                            onDataChange={newData=>onChange(newData)}
                            readOnlyFields={[0]}
                        />
                    </tbody>
                </table>
            </div>
        )
    }
}

ReportsTable.defaultProps = {
    data: [],
    onChange: ()=>{},
    editable: true
}