import React from 'react';

export default class InputFields extends React.Component {

    constructor(props) {
        super(props);
        this.data = {};
        this.formRef=React.createRef();
    }

    clearForm() {
        this.formRef.current.reset();
        this.data = {};
    }

    render() {
        
        const { columns, onChange } = this.props;

        return(
            <form ref={this.formRef}>
                <div className="row">
                    {
                        columns.map((column, index)=>{
                            return(
                                <div key={index} className="col-sm-3">
                                    <input type="text" className="txt form-control" placeholder={column.label}
                                        onChange={(event)=>{
                                            if(column.searchKey) {
                                                this.data[column.searchKey] = event.target.value;
                                                onChange(this.data);
                                            }
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        )
    }
}

InputFields.defaultProps = {
    columns: [],
    onChange: ()=>{}
}