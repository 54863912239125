import React from 'react';
import DropDown from '../../DistRepMapper/DropDown/DropDown';
import Constants from '../../../../util/Constants';
import Distributors from '../../../FilterComponents/Distributors/Distributors';

export default class SearchBox  extends React.Component {

    constructor(props) {
        super(props);
        this.searchParams = {}
        this.data = {}
    }

    render() {
        const { onSearch, onFetchComplete } = this.props;
        return(
            <React.Fragment>
                <div className="col-sm-3">
                    <Distributors
                        label="Distributor"
                        onChange={distributor => {
                            this.searchParams.distributor = distributor === null ? '' : distributor.distributionName
                        }}
                        onFetchComplete={(distributors)=>{
                            this.data.distributors = distributors;
                            onFetchComplete(this.data);
                        }}
                    />
                </div>
                <div className="col-sm-3">
                    <DropDown
                        label="Manufacturer"
                        url={`${Constants.baseURL}${Constants.getDistMfgMappingManufacturers}`}
                        onChange={(event)=>{
                            this.searchParams.mfg = event.target.value;
                        }}
                        onFetchComplete={(manufacturers)=>{
                            this.data.manufacturers = manufacturers;
                            onFetchComplete(this.data);
                        }}
                    />
                </div>
                <div className="col-sm-3 brn-wrap">
                    <button
                        className="button"
                        onClick={()=>{
                            onSearch(this.searchParams);
                        }}
                    >Search</button>
                </div>
            </React.Fragment>
        )
    }
}