import React from "react";
import "./PrivilegeScreen.css";
// import Save from '../../../../images/icon-save.png';
import UserPrivileges from "./UserPrivileges/UserPrivileges";
import RolePrivileges from "./RolePrivileges/RolePrivileges";
import { connect } from "react-redux";
import Constants from "../../../util/Constants";
import { showLoader } from "../../../../redux/actions";

class PrivilegeScreen extends React.Component {
  static loaderCount = 0;

  state = {
    allPrivileges: [],
    roles: [],
  };

  fetchRoleData() {
    const url = `${Constants.baseURL}${Constants.getAllRoles}`;
    fetch(url, {
      signal: this.abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ roles: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.abortController = new AbortController();
    this.fetchRoleData();
    this.fetchAllPrivileges();
  }

  loader(state) {
    const { dispatch } = this.props;
    if (state === true) {
      PrivilegeScreen.loaderCount++;
      dispatch(showLoader(true));
    } else {
      if (PrivilegeScreen.loaderCount > 0) {
        PrivilegeScreen.loaderCount--;
      }
      if (PrivilegeScreen.loaderCount === 0) {
        dispatch(showLoader(false));
      }
    }
  }

  fetchAllPrivileges() {
    this.loader(true);
    const url = `${Constants.baseURL}${Constants.getAllPrivileges}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ allPrivileges: data });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loader(false);
      });
  }

  render() {
    const { userDetails } = this.props;
    const { allPrivileges, roles } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <UserPrivileges
              roles={roles}
              userDetails={userDetails}
              allPrivileges={allPrivileges}
              showLoader={(state) => this.loader(state)}
            />
          </div>
          <div className="col-sm-6">
            <RolePrivileges
              roles={roles}
              userDetails={userDetails}
              allPrivileges={allPrivileges}
              showLoader={(state) => this.loader(state)}
            />
          </div>
        </div>
        {/* <div className="row">
                    <div className="col-sm-12">
                        <table className="privilege-table" cellSpacing="20">
                            <thead>
                                <tr>
                                    <th>
                                        SCREENS
                                    </th>
                                    <th>
                                        REPORTS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <ul>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;Users
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;Zip Rep
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;Upload Data
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;Master
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;Report
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;MFG Lookup
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;Cost Calculation
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;report 1
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;report 2
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;report 3
                                            </li>
                                            <li>
                                                <input type="checkbox" />&nbsp;&nbsp;report 4
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="btn-wrap">
                            <button className="button" type="button" onClick={(() => {
                                // this.saveAllChanges();
                            })}><img src={Save} alt="save" width="20" height="20" />&nbsp;&nbsp;Save
                                    </button>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.userDetails,
});

export default connect(mapStateToProps)(PrivilegeScreen);
