import React from 'react';
import './styles.css';

export default class LeaderboardV2 extends React.Component {
    render() {
        const { leaders } = this.props;
        return(
            <div className="leaderboard-container full-width">
                <table className="leaderboard-table full-width">
                    <tbody>
                        {
                            leaders.map(leader=>{
                                return(
                                    <tr>
                                        <td>
                                            <img className="rounded-corners" src={`data:image/png[jpg];base64,${leader.image}`} width="45" height="45" alt="" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {leader.repName}
                                        </td>
                                        <td>
                                            {leader.percentageChange} %
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

LeaderboardV2.defaultProps = {
    leaders: []
}