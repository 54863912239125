import React from 'react';
import { FormControl, Select, MenuItem, Input } from '@material-ui/core';

export default class Multiselect extends React.Component {
    render() {
        const { inputStyle, containerStyle, label, values, value, onChange, onClose, fullWidth, labelStyle, inputProps } = this.props;
        return (
            <FormControl fullWidth={fullWidth} style={containerStyle}>
                <Select
                    multiple
                    disableUnderline
                    displayEmpty
                    input={<Input style={inputStyle} />}
                    value={value}
                    onChange={event => {
                        onChange(event)
                    }}
                    onClose={() => onClose()}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <div style={{ color: 'white', fontSize: '12px', ...labelStyle }}>{label}</div>;
                        }
                        return <div style={{ color: 'white', fontSize: '12px', ...labelStyle }}>{selected.join(', ')}</div>
                    }}
                    inputProps={inputProps}
                >
                    <MenuItem disabled value="">
                        <em>{label}</em>
                    </MenuItem>
                    {
                        values.map(val => {
                            return (
                                <MenuItem
                                    key={val.id}
                                    value={val.value}
                                    classes={{
                                        selected: 'gray-background'
                                    }}
                                >
                                    {val.value}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        )
    }
}

Multiselect.defaultProps = {
    inputStyle: { color: 'white', fontSize: '12px' },
    containerStyle: {  width: 150 },
    labelStyle: {},
    inputProps: { 'aria-label': 'Without label' },
    label: 'Multi select',
    values: [],
    value: [],
    onChange: () => {},
    onClose: () => {},
    fullWidth: false
}