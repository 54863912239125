import React from 'react';
import LeadershipLineGraph from './LeadershipLineGraph';
import Manufacturers from '../../Manufacturers';
import Distributors from '../../Distributors';
import Constants, { roles } from '../../../util/Constants';
import Multiselect from '../../MultiselectComponents/Multiselect';
import DropDown from '../../MasterComponent/DistRepMapper/DropDown/DropDown';
import './index.css';
import { connect } from 'react-redux';
import { Tooltip,FormControl, FormControlLabel } from '@material-ui/core';
import { WheatSwitch } from '../../../util/Constants';
import DownloadIcon from '../../../../images/icon-fileDL.png';

class LeadershipGraph extends React.Component {
    constructor(props) {
        super(props);
    const { userDetails } = props;
    const name = userDetails.name.split(" ");
    this.selectedUserName = `${name[1]}, ${name[0]}`;
    this.currentUserName = this.selectedUserName;
    this.currentUserRole = userDetails.role;
    this.hqfilter = true;
    this.state = {
        manufacturers: [],
        distributors: [],
        types: [],
        states: [],
        rep:"",
        hqfilter: this.hqfilter
    }
    }

    componentDidMount() {
        this.props.fetchData();
    }

    render() {
        const { mouseover, mousemove, mouseleave, data, fetchData, download } = this.props;
        const { manufacturers, distributors, types, states, reps, hqfilter } = this.state;
        return (
            <div className="container-fluid leadership-graph-container">
                <div className="row">
                    <div className="col-sm-1 thin-select">
                        <Manufacturers
                            onChange={event => {
                                this.manufacturer = event.target.value.join(',');
                                this.setState({
                                    manufacturers: event.target.value
                                })
                            }}
                            onClose={_ => {
                                fetchData(this.manufacturer, this.distributor, this.type, this.stateCode, this.rep, this.hqfilter)
                            }}
                            multiselect
                            value={manufacturers}
                        />
                    </div>
                    <div className="col-sm-1 thin-select">
                        <Distributors
                            onChange={event => {
                                this.distributor = event.target.value.join(',');
                                this.setState({
                                    distributors: event.target.value
                                })
                                //fetchData(this.manufacturer, this.distributor)
                            }}
                            value={distributors}
                            onClose={_ => {
                                fetchData(this.manufacturer, this.distributor, this.type, this.stateCode, this.rep, this.hqfilter)
                            }}
                            multiselect
                            isStrategic= "true"
                        />
                    </div>
                    <div className="col-sm-1 thin-select">
                        <Multiselect
                            label="All Types"
                            values={['Purch', 'POS'].map(type => ({
                                value: type,
                                id: type
                            }))}
                            value={types}
                            onChange={event => {
                                this.type = event.target.value.join(',')
                                this.setState({
                                    types: event.target.value
                                })
                            }}
                            labelStyle={{ marginLeft: '2px', fontSize: '12px' }}
                            containerStyle={{ border: '1px solid gray', backgroundColor: 'rgba(78, 80, 84, 0.466)', width: 150 }}
                            inputProps={{
                                'aria-label': 'Without label',
                            }}
                            onClose={_ => {
                                fetchData(this.manufacturer, this.distributor, this.type, this.stateCode, this.rep, this.hqfilter)
                            }}
                            fullWidth />
                    </div>
                    <div className="col-sm-1 thin-select">
                        <FormControl className="select-input">
                                    <FormControlLabel
                                        control={
                                            <WheatSwitch
                                                checked={hqfilter}
                                                color="default"
                                                onClick={(event) => {
													this.hqfilter= event.target.checked;
                                                    this.setState({
                                                        hqfilter: event.target.checked
                                                    })
                                                    fetchData(this.manufacturer, this.distributor, this.type, this.stateCode, this.rep, this.hqfilter)
                                                }}
                                            />
                                        }
                                        label='HQ Filter'
                                        className="status-switch"
                                    />
                        </FormControl>
                    </div>
                    <div className="col-sm-1" width="100%" >
                        <Tooltip title="Download" >
                            <img className="float-right" src={DownloadIcon} width="38px" height="38px" onClick={() => {
                                download(this.rep, this.hqfilter);
                            }} />
                        </Tooltip>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <LeadershipLineGraph
                            mouseover={mouseover}
                            mousemove={mousemove}
                            mouseleave={mouseleave}
                            data={data}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    userDetails: state.userDetails
})

LeadershipGraph.defaultProps = {
    mouseover: () => { },
    mousemove: () => { },
    mouseleave: () => { },
    fetchData: () => { },
    download:() => {},
    data: []
}

export default connect(mapStateToProps)(LeadershipGraph);