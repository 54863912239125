import React from 'react';
import SalesResponsibility from './SalesResponsibility/SalesResponsibility';
import { connect } from 'react-redux';
import Constants, { roles }from '../../util/Constants';

import TableHeaders from '../ZipReportComponent/TableHeaders';
import TableBody from '../ZipReportComponent/TableBody';
import DropDown from '../MasterComponent/DistRepMapper/DropDown/DropDown';
import Manufacturers from '../Manufacturers';
import Distributors from '../Distributors';
import './Home.css';
import TableFooter from '../ZipReportComponent/TableFooter';

const recordsPerPage = Constants.maxHomePageRows;

const columns = [
    {
        label: 'DIST',
        nosort: true,
        key: 'distributor'
    }, {
        label: 'MFG',
        nosort: true,
        key: 'mfg'
    }
]

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.tableParams = {
            limit: recordsPerPage
        }
    }

    state = {
        states: [],
        tableData: [],
        selectedState: ''
    }

    getStates(uname, rol) {
        const { role, name } = this.props.userDetails;
        const fullName = String(name).split(" ");
        const params = `rep=${uname || (fullName[1]+', '+fullName[0])}&role=${rol || role}`;
        const url = `${Constants.baseURL}${Constants.getStatesForRep}?${params}`

        fetch(url, {
            signal: this.abortController.signal
        })
        .then(response=>response.json())
        .then(states=>{
            this.setState({
                states
            })
            this.tableParams.location = states[0];
            this.getTableData();
        }).catch(error=>{
            console.log(error)
        })
    }

    getTableData(index=0) {
        const queryParams = Object.keys(this.tableParams).reduce((total, current)=>{
            if(!this.tableParams[current]) {
                return total;
            }
            return `${total}&${current}=${this.tableParams[current]}`;
        }, '')
        const url = `${Constants.baseURL}${Constants.getMfgLookupForState}?${queryParams}&index=${index}`

        if(this.tableParams.location) {
            this.setState({
                selectedState: this.tableParams.location
            })
        }

        fetch(url, {
            signal: this.abortController.signal
        })
        .then(response=>response.json())
        .then(data=>{
            this.setState({
                tableData: data.response,
                totalPages: Math.ceil(data.totalCount/recordsPerPage),
                page: index + 1
            })
        }).catch(error=>{
            console.log(error);
        })
    }

    updateTableData(data) {
        this.tableParams.location = data.properties.code;
        this.getTableData();
    }

    componentDidMount() {
        this.abortController = new AbortController()
        this.getStates();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {
        const { states, tableData, totalPages, page, selectedState } = this.state;
        const { userDetails } = this.props;
        const fullName = String(userDetails.name).split(" ");
        const params = fullName[1]+', '+fullName[0];
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-7"></div>
                    <div className="col-sm-2 home-dropdown">
                        { (userDetails.role === roles.SALES_MANAGER || userDetails.role === roles.LEADERSHIP) &&
                            <DropDown
                                label="All Users"
                                url={`${Constants.baseURL}${Constants.getIssUnderRep}?iss=${params}&role=${userDetails.role}`}
                                onChange={(event)=>{
                                    const user = event.target.value;
										if(user === '') {
											this.getStates(user, userDetails.role)
                                        } else if(userDetails.role === roles.LEADERSHIP) {
											this.getStates(user, roles.SALES_MANAGER)
                                        } else {
											this.getStates(user, roles.SALES_REP)
                                        }
                                }}
                            />
                        }
                    </div>
                    <div className="col-sm-3">
                        <div className="row home-dropdown">
                            <div className="col-sm-6">
                                <Distributors
                                    onChange={event=>{
                                        this.tableParams.dist = event.target.value;
                                        this.getTableData()
                                    }}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Manufacturers
                                    onChange={(event)=>{
                                        this.tableParams.mfg = event.target.value;
                                        this.getTableData();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-9">
                        <SalesResponsibility
                            states={states}
                            selectedState={selectedState}
                            onClick={(data)=>this.updateTableData(data)}
                        />
                    </div>
                    <div className="col-sm-3">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="listing-grid table-responsive table-container">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <TableHeaders
                                                    columns={columns}
                                                />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <TableBody
                                                columns={columns}
                                                data={tableData}
                                                disableActions={true}
                                            />
                                        </tbody>
                                        <tfoot>
                                            <TableFooter
                                                totalPages={totalPages}
                                                page={page}
                                                changePage={(index)=>{
                                                    this.getTableData(index);
                                                }}
                                            />
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state)=>({
    userDetails: state.userDetails
})

export default connect(mapStateToProps)(Home);