import React from 'react';
import Constants from '../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../redux/actions';
import { FormControl, Select, MenuItem, Input } from '@material-ui/core';

class Manufacturers extends React.Component {

    state = {
        manufacturers: []
    }

    componentDidMount() {
        this.abortController = new AbortController();
        this.fetchData();
    }

    fetchData() {
        const { dispatch, onFetchComplete } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.getManufacturers}`;
        fetch(url, {
            signal: this.abortController.signal
        })
            .then(data => data.json())
            .then(data => {
                dispatch(showLoader(false));
                this.setState({ manufacturers: data });
                onFetchComplete(data);
            })
            .catch(error => {
                dispatch(showLoader(false));
                console.log('Error: ' + error);
            });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {
        const { onChange, disabled, multiselect, value, onClose, width, fontSize, marginBottom } = this.props;
        const { manufacturers } = this.state;

        if (multiselect) {
            return (
                <FormControl style={{
                    width: width ? width : 150, border: '1px solid gray', backgroundColor: 'rgba(78, 80, 84, 0.466)',
                    marginBottom: marginBottom
                }}>
                    <Select
                        multiple
                        disableUnderline
                        displayEmpty
                        input={<Input style={{color: 'white', fontSize: fontSize ? fontSize:'12px', paddingLeft: '15px'}} />}
                        value={value}
                        onChange={event => {
                            onChange(event)
                        }}
                        onClose={() => onClose()}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <div style={{color: 'white', fontSize: fontSize ? fontSize:'12px'}}>All Manufactures</div>;
                            }
                            return selected.join(', ');
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem disabled value="">
                            <em>All Manufactures</em>
                        </MenuItem>
                        {
                            manufacturers.map(man => {
                                return(
                                    <MenuItem
                                        key={man.id}
                                        value={man.mfgName}
                                        classes={{
                                            selected: 'gray-background'
                                        }}
                                    >
                                        {man.mfgName}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            )
        }

        return (
            <select
                className="select special-select"
                disabled={disabled}
                onChange={(event) => {
                    onChange(event);
                }}
            >
                <option value="">All Manufactures</option>
                {
                    manufacturers.map(manufacturer => <option key={manufacturer.id} value={manufacturer.mfgName}>{manufacturer.mfgName}</option>)
                }
            </select>
        )
    }
}

Manufacturers.defaultProps = {
    onChange: () => { },
    onClose: () => {},
    disabled: false,
    onFetchComplete: () => { },
    value: undefined
}

export default connect()(Manufacturers);