import React from 'react';
import { connect } from 'react-redux';
import Constants from '../../../util/Constants';
import { showLoader } from '../../../../redux/actions';
import FilterDropdown from '../FilterDropdown/FilterDropdown';

class Distributors extends React.Component {

    state = {
        distributors: []
    }

    componentDidMount() {
        this.abortController = new AbortController();
        this.fetchData();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    fetchData() {
        const { dispatch, onFetchComplete, isHqFilter } = this.props;
        dispatch(showLoader(true));
        let url = '';
        if(!!isHqFilter) {
            url = `${Constants.baseURL}${Constants.getHQFilterDistributors}`;
        } else {
            url = `${Constants.baseURL}${Constants.getDistributors}`;
        }
        fetch(url, {
            signal: this.abortController.signal
        })
            .then(data => data.json())
            .then(data => {
                dispatch(showLoader(false));
                this.setState({ distributors: data });
                onFetchComplete(data);
            })
            .catch(error => {
                dispatch(showLoader(false));
                console.log('Error: ' + error);
            });
    }

    render() {
        const { onChange, disabled, label, isHqFilter } = this.props;
        const { distributors } = this.state;
        let listItems;
        if(isHqFilter) {
            listItems = distributors.map(distributor=>({
                label: distributor
            }));
        } else {
            listItems=distributors.map(distributor=>({
                label: distributor.distributionName,
                ...distributor
            }));
        }
        return(
            <FilterDropdown
                listItems={listItems}
                label={label || "Choose distributor"}
                disabled={disabled}
                onChange={onChange}
            />
        )
    }
}

Distributors.defaultProps = {
    onChange: () => { },
    disabled: false,
    onFetchComplete: () => { },
    label: ""
}

export default connect()(Distributors);