// This is the loader that is used for the inner pages after logging in

import React from 'react';
import { ClipLoader } from 'react-spinners';

export default class InnerLoader extends React.Component {
    render() {
        return(
            <React.Fragment>
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#00000080', zIndex: 100}}></div>
                <div style={{position: 'absolute', left: '50%', top: '50%', zIndex: 100, transform: 'translate(-50%, -50%)'}}>
                    <ClipLoader
                        sizeUnit={"px"}
                        size={150}
                        color={'#123abc'}
                        loading={true}
                    />
                </div>
            </React.Fragment>
        )
    }
}