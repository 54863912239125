import React from 'react';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import Constants, { toastOptions } from '../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';
import { toast } from 'react-toastify';

const columns = [
    {
        label: 'Zip Code',
        key: 'zipCode'
    },
    {
        label: 'Primary City',
        key: 'primaryCity'
    },
    {
        label: 'State',
        key: 'state'
    },
    {
        label: 'County',
        key: 'county'
    },
    {
        label: 'Rep',
        key: 'rep'
    },
    {
        label: 'Iss',
        key: 'iss'
    },
    {
        label: 'Rsm',
        key: 'rsm'
    },
    {
        label: 'Region',
        key: 'rsmId'
    },
    {
        label: 'Territory',
        key: 'territory'
    },
	{
        label: 'TerritoryId',
        key: 'territoryId'
    },
    {
        label: 'Last Modified By',
        key: 'lastModifiedBy'
    },
    {
        label: 'Last Modified Time',
        key: 'lastModifiedTime'
    }
];

const dummyRow = {
    zipCode: '',
    primaryCity: '',
    state: '',
    county: '',
    rep: '',
    iss: '',
    rsm: '',
    region: '',
    territory: '',
	territoryId: ''
}

class ZipDataTable extends React.Component {

    static tempId = 0;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            sortedColId: 0,
            sortOrderAscending: false,
            totalPages: 0,
            page: 1
        }
        this.index = 0;
    }

    componentDidMount() {
        this.abortController = new AbortController();
        // initially fetch history to populate table on load
        this.fetchHistory();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.searchString !== this.props.searchString) {
            this.fetchHistory(0);
        }
    }

    /**
     * Fetch initial data to populate the table
     */
    fetchHistory(index) {
        if(index === undefined) {
            index = this.index;
        } else {
            this.index = index;
            this.setState({
                page: index+1
            });
        }
        const { dispatch, searchString } = this.props;
        dispatch(showLoader(true));
        // api call to fetch upload history
        const url = `${Constants.baseURL}${Constants.zipUploadHistory}?limit=${Constants.maxZipHistoryRows}&index=${index}${searchString}`;
        fetch(url, {
            signal: this.abortController.signal
        }).then(response=>{
            dispatch(showLoader(false));
            return response.json();
        }).then(response => {
            const totalPages = Math.ceil(response.totalCount/Constants.maxZipHistoryRows);
            this.setState({
                data: response.response,
                totalPages
            })
        }).catch(error => {
            dispatch(showLoader(false));
            console.log(error);
        });
    }

    /**
     * Function to delete a row from history table
     * 
     * @param {integer} id of row to be deleted
     */
    deleteHistory(id) {
        if(String(id).startsWith('temp')) {
            const { data } = this.state;
            this.setState({
                data: data.filter(row=>row.id !== id)
            })
            return;
        }
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        let path = Constants.deleteZipHistory;
        path = path.replace('{id}', id);
        const url = `${Constants.baseURL}${path}`;
        fetch(url, {
            method: 'DELETE'
        }).then(response=>{
            dispatch(showLoader(false));
            if(response.status === 200) {
                toast.success("Record deleted successfully", toastOptions);
                this.fetchHistory();
            } else {
                throw new Error("Success: Delete failed");
            }
        }).catch(error=>{
            dispatch(showLoader(false));
            console.log("Error: "+error);
            toast.error("Error: Delete failed", toastOptions);
            this.fetchHistory();
        });
    }

    /**
     * This function is to sort the table based on the column object passed to it
     * 
     * @param {object} column object
     * @param {integer} index index of column to be sorted
     */
    sortTable(column, index=0) {
        let { data, sortOrderAscending, sortedColId } = this.state;
        if(sortedColId === index) {
            sortOrderAscending = !sortOrderAscending;
        } else {
            sortOrderAscending = true;
            sortedColId = index;
        }
        data = data.sort((row1, row2)=>{
            const string1 = JSON.stringify(row1[column.key]);
            const string2 = JSON.stringify(row2[column.key]);
            const diff = string1.localeCompare(string2);
            return sortOrderAscending ? diff : -1*diff;
        });
        this.setState({
            data,
            sortOrderAscending,
            sortedColId
        })
    }

    /**
     * Function to add new row
     */
    addRow() {
        const newRow = JSON.parse(JSON.stringify(dummyRow));
        newRow.id = `temp${ZipDataTable.tempId++}`;
        const { data } = this.state;
        data.push(newRow);
        this.setState({
            data
        })
    }

    render() {
        const { sortedColId, sortOrderAscending, data, totalPages, page } = this.state;
        const { onDataChange, editable, resetEditSwitch } = this.props;
        return (
            <div className="listing-grid table-responsive table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders columns={columns} sortedColId={sortedColId} sortOrderAscending={sortOrderAscending} sortTable={(column, index)=>this.sortTable(column, index)} />
                            {editable && <th className="history-table-header" scope="col" >Action</th >}
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody
                            columns={columns}
                            data={data}
                            deleteHistory={(id)=>this.deleteHistory(id)}
                            readOnlyFields={[10, 11]}
                            onDataChange={onDataChange}
                            page={page}
                            disableActions={!editable}
                            resetEditSwitch={resetEditSwitch}
                            hiddenEditRowIndexes={
                                data.reduce((t,c, index)=>{
                                    if(String(c.id).startsWith('temp')) {
                                        t.push(index);
                                    }
                                    return t;
                                }, [])
                            }
                        />
                        <TableFooter totalPages={totalPages} page={page} changePage={(index)=>this.fetchHistory(index)} />
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('ZIP_REP')
})

export default connect(mapStateToProps, null, null, {forwardRef: true})(ZipDataTable);

ZipDataTable.defaultProps = {
    onDataChange: ()=>{}
}