import React from 'react';
import './SearchBox.css';
import DropDown from '../DropDown/DropDown';
import Constants from '../../../../util/Constants';
import Distributors from '../../../FilterComponents/Distributors/Distributors';

export default class SearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.searchParams = {}
    }

    render() {
        const { onSearch } = this.props;
        return (
            <form className="row dist-rep-mapper-search-container" onSubmit={(e)=>e.preventDefault()}>
                <div className="col-sm-2">
                    <Distributors
                        label="Distributor"
                        onChange={distributor => {
                            this.searchParams.distributor = distributor === null ? '' : distributor.distributionName
                        }}
                    />
                </div>
                <div className="col-sm-2">
                    <DropDown
                        label="Representative"
                        url={`${Constants.baseURL}${Constants.getDistRepPersons}`}
                        onChange={(event)=>{
                            this.searchParams.person = event.target.value;
                        }}
                    />
                </div>
                <div className="col-sm-2">
                    <DropDown
                        label="Group"
                        url={`${Constants.baseURL}${Constants.getDistRepGroups}`}
                        onChange={(event)=>{
                            this.searchParams.groups = event.target.value;
                        }}
                    />
                </div>
                <div className="col-sm-2">
                    <DropDown
                        label="Team"
                        url={`${Constants.baseURL}${Constants.getDistRepTeams}`}
                        onChange={(event)=>{
                            this.searchParams.team = event.target.value;
                        }}
                    />
                </div>
                <div className="col-sm-4 brn-wrap">
                    <button
                        className="button"
                        onClick={()=>{
                            onSearch(this.searchParams);
                        }}
                    >Search</button>
                    <button
                        className="button"
                        type="reset"
                        onClick={()=>{
                            this.searchParams={}
                        }}
                    >Clear</button>
                </div>
            </form>
        )
    }
}

SearchBox.defaultProps = {
    onFetchComplete: ()=>{}
}