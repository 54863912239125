import React from 'react';
import Distributors from "../../Distributors";
import Manufacturers from "../../Manufacturers";
import RegionMapperTable from './RegionMapperTable';
import Constants, { toastOptions } from '../../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Popup from '../../Popup/Popup';
import AddAndSaveButtons from '../AddAndSaveButtons';

const dummyRow = {
    distributor: '',
    mfg: '',
    region: ''
}

class RegionMapper extends React.Component {

    static count = 0;

    state = {
        data: [],
        manufacturers: [],
        distributors: [],
        manufacturer: '',
        distributor: '',
        showPopup: false,
        resetEditSwitch: false
    }

    handleChange(event, key) {
        this.setState({
            [key]: event.target.value
        })
    }

    componentDidMount() {
        this.abortController = new AbortController();
        this.fetchData();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    fetchData() {
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.getRegionDetails}`;
        fetch(url, {
            signal: this.abortController.signal
        }).then(response=>{
            dispatch(showLoader(false));
            return response.json();
        }).then(jsonData=>{
            this.setState({
                data: jsonData
            })
        }).catch(error=>{
            dispatch(showLoader(false));
            console.log("Error: "+error);
        })
    }

    addRow() {
        const { data } = this.state;
        data.push(JSON.parse(JSON.stringify(dummyRow)));
        const newRowIndex = data.length - 1;
        data[newRowIndex].id = `temp${RegionMapper.count}`;
        RegionMapper.count++;
        this.setState({
            data
        })
    }

    deleteRow(id) {
        let { data } = this.state;
        data = data.filter(row=>String(row.id) !== String(id));
        this.setState({
            data
        });
        if(this.updatedData) {
            this.updatedData = this.updatedData.filter(row=>String(row.id) !== String(id));
        }
        if(String(id).startsWith('temp')) {
            return;
        }
        this.deleteApiCall(id);
    }

    deleteApiCall(id) {
        const params = Constants.deleteRegionMapper.replace('{regionId}', id);
        const url = `${Constants.baseURL}${params}`;
        fetch(url, {
            method: 'DELETE'
        }).then(response=>{
            console.log(response);
            this.fetchData();
        }).catch(error=>{
            console.log(error);
            this.fetchData();
        })
    }

    saveData() {
        if(!this.updatedData) {
            return;
        }
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.createOrUpdateRegion}`;
        const body = JSON.stringify(this.updatedData.map(row=>{
            if(String(row.id).startsWith('temp')) {
                delete row.id;
            }
            return row;
        }));
        fetch(url, {
            method: 'POST',
            signal: this.abortController.signal,
            body,
            headers: {
                'Content-type': 'application/json'
            }
        }).then(response=>{
            if(response.status !== 204) {
                throw new Error()
            }
            dispatch(showLoader(false));
            toast.success("Success: Changes saved", toastOptions);
            this.setState({
                resetEditSwitch: !this.state.resetEditSwitch
            })
            this.fetchData();
        }).catch(error=>{
            console.log(error);
            dispatch(showLoader(false));
            toast.error("Error: Operation failed", toastOptions);
            this.fetchData();
        })
    }

    /**
     * Higher order function to close popup
     * 
     * @param {function} arg - function to execute
     */
    closePopup(arg = ()=>{}) {
        this.setState({
            showPopup: false
        })
        arg()
    }

    render() {

        const { data, manufacturer, distributor, manufacturers, distributors, showPopup, resetEditSwitch } = this.state;
        const { editable } = this.props;

        return(
            <div className="container-fluid">
                { showPopup &&
                    <Popup
                        onYes={()=>{
                            this.closePopup(this.deleteRow(this.idToDelete));
                        }}
                        onNo={()=>{
                            this.closePopup();
                        }}
                    />
                }
                <div className="row">
                    <div className="col-sm-4">
                        <Distributors
                            onChange={event=>this.handleChange(event, 'distributor')}
                            onFetchComplete={(distributors)=>{
                                this.setState({
                                    distributors
                                })
                            }}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Manufacturers
                            onChange={event=>this.handleChange(event, 'manufacturer')}
                            onFetchComplete={(manufacturers)=>{
                                this.setState({
                                    manufacturers
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <RegionMapperTable
                            data={data}
                            onDataChange={(updatedData)=>{
                                this.updatedData = updatedData;
                                updatedData.forEach(row=>{
                                    const changeIndex = data.indexOf(d=>d.id === row.id);
                                    data[changeIndex] = row;
                                })
                                this.setState({
                                    data
                                })
                            }}
                            manufacturers={manufacturers}
                            distributors={distributors}
                            manufacturer={manufacturer}
                            distributor={distributor}
                            deleteRow={(id)=>{
                                this.idToDelete = id;
                                this.setState({
                                    showPopup: true
                                })
                            }}
                            editable={editable}
                            resetEditSwitch={resetEditSwitch}
                        />
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={()=>{
                        this.addRow();
                    }}
                    onSaveClick={()=>{
                        this.saveData();
                    }}
                    disabled={!editable}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('MASTER')
})

export default connect(mapStateToProps)(RegionMapper);