import React from "react";
import { connect } from "react-redux";
import Constants from "../util/Constants";
import { showLoader } from "../../redux/actions";

class UserSelect extends React.Component {
  state = {
    users: [],
  };

  componentDidMount() {
    this.abortController = new AbortController();
    this.fetchData();
  }

  fetchData() {
    const { dispatch, onFetchComplete } = this.props;
    dispatch(showLoader(true));
    const url = `${Constants.baseURL}${Constants.getAllUserDetails}`;
    fetch(url, {
      signal: this.abortController.signal,
    })
      .then((data) => data.json())
      .then((data) => {
        dispatch(showLoader(false));
        this.setState({ users: data });
        onFetchComplete(data);
      })
      .catch((error) => {
        dispatch(showLoader(false));
        console.log("Error: " + error);
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const { disabled, onChange, label, userNames, nolabel, value } = this.props;
    const { users } = this.state;
    return (
      <select
        className="select special-select"
        disabled={disabled}
        onChange={(event) => {
          onChange(event, users[event.target.selectedIndex]);
        }}
        value={value}
      >
        {!nolabel && (
          <option value="">{label || "Choose Representative Name"}</option>
        )}
        {users.map((user) => {
          const value = userNames ? user.userName : `${user.userName}`;
          return <option key={user.userId}>{value}</option>;
        })}
      </select>
    );
  }
}

UserSelect.defaultProps = {
  onChange: () => {},
  onFetchComplete: () => {},
  disabled: false,
  label: "",
  userNames: false,
  nolabel: false,
  value: undefined,
};

export default connect()(UserSelect);
