import React from 'react';
import './AllocatedRepresentatives.css';

export default class AllocatedRepresentatives extends React.Component {
    render() {
        const { data } = this.props;
        return(
            <table>
                <thead>
					<tr class="blank_row">
            <td>&nbsp;</td>
</tr>
                    <tr>
                        <th colSpan={2}>Allocated Representatives</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((value, id)=>{
                            return(
                                <tr key={id}>
                                    <td>
                                        {id+1}
                                    </td>
                                    <td>
                                        {value}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }
}

AllocatedRepresentatives.defaultProps = {
    data: []
}