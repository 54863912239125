import React from 'react';

export default class TableFooter extends React.Component {

    changePage(page) {
        const { changePage } = this.props;
        changePage(page - 1);
    }

    render() {
        const { totalPages, page } = this.props;
        return (
            <tr>
                <td colSpan={100} className="history-table-footer">
                    {
                        totalPages === 0 || isNaN(totalPages) ?
                            "No data found"
                            :
                            <span>
                                <span className={page === 1 ? "disabled" : ""} onClick={() => { this.changePage(page - 1) }}>&#9664;</span>&nbsp;&nbsp;
                                <select
                                    onChange={(event) => {
                                        this.changePage(parseInt(event.target.value));
                                    }}
                                    value={page}
                                >
                                    {
                                        Array(totalPages).fill(1).map((v, k) => <option key={'page' + k} value={k + 1}>{k + 1}</option>)
                                    }
                                </select>
                                &nbsp;&nbsp;<span className={totalPages === page ? "disabled" : ""} onClick={() => { this.changePage(page + 1) }}>&#9654;</span>
                            </span>
                    }
                </td>
            </tr>
        )
    }
}

TableFooter.defaultProps = {
    changePage: () => { },
    totalPages: 0,
    page: 1
}