import React, { Fragment } from 'react';
import { Tooltip, FormControl, FormControlLabel } from '@material-ui/core';
import { CustomTextField, WheatSwitch, numberWithComas } from '../../util/Constants';
import Active from '../../../images/icon-active.png';
import Disabled from '../../../images/icon-disabled.png';
import FilterDropdown from '../FilterComponents/FilterDropdown/FilterDropdown';

export default class TableBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            enableEdit: [],
            data: props.data
        }
        this.editedValues = [];
    }

    componentDidUpdate(prevProps) {
        // reset editedValues and enableEdit on new page navigation // reset editable fields on switch change
        if (prevProps.page !== this.props.page || prevProps.resetEditSwitch !== this.props.resetEditSwitch) {
            this.editedValues = [];
            this.setState({
                enableEdit: [],
            })
            this.props.onDataChange([]);
        }
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({
                data: this.props.data
            })
        }
    }

    /**
     * This function is to clear edited values array after a save operation
     */
    clearEditedValues() {
        this.editedValues = []
    }

    /**
     * Function that handles edit inside table
     * @param {value} value - new value
     * @param {object} row - row object
     * @param {integer} rowIndex - row index to identify the row
     * @param {string} columnKey - the key of column being changed
     */
    handleEdit(value, row, rowIndex, columnKey) {
        const { onDataChange } = this.props;
        const { data } = this.state;
        row[columnKey] = value;
        data[rowIndex][columnKey] = value;
        this.setState({
            data
        })
        this.editedValues[rowIndex] = row;
        onDataChange(this.editedValues.filter(value => value !== undefined));
    }

    render() {
        const { columns, deleteHistory, readOnlyFields, disableActions, hiddenEditRowIndexes, noedit, sendObjectOnDelete, disableActionsWithoutHiding } = this.props;
        const { enableEdit, data } = this.state;
        return (
            data.map((row, rowIndex) =>{
                const disabled = !(enableEdit[rowIndex] || hiddenEditRowIndexes.includes(rowIndex)) || disableActionsWithoutHiding;
                return(
                <tr key={'row' + rowIndex}>
                    {
                        columns.map((column, columnIndex) => {
                            const value = row[column.key];
                            const withComas = column.withComas || false;
                            return (
                                <td key={'column' + columnIndex} className="table-data custom-table-body" >
                                    {
                                        (()=>{
                                            if((readOnlyFields.includes(columnIndex) || disabled) && column.type !== 'boolean') {
                                                return withComas ? numberWithComas(value, column.prefix) : value;
                                            }
                                            switch(column.type) {
                                                case 'boolean':
                                                    return(
                                                        <FormControl className="select-input">
                                                            <FormControlLabel
                                                                disabled={disabled}
                                                                control={
                                                                    <WheatSwitch
                                                                        checked={value}
                                                                        color="default"
                                                                        onClick={(event) => {
                                                                            this.handleEdit(event.target.checked, row, rowIndex, column.key)
                                                                        }}
                                                                    />
                                                                }
                                                                label={value ? "True\u00A0\u00A0 " : "False"}
                                                                className="status-switch"
                                                            />
                                                            <img src={value ? Active : Disabled} width="30px" height="30px" alt="status" className="status-icon" />
                                                        </FormControl>
                                                    )
                                                case 'enum':
                                                    const label = column.enum.label;
                                                    const values = column.enum.values;
                                                    return(
                                                        <select
                                                            className="select special-select"
                                                            disabled={disabled}
                                                            value={value}
                                                            onChange={(event)=>this.handleEdit(event.target.value, row, rowIndex, column.key)}
                                                        >
                                                            <option value="">{label}</option>
                                                            {
                                                                values.map((value, index)=>
                                                                    <option key={index} value={value}>{value}</option>
                                                                )
                                                            }
                                                        </select>
                                                    )
                                                    case 'auto':
                                                        const label1 = column.enum.label;
                                                        const values1 = column.enum.values;
                                                        return(
                                                            <FilterDropdown
                                                            listItems={values1.map(distributor=>({
                                                                label: distributor
                                                            }))}
                                                            label={label1 || "Choose distributor"}
                                                            disabled={disabled}
                                                            onChange={(value)=>this.handleEdit(value.label, row, rowIndex, column.key)}
                                                        />
                                                        )
                                                default:
                                                    return(
                                                        <CustomTextField
                                                            fullWidth
                                                            value={value}
                                                            onChange={(event) => this.handleEdit(event.target.value || '', row, rowIndex, column.key)}
                                                        />
                                                    )
                                            }
                                        })()
                                    }
                                </td>)
                        }
                        ).concat(
                            disableActions?[]:
                            <td key={'row' + rowIndex + 1} className={"user-actions custom-table-body"+(disableActionsWithoutHiding?' not-editable':'')}>
                                {row.dataCount !== null &&
                                    <Fragment>
                                        <Tooltip title="This will delete corresponding uploaded data in the DB">
                                            <span className="delete" onClick={() => {
                                                deleteHistory(sendObjectOnDelete ? row : row.id);
                                            }} />
                                        </Tooltip>
                                        { (!hiddenEditRowIndexes.includes(rowIndex) && !noedit) &&
                                            <Tooltip title="Toggle edit">
                                                <span className="edit" onClick={() => {
                                                    enableEdit[rowIndex] = !enableEdit[rowIndex];
                                                    this.setState({
                                                        enableEdit
                                                    })
                                                }} />
                                            </Tooltip>
                                        }
                                    </Fragment>
                                }
                            </td>
                        )
                    }
                </tr>)
            })
        )
    }
}

TableBody.defaultProps = {
    data: [],
    columns: [],
    deleteHistory: () => { },
    readOnlyFields: [],
    onDataChange: () => { },
    disableActions: false,
    noedit: false,
    hiddenEditRowIndexes: [],
    sendObjectOnDelete: false,
    disableActionsWithoutHiding: false,
    resetEditSwitch: false
}