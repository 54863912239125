import React from 'react';
import './Expense.css';
import '../../../index.css'
import HeadwiseExpense from './HeadwiseExpense/HeadwiseExpense';
import EventHeadExpense from './EventHeadExpense/EventHeadExpense';
import BudgetExceeded from './Flags/BudgetExceeded';
import NearBudget from './Flags/NearBudget';
import UnderUtilized from './Flags/UnderUtilized';
import ExpenseYOY from './ExpenseYOY/ExpenseYOY';
import EventExpenseYOY from './EventExpenseYOY/EventExpenseYOY';
import BudgetDetails from './BudgetDetails/BudgetDetails';
import Constants, { downloadFile,roles } from '../../util/Constants';
import Filters from './Filters/Filters';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { Tooltip } from '@material-ui/core';
import DownloadIcon from '../../../images/icon-fileDL.png';
import { showLoader } from '../../../redux/actions';

import SimpleUploadComponent from '../SimpleUploadComponent/SimpleUploadComponent';
import UploadIcon from '../../../images/icon-upload.ico';

const data1 = [
    {
        name: 'SUPPLIES OFFICE',
        expense: 1500,
        budget: 3500
    },
    {
        name: 'MOBILE PHONE',
        expense: 4000,
        budget: 4500
    },
    {
        name: 'WIRELESS CARD',
        expense: 3900,
        budget: 4540
    },
    {
        name: 'AIRFARE',
        expense: 1200,
        budget: 1700
    },
    {
        name: 'AUTO RENTAL',
        expense: 7000,
        budget: 8000
    },
    {
        name: 'AUTOMOBILE EXPENSE',
        expense: 4200,
        budget: 7500
    },
    {
        name: 'ENTERTAINMENT',
        expense: 5005,
        budget: 7500
    },
    {
        name: 'HOTEL & LODGING',
        expense: 1000,
        budget: 1700
    },
    {
        name: 'PRINTING AND REPRODUCTION',
        expense: 100,
        budget: 700
    }
]

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const maxBars = Constants.maxExpensesBarGraphBars;
const maxPoints = Constants.maxExpensesLineGraphDataPoints;
const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
const today = new Date();

class Expense extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expenseBudgetData: [],
            eventExpenseBudgetData:[],
            yoyData: {
                overBudget: [],
                nearBudget: [],
                underBudget: []
            },
            headwiseExpensePage: 1,
            yoyPage: 1,
            eventHeadwiseExpensePage: 1,
            yoyEventPage: 1
        }
        this.queryParams = {
            year: today.getFullYear(),
            month: months[today.getMonth()]
        };
        if (today.getMonth()===0) {
            this.year = today.getFullYear()-1;
            this.monthOrQuarter = months[11].toLowerCase();
        } else {
            this.year = today.getFullYear();
            this.monthOrQuarter = months[today.getMonth()-1].toLowerCase();
        }
        this.isMonth = false;
        this.tooltipRef = React.createRef();
    }

    fetchAllData() {
        this.fetchHeadwiseExpense();
        this.fetchHeadwiseEventExpense();
        this.fetchExpenseYOY();
    }

    getQueryParameters() {
        let result = '';
        Object.keys(this.queryParams).forEach(key=>{
            const value = this.queryParams[key];
            if(value) {
                result += `&${key}=${value}`;
            }
        })
        return result.slice(1);
    }

    fetchHeadwiseExpense() {
        const url = `${Constants.expensesBaseURL}${Constants.getHeadwiseExpenses}`;
        fetch(`${url}?${this.getQueryParameters()}`)
            .then(response=>response.json())
            .then(data=>{
                data.forEach(row=>row.name = row.headName)
                this.setState({
                    expenseBudgetData: data,
                    headwiseExpensePage: 1,
                    yoyPage: 1
                })
            })
            .catch(error=>{
                console.log(error);
            })
    }

    fetchHeadwiseEventExpense() {
        const url = `${Constants.expensesBaseURL}/dashboard/headWiseEventExpense`;
        fetch(`${url}?${this.getQueryParameters()}`)
            .then(response=>response.json())
            .then(data=>{
                data.forEach(row=>row.name = row.headName)
                this.setState({
                    eventExpenseBudgetData: data,
                    eventHeadwiseExpensePage: 1,
                    yoyEventPage: 1
                })
            })
            .catch(error=>{
                console.log(error);
            })
    }

    fetchExpenseYOY() {
        const url = `${Constants.expensesBaseURL}${Constants.getExpensesYOY}`;
        fetch(`${url}?${this.getQueryParameters()}`)
            .then(response=>response.json())
            .then(data=>{
                this.setState({
                    yoyData: data
                })
            }).catch(error=>{
                console.log(error);
            })
    }

    download() {
        
        const url = `${Constants.expensesBaseURL}/data/download`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));     

        downloadFile(url, "expense-summary", "xlsx")
            .finally(() => {
                dispatch(showLoader(false));
            })
    }
    /**
     * Function to change page
     * @param {string} pageType - type of page - headwiseExpensePage or yoyPage
     * @param {integer} offset - offset by which page is changed - +1 or -1
     */
    changePage(pageType, offset) {
        const page = this.state[pageType];
        this.setState({
            [pageType]: page+offset
        })
    }

    componentDidMount() {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.on("mouseover", _=>{
            Tooltip.style('left', 0)
        })
    }

    mouseover() {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.style("opacity", 1)
    }

    mousemove(html) {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.html(html)
        const styles = Tooltip.node().getBoundingClientRect();
        Tooltip.style('left', `${d3.event.x + 5}px`).style('top', `${d3.event.y - styles.height-5}px`)
    }

    mouseleave() {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.style("opacity", 0)
    }

    render(monthOrQuarter, year) {
        const { expenseBudgetData, eventExpenseBudgetData, yoyData, headwiseExpensePage, yoyPage, eventHeadwiseExpensePage, yoyEventPage} = this.state;
        const { userDetails } = this.props;
        const isAdmin = userDetails.role === roles.ADMIN;
        return(
            <div className="container-fluid expense-container">
                <div ref={this.tooltipRef} className="tooltip"></div>
                                                
                <div className="row expense-dropdowns flex">
                {isAdmin && <div className="row expense-upload-div">
                        <select className="select special-select"
                            value={year}
                            onChange={(event) => {
                                this.year = event.target.value;
                                this.setState({
                                    year: this.year
                                })
                            }}
                        >
                            {
                                Array.from({ length: 3 }, (v, k) =>
                                    today.getFullYear() - k
                                ).map((year, index) => <option key={`year-${index}`} value={year}>{year}</option>)
                            }
                        </select>
                    </div>
    }
                    &nbsp;
                    &nbsp;
                    {isAdmin &&  <div className="row expense-upload-div">

                        <select className="select special-select"
                            value={monthOrQuarter}
                            onChange={(event) => {
                                this.monthOrQuarter = event.target.value;
                                this.setState({
                                    monthOrQuarter: this.monthOrQuarter
                                })
                            }}
                        >

                            <optgroup label="Months">
                                {
                                    months.map(month => <option key={month} value={month.toLowerCase()}>{month}</option>)
                                }
                            </optgroup>
                            <optgroup label="Quarters">
                                {
                                    quarters.map(quarter => <option key={quarter} value={quarter}>{quarter}</option>)
                                }
                            </optgroup>
                        </select>
                    </div>
    }
                    &nbsp;
                    &nbsp;
                    {isAdmin &&
                    <div className="row expense-upload-div">
                        <SimpleUploadComponent
                            uploadUrl={`${Constants.expensesBaseURL}${Constants.uploadExpenseDate}` + `?year=` + this.year + '&month=' + this.monthOrQuarter}
                            image={{
                                src: UploadIcon,
                                width: 20,
                                height: 20
                            }}
                            label="UPLOAD" />
                    </div>
    }
                    &nbsp;
                    &nbsp;
                    {isAdmin &&

                    <div className="col-sm-1" width="100%" >
                        <Tooltip title="Download" >
                            <img className="float-right" src={DownloadIcon} width="38px" height="38px" onClick={() => {
                                this.download();
                            }} />
                        </Tooltip>
                    </div>
    }                 
                                            
                    <Filters
                        onSearch={(newQueryParams)=>{
                            this.queryParams = newQueryParams;
                            this.fetchAllData();
                        }}
                        userDetails={userDetails}
                    />
                </div>
                <div className="row budget-details-container">
                    <div className="col-sm-12 dashboard-cell-1 ">
                        <BudgetDetails
                            budget={yoyData.budget}
                            expense={yoyData.expense}
                            mouseover={()=>this.mouseover()}
                            mousemove={(html)=>this.mousemove(html)}
                            mouseleave={()=>this.mouseleave()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 top-left  dashboard-cell-1">
                        <HeadwiseExpense
                            data={expenseBudgetData.slice((headwiseExpensePage-1)*maxBars, headwiseExpensePage*maxBars)}
                            page={headwiseExpensePage}
                            leftDisabled={headwiseExpensePage === 1}
                            rightDisabled={headwiseExpensePage === Math.ceil(expenseBudgetData.length/maxBars)}
                            onLeftClick={()=>{
                                this.changePage('headwiseExpensePage', -1);
                            }}
                            onRightClick={()=>{
                                this.changePage('headwiseExpensePage', 1);
                            }}
                            mouseover={()=>this.mouseover()}
                            mousemove={(html)=>this.mousemove(html)}
                            mouseleave={()=>this.mouseleave()}
                        />
                    </div>
                    <div className="col-sm-6 top-right dashboard-cell-2">
                        <ExpenseYOY
                            data={expenseBudgetData.slice((yoyPage-1)*maxPoints, yoyPage*maxPoints)}
                            page={yoyPage}
                            leftDisabled={yoyPage === 1}
                            rightDisabled={yoyPage === Math.ceil(expenseBudgetData.length/maxPoints)}
                            onLeftClick={()=>{
                                this.changePage('yoyPage', -1);
                            }}
                            onRightClick={()=>{
                                this.changePage('yoyPage', 1);
                            }}
                            mouseover={()=>this.mouseover()}
                            mousemove={(html)=>this.mousemove(html)}
                            mouseleave={()=>this.mouseleave()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 top-left  dashboard-cell-1">
                        <EventHeadExpense
                            data={eventExpenseBudgetData.slice((eventHeadwiseExpensePage-1)*maxBars, eventHeadwiseExpensePage*maxBars)}
                            page={eventHeadwiseExpensePage}
                            leftDisabled={eventHeadwiseExpensePage === 1}
                            rightDisabled={eventHeadwiseExpensePage === Math.ceil(eventExpenseBudgetData.length/maxBars)}
                            onLeftClick={()=>{
                                this.changePage('eventHeadwiseExpensePage', -1);
                            }}
                            onRightClick={()=>{
                                this.changePage('eventHeadwiseExpensePage', 1);
                            }}
                            mouseover={()=>this.mouseover()}
                            mousemove={(html)=>this.mousemove(html)}
                            mouseleave={()=>this.mouseleave()}
                        />
                    </div>
                    <div className="col-sm-6 top-right dashboard-cell-2">
                        <EventExpenseYOY
                            data={eventExpenseBudgetData.slice((yoyEventPage-1)*maxPoints, yoyEventPage*maxPoints)}
                            page={yoyEventPage}
                            leftDisabled={yoyEventPage === 1}
                            rightDisabled={yoyEventPage === Math.ceil(eventExpenseBudgetData.length/maxPoints)}
                            onLeftClick={()=>{
                                this.changePage('yoyEventPage', -1);
                            }}
                            onRightClick={()=>{
                                this.changePage('yoyEventPage', 1);
                            }}
                            mouseover={()=>this.mouseover()}
                            mousemove={(html)=>this.mousemove(html)}
                            mouseleave={()=>this.mouseleave()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 dashboard-cell-1">
                        <BudgetExceeded
                            data={yoyData.overBudget}
                        />
                    </div>
					<div className="col-sm-4 dashboard-cell-2">
                        <NearBudget
                            data={yoyData.nearBudget}
                        />
                    </div>
					<div className="col-sm-4 dashboard-cell-2">
                        <UnderUtilized
                            data={yoyData.underBudget}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return({
        userDetails: state.userDetails
    })
}

export default connect(mapStateToProps)(Expense);

Expense.defaultProps = {
    userDetails: {}
}