import React, { Fragment } from 'react';
import Search from '../../../images/icon-search.png';

export default class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.searchRef = React.createRef();
    }
    render() {
        const { placeholder, onSearch, dimension, onChange } = this.props;
        return (
            <Fragment>
                <input ref={this.searchRef} type="text" className="txt form-control" placeholder={placeholder}
                    onChange={(event)=>{
                        onChange(event.target.value);
                    }}
                />
                <div className="input-group-append">
                    <button onClick={() => {
                        const value = this.searchRef.current.value;
                        onSearch(value);
                    }} className="btn search-button" type="submit"><img src={Search} alt="search" width={dimension} height={dimension} /></button>
                </div>
            </Fragment>
        )
    }
}

SearchBox.defaultProps = {
    onSearch: ()=>{},
    dimension: 20,
    onChange: ()=>{}
}