import React from 'react';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import DollarIcon from '../../../../images/icon-dollar.png';
import PercentIcon from '../../../../images/icon-percent.png';
import './ExpenseHeadTable.css';
import { CustomTextField, numberWithComas, WheatSwitch } from '../../../util/Constants';
import { Select, FormControl, MenuItem, Input, withStyles, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import Active from '../../../../images/icon-active.png';
import Disabled from '../../../../images/icon-disabled.png';

const columns = [
    {
        label: 'Expense Head',
        nosort: true,
        key: 'headName'
    },
    {
        label: 'Amount',
        nosort: true,
        key: 'budget'
    },
{
        label: 'Active',
        nosort: true,
        key: 'active'
}
]

export default class ExpenseHeadTable extends React.Component {
    render() {
        const { editable, data, onChange } = this.props;
        return (
            <div className="col-sm-12">
                <div className="listing-grid table-responsive table-container">
                    <table className="table expense-head-table">
                        <thead>
                            <tr>
                                <TableHeaders
                                    columns={columns}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((row, index)=>{
                                    const isDollar = row.amountType === 'flat';
                                    const icon = isDollar ? DollarIcon : PercentIcon;
                                    return(
                                        <tr key={index}>
                                            <td>
                                                {row.headName}
                                            </td>
                                            <td className="amount-column">
                                                { !editable ?
                                                    numberWithComas(row.budget, '') :
                                                    <CustomTextField
                                                        type="number"
                                                        value={row.budget}
                                                        onChange={event=>{
                                                            data[index] = {
                                                                ...row,
                                                                budget: event.target.value
                                                            }
                                                            onChange(data, index)
                                                        }}
                                                    />
                                                }
                                                <Tooltip title="Click to change amount type">
                                                    <img src={icon} width="25px" height="25px" onClick={_=>{
                                                        const newAmountType = isDollar ? 'percent' : 'flat';
                                                        data[index] = {
                                                            ...row,
                                                            amountType: newAmountType
                                                        }
                                                        onChange(data, index)
                                                    }} />
                                                </Tooltip>
                                            </td>
                                            <td>
                                            <FormControl className="select-input">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <WheatSwitch
                                                                                defaultChecked={JSON.parse(row.status)}
                                                                                color="default"
                                                                                onChange={(event) => {
                                                                                          data[index] = {
                                                                                               ...row,
                                                                                               status: event.target.checked
                                                                                            }
                                                                                    onChange(data, index)
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={JSON.parse(row.status) ? "Active\u00A0\u00A0 " : "Inactive"}
                                                                        className="status-switch"
                                                                    />
                                            <img src={JSON.parse(row.status) ? Active : Disabled} width="30px" height="30px" alt="status" className="status-icon" />
                                            </FormControl>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

ExpenseHeadTable.defaultProps = {
    editable: true,
    data: [],
    onChange: ()=>{}
}