import React from 'react';
import USA from '../../../util/MapData/us-states.json';
import * as d3 from 'd3';
import { connect } from 'react-redux';
import { showLoader } from '../../../../redux/actions';
import { numberWithComas } from '../../../util/Constants';

class Map extends React.Component {

    constructor(props){
        super(props);
        this.tooltipRef = React.createRef();
        this.chartRef = React.createRef();
        this.scales = null;
    }

    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate(prevProps) {
        const { data, min, max } = this.props;
        if(JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.drawChart();
        }
        else if(min !== prevProps.min || max !== prevProps.max) {
            this.redrawCircles();
        }
    }

    getColor(value) {
        if(value > 100000) {
            return 100000;
        } else if(value < 0) {
            return -1;
        } else {
            return value;
        }
    }

    redrawCircles() {
        const { max, min, dispatch, sliderPosition } = this.props;
        dispatch(showLoader(true));
        const colors = d3.scaleSequential().domain([sliderPosition,-1])
                            .interpolator(d3.interpolateRdYlGn);
        const circles = d3.select(this.chartRef.current)
                            .selectAll('circle')
        circles.attr('display', "block")
        circles.filter(d=>d > max)
            .attr('display', 'none');
        circles.filter(d=> d <= max)
            .attr('fill', d=>colors(this.getColor(d)))
            .attr('stroke', d=>colors(this.getColor(d)))
        // redraw scale
        if(this.scales) {
            this.scales.selectAll('text').remove();
            const step = Math.abs(sliderPosition)/20;
            const colorRects = d3.range(max, 0, -step);
            this.scales.data(colorRects)
                .append('text')
                .text(d=>Math.round(d))
                .attr('x', 15)
                .attr('y', 10)
                .attr('stroke', 'white')
                .attr('font-size', '9px')
        }
        dispatch(showLoader(false));
    }

    drawChart() {

        const { data, max, min, sliderPosition } = this.props;
        const chart = this.chartRef.current;
        const tooltip = d3.select(this.tooltipRef.current);
        const width = 960;
        const height = 500;

        // clean the board
        d3.select(chart).selectAll('svg').remove();

        // D3 Projection
        var projection = d3.geoAlbersUsa()
                            .translate([width/2, height/2])
                            .scale([1000]);

        // Define path generator
        var path = d3.geoPath().projection(projection);

        const svg = d3.select(chart)
                        .append('svg')
                        .attr("preserveAspectRatio", "xMinYMin meet")
                        .attr('viewBox', `0 0 ${width} ${height}`)
        
        svg.selectAll('path')
            .data(USA.features)
            .enter()
            .append('path')
            .attr('d', data => {
                const centroid = path.centroid(data);
                if(isNaN(centroid[0]) || isNaN(centroid[1])) {
                    return;
                }
                svg.append('text')
                    .attr('x', centroid[0])
                    .attr('y', centroid[1])
                    .html(data.properties.code)
                    .style('fill', 'blue')
                    .attr("text-anchor","middle")
                    .attr('font-size','6pt')
                return path(data)
            })
            .style('fill', 'gainsboro')
            // .style("stroke", "#fff")
            // .style("stroke-width", "1")
        
        const mouseover = ()=>{
            tooltip.style('opacity', 1);
        }

        const mouseleave = ()=>{
            tooltip.style('opacity', 0);
        }

        const mousemove = (d)=>{
            const { zipCode, totalAmount, state } = d;
            tooltip.html(`
                <div style="text-align: center; font-weight: 600;">
                    ${zipCode} (${state}) ${numberWithComas(totalAmount, '$')}
                </div>
            `)
            const styles = tooltip.node().getBoundingClientRect();
            tooltip.style('left', `${d3.event.x + 5}px`).style('top', `${d3.event.y - styles.height-5}px`)
        }

        tooltip.on('mouseover', (d)=>{
            tooltip.style('left', 0);
        })

        // const colors = d3.scaleLinear()
        //                     .domain([min, max])
        //                     .range(['white', 'red']);

        // var colors = d3.scaleQuantize()
        //                 .domain([d3.min(amounts), d3.max(amounts)])
        //                 .range(["#5E4FA2", "#3288BD", "#66C2A5", "#ABDDA4", "#E6F598", 
        //                 "#FFFFBF", "#FEE08B", "#FDAE61", "#F46D43", "#D53E4F", "#9E0142"]);

        const colors = d3.scaleSequential().domain([sliderPosition,0])
                            .interpolator(d3.interpolateRdYlGn);


        data.forEach(city=>{
            try {
                const coordinates = projection([city.longitude, city.latitude])
                const fill = colors(this.getColor(city.totalAmount));
                svg.append('circle')
                    .attr('cx', coordinates[0])
                    .attr('cy', coordinates[1])
                    .attr('r', 5)
                    .attr('fill', fill)
                    .attr('fill-opacity', "25%")
                    .attr('stroke', fill)
                    .attr('stroke-width', 0.1)
                    .datum(city.totalAmount)
                    .on("mouseover", mouseover)
                    .on("mousemove", ()=>{mousemove(city)})
                    .on("mouseleave", mouseleave)
            } catch(error) {
            }
        })

        // const coordinates = projection([-67.13422,18.448619]);
        // console.log(coordinates)
        // svg.append('circle')
        //     .attr('cx', coordinates[0])
        //     .attr('cy', coordinates[1])
        //     .attr('r', 10)
        //     .attr('fill', 'blue')

        const step = Math.abs(sliderPosition/20);
        const colorRects = d3.range(sliderPosition, 0, -step);
        const scales = svg.selectAll(".rects")
                        .data(colorRects)
                        .enter()
                        .append('g')
                        .attr('transform', (d,i)=>`translate(10, ${10+i*18})`)

        scales.append("rect")
        .attr("height", 15)
        .attr("width", 10)
        .attr("fill", d=>colors(d))
        .attr("stroke", "gray")

        scales.append('text')
        .attr('x', 15)
        .attr('y', 10)
        .text(d=>Math.round(d))
        .attr('stroke', 'white')
        .attr('font-size', '9px')

        this.scales = scales;
            
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.tooltipRef} className="tooltip"></div>
                <div ref={this.chartRef}></div>
            </React.Fragment>
        )
    }
}

Map.defaultProps = {
    data: [],
    max: 0,
    min: 0,
    sliderPosition: 100000
}

export default connect()(Map)