import React from 'react';
import SearchBox from './SearchBox/SearchBox';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import TableBody from '../../ZipReportComponent/TableBody';
import Constants, { toastOptions, downloadFile } from '../../../util/Constants';
import AddAndSaveButtons from '../AddAndSaveButtons';
import Popup from '../../Popup/Popup';
import { toast } from 'react-toastify';
import UploadIcon from '../../../../images/icon-upload.ico';
import DownloadIcon from '../../../../images/icon-save.png';
import Button from '../../ZipReportComponent/Button';
import { connect } from 'react-redux';
import { showLoader } from '../../../../redux/actions';
import SimpleUploadComponent from '../../SimpleUploadComponent/SimpleUploadComponent';

const columns = [
    {
        label: 'Distributor',
        key: 'distributor',
        nosort: true,
        type: 'enum'
    },
    {
        label: 'Manufacturer',
        key: 'mfg',
        nosort: true,
        type: 'enum'
    }
]

class DistMfgMapper extends React.Component {

    static count = 0;

    constructor(props) {
        super(props);
        this.newData = []
        this.state = {
            manufacturers: [],
            distributors: [],
            data: [],
            showPopup: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData(searchParams = {}) {
        let params = '';
        const searchString = Object.keys(searchParams).reduce((total, current)=>{
            if(!searchParams[current]) {
                return total;
            }
            return `${total}&${current}=${searchParams[current]}`
        }, '');
        const url = `${Constants.baseURL}${Constants.getDistMfgMappings}?${searchString}`
        fetch(url)
        .then(response=>{
            if(response.status !== 200) {
                throw new Error()
            }
            return response.json()
        })
        .then(response=>{
            this.setState({
                data: response.response
            })
        }).catch(error=>{
            console.log(error);
        })
    }

    addRow() {
        const { data } = this.state;
        const dummyrow = {
            distributor: "",
            id: `temp${DistMfgMapper.count++}`,
            mfg: ""
        }
        data.push(dummyrow);
        this.setState({
            data
        })
    }

    deleteRow() {
        const { data } = this.state;
        const id = this.idToDelete;
        const deleteRow = ()=>{
            const newData = data.filter(row=>row.id !== id);
            this.newData = this.newData.filter(row=>row.id !== id)
            this.setState({
                data: newData
            })
        }
        if(String(id).startsWith('temp')) {
            deleteRow()
            return;
        }

        const url = `${Constants.baseURL}${Constants.deleteDistMfgMapping.replace('{id}', id)}`;
        fetch(url, {
            method: 'DELETE'
        }).then(response=>{
            if(response.status !== 204) {
                throw new Error()
            }
            toast.success('Success: Delete operation success', toastOptions);
            deleteRow();
        }).catch(error=>{
            toast.error('Error: Delete operation failed', toastOptions);
            console.log(error);
        })
    }

    saveData() {
        if(JSON.stringify(this.newData) === '[]')
            return;
        const url = `${Constants.baseURL}${Constants.createDistMfgMapping}`;
        this.newData.forEach(row=>delete row.id);
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(this.newData),
            headers: {
                'Content-type': 'application/json'
            }
        }).then(response=>{
            console.log(response)
        }).catch(error=>{
            console.log(error)
        }).finally(()=>{
            this.fetchData();
        })
    }

    closePopup(args = ()=>{}) {
        this.setState({
            showPopup: false
        })
        args();
    }

    render() {
        const { manufacturers, distributors, data, showPopup } = this.state;
        const { dispatch, editable } = this.props;
        return(
            <div className="container-fluid">
                { showPopup &&
                    <Popup
                        onYes={()=>{
                            this.closePopup(this.deleteRow())
                        }}
                        onNo={()=>{
                            this.closePopup();
                        }}
                    />
                }
                <div className="row">
                    <SearchBox
                        onFetchComplete={(data)=>{
                            this.setState({
                                manufacturers: data.manufacturers || [],
                                distributors: data.distributors || []
                            })
                        }}
                        onSearch={(searchParams)=>{
                            this.fetchData(searchParams)
                        }}
                    />
                    <div className="col-sm-3">
                       <div className="row zip-update-button-container">
                             <SimpleUploadComponent
                                 uploadUrl={`${Constants.baseURL}${Constants.uploadDistMfgMapping}`}
                                 image={{
                                     src: UploadIcon,
                                     width: 20,
                                     height: 20
                                 }}
                                 label="UPLOAD"
                                 disabled={!editable}
                             />
                             <Button
                                 onClick={() => {
                                     const url = `${Constants.baseURL}${Constants.downloadDistMfgMapping}`;
                                     dispatch(showLoader(true));
                                     downloadFile(url, "DistMfgMapping", "xlsx")
                                         .finally(()=>{
                                             dispatch(showLoader(false))
                                         })
                                 }}
                                 icon={DownloadIcon}
                                 label='Download'
                                 dimension='20'
                             />
                       </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="listing-grid table-responsive table-container mfg-lookup-table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <TableHeaders
                                            columns={columns}
                                        />
                                        {editable && <th className="history-table-header" scope="col" >Action</th >}
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableBody
                                        columns={(()=>{
                                            columns[0].enum = {
                                                label: 'Select Distributor',
                                                values: distributors
                                            }
                                            columns[1].enum = {
                                                label: 'Select Manufacturer',
                                                values: manufacturers
                                            }
                                            return columns;
                                        })()}
                                        data={data}
                                        noedit={true}
                                        deleteHistory={(id)=>{
                                            this.idToDelete = id;
                                            this.setState({
                                                showPopup: true
                                            })
                                        }}
                                        hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                                            if(String(currentRow.id).startsWith('temp')) {
                                                resultArray.push(index);
                                            }
                                            return resultArray;
                                        }, [])}
                                        onDataChange={(newData)=>{
                                            this.newData = newData;
                                        }}
                                        disableActions={!editable}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={()=>{
                        this.addRow();
                    }}
                    onSaveClick={()=>{
                        this.saveData();
                    }}
                    disabled={!editable}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('MASTER')
})

export default connect(mapStateToProps)(DistMfgMapper);