import React from 'react';
import TableHeaders from '../../../ZipReportComponent/TableHeaders';
import TableBody from '../../../ZipReportComponent/TableBody';
import TableFooter from '../../../ZipReportComponent/TableFooter';
import Constants from '../../../../util/Constants';

const columns = [
    {
        label: 'Manufacturer',
        nosort: true,
        key: 'mfgName',
        type: 'enum',
        enum: {
            label: 'Choose Manufacturer',
            values: []
        }
    },
    {
        label: 'Distributor',
        nosort: true,
        key: 'distributor',
        type: 'enum',
        enum: {
            label: 'Choose Distributor',
            values: []
        }
    },
    {
        label: 'Territory',
        nosort: true,
        key: 'territory',
        type: 'enum',
        enum: {
            label: 'Choose Territory',
            values: []
        }
    },
    {
        label: 'Percentage',
        nosort: true,
        key: 'percentage',
        withComas: true,
        prefix: ''
    },
    {
        label: 'LastYearSalesAmount',
        nosort: true,
        key: 'lastYearSalesAmount',
        withComas: true,

    },
    {
        label: 'Target Revenue',
        nosort: true,
        key: 'targetRevenue',
        withComas: true,
        prefix: '$ '
    },
    {
        label: 'Year',
        nosort: true,
        key: 'year',
        type: 'enum',
        enum: {
            label: 'Choose Year',
            values: Array.from({length: 10}, (v,k)=>2019+k)
        }
    }
]

export default class GoalTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns
        }
    }

    componentDidMount() {
        this.fetchManufacturers();
        this.fetchDistributores();
        this.fetchTerritory();
    }

    fetchManufacturers() {
        const url = `${Constants.baseURL}${Constants.getManufacturers}`;
        fetch(url)
            .then(response=>response.json())
            .then(data=>{
                const manufacturers = data.map(manufacturer=>manufacturer.mfgName);
                columns[0].enum.values = manufacturers;
                this.setState({
                    columns
                })
            })
            .catch(error=>console.log(error));
    }

    fetchDistributores() {
        const url = `${Constants.baseURL}${Constants.getHQFilterDistributors}`;
        fetch(url)
            .then(response=>response.json())
            .then(data=>{
                const distributor = data.map(distributor=>distributor);
                columns[1].enum.values = distributor;
                this.setState({
                    columns
                })
            })
            .catch(error=>console.log(error));
    }

    fetchTerritory() {
        const url = `${Constants.baseURL}${Constants.getAllTerritories}`;
        fetch(url)
            .then(response=>response.json())
            .then(data=>{
                const territory = data.map(territory=>territory.territoryCode);
                columns[2].enum.values = territory;
                this.setState({
                    columns
                })
            })
            .catch(error=>console.log(error));
    }

    render() {
        const { goals, repNames, onDataChange, deleteRow, totalPages, currentPage, changePage, editable, resetEditSwitch } = this.props;
        const { columns } = this.state;
        return( 
            <div className="listing-grid table-responsive table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <TableHeaders columns={columns} />
                            {editable && <th className="history-table-header">Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody
                            columns={(()=>{
                                //columns[2].enum.values = repNames
                                return columns;
                            })()}
                            data={goals}
                            onDataChange={(newData)=>{
                                onDataChange(newData)
                            }}
                            hiddenEditRowIndexes={goals.reduce((resultArray, currentRow, index)=>{
                                if(String(currentRow.goalId).startsWith('temp')) {
                                    resultArray.push(index);
                                }
                                return resultArray;
                            }, [])}
                            deleteHistory={deleteRow}
                            sendObjectOnDelete={true}
                            disableActions={!editable}
                            resetEditSwitch={resetEditSwitch}
                            readOnlyFields={[4,5]}
                        />
                    </tbody>
                    <tfoot>
                        <TableFooter
                            totalPages={totalPages}
                            page={currentPage}
                            changePage={(index)=>{
                                changePage(index);
                            }}
                        />
                    </tfoot>
                </table>
            </div>
        )
    }
}

GoalTable.defaultProps = {
    goals: [],
    repNames: [],
    onDataChange: () => {},
    deleteRow: () => {},
    totalPages: 0,
    currentPage: 0,
    changePage: ()=>{},
    editable: true
}