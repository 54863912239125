import * as React from "react";
import {ClipLoader} from "react-spinners";

export default class LoadingComponent extends React.Component {

    render() {
        return (<div className="loading">
            <div className='spinner-loader'>
                <ClipLoader
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={true}
                />
            </div>
        </div>);
    }
}