import { combineReducers } from 'redux';
import showLoader from './loader';
import userDetails from './user';
import privileges from './privileges';

// This method is the root reducer. This method combines all other sub reducers
export default combineReducers({
    showLoader,
    userDetails,
    privileges
})