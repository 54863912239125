import React from 'react';
import HorizontalBarChart from './HorizontalBarChart/HorizontalBarChart';
import './Dashboard.css';
import '../../../index.css'
import Constants, { downloadFile, numberWithComas, roles } from '../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../redux/actions';
import Speedometer from './Speedometer/Speedometer';
import DashboardTable from './DashboardTable/DashboardTable';
// import Leaderboard from './Leaderboard/Leaderboard';
import Leaderboard from './LeaderboardV2/LeaderboardV2';
import YTD from './YTD/YTD';
import $ from 'jquery';
import DownloadIcon from '../../../images/icon-fileDL.png';
import ArrowIcon from '../../../images/icon-right-arrow.png';
import { Tooltip,FormControl, FormControlLabel } from '@material-ui/core';
import DropDown from '../MasterComponent/DistRepMapper/DropDown/DropDown';
import Button from '../ZipReportComponent/Button';
import SearchIcon from '../../../images/icon-search.png'
import LeadershipGraph from './LeadershipLineGraph';
import * as d3 from 'd3';
import { WheatSwitch } from '../../util/Constants';
import Multiselect from '../MultiselectComponents/Multiselect';

//const colors = ['rgb(253, 234, 111)', '#98dac6', 'rgb(53, 209, 253)', 'rgb(56, 150, 198)', '#67819d', 'purple', 'rgb(249, 151, 149)'];
//const colors = ['#85a63d', '#1777c5', '#f24f2c', '#ce4545', '#00acc8', '#d1c782', '#c7bcd1'];
//const colors = ['#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43','#488f31' ,'#83af70', '#bad0af','#f1f1f1','#f0b8b8','#e67f83','#de425b',];
const colors = ['#bad0af', '#f1f1f1', '#f0b8b8', '#e67f83', '#de425b', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#488f31', '#83af70',];

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
const today = new Date();


class Dashboard extends React.Component {

    static loading = []

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.tooltipRef = React.createRef();
        if (today.getMonth()===0) {
            this.year = today.getFullYear()-1;
            this.monthOrQuarter = months[11].toLowerCase();
        } else {
            this.year = today.getFullYear();
            this.monthOrQuarter = months[today.getMonth()-1].toLowerCase();
        }
        this.isMonth = false;
        this.ytdLabel = this.monthOrQuarter;
        this.type = '';
        // initialize user name with current user name
        const { userDetails } = props;
        const name = userDetails.name.split(" ");
        this.selectedUserName = `${name[1]}, ${name[0]}`;
        this.currentUserName = this.selectedUserName;
        this.currentUserRole = userDetails.role;
        this.selectedUserRole = this.currentUserRole;
        this.hqfilter = true;
		this.isKeyUser = userDetails.role === roles.KEY;
        this.isKeyDir = userDetails.role === roles.KEY_DIR;
        this.isKey = (this.isKeyUser||this.isKeyDir);
        this.state = {
            chartData: [],
            kpiData: {},
            leaders: [],
            topPerformers: [],
            ytdData: [],
            leadershipGraphData: [],
            year: this.year,
            monthOrQuarter: this.monthOrQuarter,
            horizontalBarPage: 1,
            verticalBarPage: 1,
            manager: '',
			hqfilter: this.hqfilter,
			isKeyUser: this.isKeyUser,
            isKeyDir:this.isKeyDir,
            isKey:this.isKey
        }
    }

    componentDidMount() {
		if (this.isKeyDir) {
			this.selectedUserName ='';
		}
        this.fetchSalesData();
		this.fetchYtdData();
		if (!this.isKey) {
          this.fetchKpiData();
          this.fetchLeaderboard();
		}
        // this.resizeWindow();
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.on("mouseover", _ => {
            Tooltip.style('left', 0)
        })
    }

    mouseover() {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.style("opacity", 1)
    }

    mousemove(html) {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.html(html)
        const styles = Tooltip.node().getBoundingClientRect();
        Tooltip.style('left', `${d3.event.x + 5}px`).style('top', `${d3.event.y - styles.height - 5}px`)
    }

    mouseleave() {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.style("opacity", 0)
    }

    /**
     * Function to show or hide loader
     * @param {boolean} load - show or hide loader
     */
    showLoader(load = false) {
        const { dispatch } = this.props;
        if (load) {
            Dashboard.loading.push(load);
            dispatch(showLoader(true));
        } else {
            Dashboard.loading.pop();
        }
        if (Dashboard.loading.length === 0) {
            dispatch(showLoader(false));
        }
    }

    resizeWindow() {
        $(window).resize(() => {
            const container = this.containerRef.current;
            const width = $(container).width();
            const height = $(container).height();
            console.log(width);
            if (width < 1000) {
                console.log("applying zoom")
                $(container).animate({ 'zoom': 0.5 }, 1000);
            }
        })
    }

    fetchYtdData() {
        const url = `${Constants.baseURL}${Constants.dashboardGetYTD}${this.getQueryParamString()}`;
        this.showLoader(true);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    ytdData: data
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.showLoader(false);
            })
    }

    fetchLeadershipGraphData(manufacturer, distributor, types, states, reps, hqfilter) {
        if(hqfilter===undefined){
            hqfilter=true;
        }
        let queryParams = ''
        queryParams += `role=${this.currentUserRole}&hqfilter=${hqfilter}`
        if (manufacturer) {
            queryParams += `${queryParams ? '&' : ''}manufacture=${manufacturer}`
        }
        if (distributor) {
            queryParams += `${queryParams ? '&' : ''}distributor=${encodeURIComponent(distributor)}`
        }
        if (types) {
            queryParams += `${queryParams ? '&' : ''}type=${types}`
        }
        if (states) {
            queryParams += `${queryParams ? '&' : ''}state=${states}`
        }
        if (reps) {
            queryParams += `${queryParams ? '&' : ''}repName=${reps}`
        }
        if(this.currentUserRole=='SalesManager' && !reps){
            queryParams += `${queryParams ? '&' : ''}manager=${this.currentUserName}`
        }
        if(this.currentUserRole=='KeyDirector' && !reps){
            queryParams += `${queryParams ? '&' : ''}manager=Key`
        }
        if(this.currentUserRole=='SalesRep' && !reps){
            queryParams += `${queryParams ? '&' : ''}repName=${this.currentUserName}`
        }
        if(this.currentUserRole=='Key' && !reps){
            queryParams += `${queryParams ? '&' : ''}repName=${this.currentUserName}`
        }

        const url = `${Constants.baseURL}${Constants.getLeadershipGraphData}${queryParams ? `?${queryParams}` : ''}`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    leadershipGraphData: data
                })
            }).catch(error => {
                console.log(error);
            })
    }

    /**
     * Function to return query parameters for get request
     * 
     * @param {boolean} yearOnly - returns only year if this is true
     */
    getQueryParamString(yearOnly = false) {
        const { year, monthOrQuarter, type } = this;
        let url = `?year=${year}&type=${type}`;
        if(months.map(month=>month.toLowerCase()).includes(monthOrQuarter.toLowerCase())) {
            url = `${url}&month=${monthOrQuarter}`;
            this.isMonth = true;
        } else {
            url = `${url}&quarter=${monthOrQuarter}`;
            this.isMonth = false;
        }
        this.ytdLabel = monthOrQuarter;
        if (yearOnly) {
            return url;
        }
        return `${url}&repName=${this.selectedUserName}&role=${this.selectedUserRole}&hqfilter=${this.hqfilter}`
    }

    fetchSalesData() {
        const url = `${Constants.baseURL}${Constants.getDashboardData}${this.getQueryParamString()}`;
		this.showLoader(true);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const chartData =
                    data.map((manufacturer, index) => ({
                        name: manufacturer.mfgName,
                        bars: [
                            {
                                value: manufacturer.targetRevenue,
                                fill: 'gainsboro'
                            },
                            {
                                value: manufacturer.achievedRevenue,
                                fill: colors[index % colors.length]
                            }
                        ]
                    }));
                const topPerformers = data.sort((a, b) => b.achievedRevenue - a.achievedRevenue).slice(0, 3);
                this.setState({
                    chartData,
                    topPerformers
                })
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.showLoader(false);
            })
    }

    /**
     * Function to fetch kpi dashboard data
     */
    fetchKpiData() {
        const url = `${Constants.baseURL}${Constants.dashboardGetKPI}${this.getQueryParamString()}`
        this.showLoader(true);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    kpiData: data
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.showLoader(false);
            })
    }

    fetchLeaderboard() {
        const url = `${Constants.baseURL}${Constants.dashboardGetLeaderboard}${this.getQueryParamString(true)}`;
        this.showLoader(true);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    leaders: data
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.showLoader(false)
            });
    }

    /**
     * Function to supply data to first table
     */
    getTable1Data() {
        const { kpiData } = this.state;
        if (JSON.stringify(kpiData) === '{}') {
            return []
        }
        const data = [
            {
                c1: {
                    value: 'TOTAL TARGET',
                    style: {
                        fontWeight: 600,

                        //fontFamily:'Montserrat',
                        //color: 'white',
                        paddingLeft: '10px',
                        paddingRight: '20px',
                        paddingBottom: '20px',
                        // background: 'rgba(78, 80, 84, 0.466)'
                    }
                },
                c2: {
                    value: numberWithComas(kpiData.totalTarget, '$ '),
                    style: {
                        fontWeight: 600,
                        fontSize: '24px',
                        color: '#0f8ffe',
                        paddingRight: '10px',
                        paddingBottom: '20px',
                        // background: 'rgba(78, 80, 84, 0.466)'
                    }
                }
            },
            {
                c1: {
                    value: 'WON',
                    style: {
                        fontWeight: 600,
                        //color: 'white',
                        paddingLeft: '10px',
                        paddingRight: '20px',
                        paddingBottom: '20px',
                        //background: 'rgba(255, 255, 255, 1)'
                    }
                },
                c2: {
                    value: numberWithComas(kpiData.totalAchieved, '$ '),
                    style: {
                        fontWeight: 600,
                        fontSize: '24px',
                        color: '#a6cf4b',
                        paddingRight: '10px',
                        paddingBottom: '20px',
                        //background: 'rgba(255, 255, 255, 1)'
                    }
                }
            },
            {
                c1: {
                    value: 'TO GO',
                    style: {
                        fontWeight: 600,
                        //color: 'black',
                        paddingLeft: '10px',
                        paddingRight: '20px',

                        //background: 'rgba(78, 80, 84, 0.466)'
                    }
                },
                c2: {
                    value: numberWithComas(kpiData.totalRemaining, '$ '),
                    style: {
                        fontWeight: 600,
                        fontSize: '24px',
                        color: '#fe7804',
                        paddingRight: '10px',
                        //background: 'rgba(78, 80, 84, 0.466)'
                    }
                }
            }
        ]
        return data;
    }

    getTable2Data() {
        const { topPerformers } = this.state;
        const data = [
            /* {
                 c1: {
                     value: 'TOP 3 PERFORMING',
                     style: {
                         fontWeight: 600,
                         //color: 'black',
                         paddingLeft: '10px',
                         paddingRight: '20px',
                         //background: 'rgba(78, 80, 84, 0.466)',
                         textAlign: 'center'
                     }
                 }
             }*/
        ]
        for (let index = 0; index < topPerformers.length && index < 3; index++) {
            data.push({
                c1: {
                    value: index + 1 + ".   " + topPerformers[index].mfgName,
                    style: {
                        /*color: 'black',
                        textAlign: 'center',
                        background: index%2 ? 'rgba(78, 80, 84, 0.466)' : 'white',*/

                        fontSize: '20px',
                        color: '#0ec8f0',
                        paddingRight: '10px',
                        paddingBottom: '10px',

                    }
                }
            })
        }
        return data;
    }

    downloadMySales() {
        const url = `${Constants.baseURL}${Constants.downloadMySales}${this.getQueryParamString()}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));

        downloadFile(url, "my-sales", "xlsx")
            .finally(() => {
                dispatch(showLoader(false));
            })
    }

    downloadMonthlyTrends(rep, hqfilter) {
        if(hqfilter===undefined){
            hqfilter=true;
        }
        let queryParams = ''
        queryParams += `role=${this.currentUserRole}&hqfilter=${hqfilter}`
        if (rep) {
            queryParams += `${queryParams ? '&' : ''}repName=${rep}`
        }
        if(this.currentUserRole=='SalesManager' && !rep){
            queryParams += `${queryParams ? '&' : ''}manager=${this.currentUserName}`
        }
        if(this.currentUserRole=='SalesRep' && !rep){
            queryParams += `${queryParams ? '&' : ''}repName=${this.currentUserName}`
        }
        if(this.currentUserRole=='Key' && !rep){
            queryParams += `${queryParams ? '&' : ''}repName=${this.currentUserName}`
        }
        const url = `${Constants.baseURL}${Constants.downloadMonthlyTrends}${queryParams ? `?${queryParams}` : ''}`;
        const { dispatch } = this.props;
        dispatch(showLoader(true));     

        downloadFile(url, "monthly-trends", "xlsx")
            .finally(() => {
                dispatch(showLoader(false));
            })
    }

    refetchData() {
        this.fetchSalesData();
        this.fetchYtdData();
		if (!this.isKey) {
          this.fetchKpiData();
          this.fetchLeaderboard();
		}
    }

    render() {
        const { chartData, kpiData, leaders, monthOrQuarter, year, horizontalBarPage, ytdData, leadershipGraphData, verticalBarPage, manager, type, hqfilter, isKeyUser, isKeyDir, isKey } = this.state;
        const { userDetails } = this.props;
        const horizontalBarCount = chartData.length;
        const verticalBarCount = ytdData.length;
        const isLeadership = userDetails.role === roles.LEADERSHIP;
        const isSalesManager = userDetails.role === roles.SALES_MANAGER;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div ref={this.tooltipRef} className="tooltip"></div>
                    <div className="row">
                        <div className="col-sm-12 dashboard-dropdowns-container">
						    <div className="dashboard-dropdown">
							{isLeadership &&
                                <FormControl className="select-input">
                                    <FormControlLabel
                                        control={
                                            <WheatSwitch
                                                checked={hqfilter}
                                                color="default"
                                                onClick={(event) => {
													this.hqfilter= event.target.checked;
                                                    this.setState({
                                                        hqfilter: event.target.checked
                                                    })
                                                }}
                                            />
                                        }
                                        label='HQ Filter'
                                        className="status-switch"
                                    />
                                </FormControl>
                            }
                            </div>
						    <div className="dashboard-dropdown">
								{ isLeadership &&
                                     <Multiselect
                                     label="All Types"
                                     values={['Key Purch', 'Purch', 'Key POS', 'POS'].map(type => ({
                                         value: type,
                                         id: type
                                     }))}
                                     value={type}
                                     onChange={event => {
                                         this.type = event.target.value.join(',')
                                         this.setState({
                                             type: event.target.value
                                         })
                                     }}
                                     labelStyle={{ marginLeft: '2px', fontSize: '15px' }}
                                     containerStyle={{ border: '1px solid gray', backgroundColor: 'rgba(78, 80, 84, 0.466)', width: 150 }}
                                     inputProps={{
                                         'aria-label': 'Without label',
                                     }}
                                     fullWidth
                                 />
                                }
								{ isKey &&
                                     <Multiselect
                                     label="All Types"
                                     values={['Key Purch', 'Key POS'].map(type => ({
                                         value: type,
                                         id: type
                                     }))}
                                     value={type}
                                     onChange={event => {
                                         this.type = event.target.value.join(',')
                                         this.setState({
                                             type: event.target.value
                                         })
                                     }}
                                     labelStyle={{ marginLeft: '2px', fontSize: '15px' }}
                                     containerStyle={{ border: '1px solid gray', backgroundColor: 'rgba(78, 80, 84, 0.466)', width: 150 }}
                                     inputProps={{
                                         'aria-label': 'Without label',
                                     }}
                                     fullWidth
                                 />
                                }
                            </div>
                            <div className="dashboard-dropdown">
                                {isLeadership &&
                                    <DropDown
                                        label="All Managers"
                                        url={`${Constants.baseURL}${Constants.getManagers}`}
                                        onChange={event => {
                                            this.selectedUserName = event.target.value
                                            this.setState({
                                                manager: event.target.value
                                            })
                                            if (this.selectedUserName === '') {
                                                this.selectedUserName = this.currentUserName;
                                                this.selectedUserRole = this.currentUserRole;
                                            } else if (this.selectedUserName === 'Key') {
                                                this.selectedUserRole = roles.KEY_DIR;
                                                this.selectedUserName = '';												
                                            } else {
                                                this.selectedUserRole = roles.SALES_MANAGER;
                                            }
                                        }}
                                    />
                                }
								{isKey &&
                                <FormControl className="select-input">
                                    <FormControlLabel
                                        control={
                                            <WheatSwitch
                                                checked={hqfilter}
                                                color="default"
                                                onClick={(event) => {
													this.hqfilter= event.target.checked;
                                                    this.setState({
                                                        hqfilter: event.target.checked
                                                    })
                                                }}
                                            />
                                        }
                                        label='HQ Filter'
                                        className="status-switch"
                                    />
                                </FormControl>
                            }
                            </div>
                            <div className="dashboard-dropdown">
                                {isSalesManager &&
                                    <DropDown
                                        label="All Reps"
                                        url={`${Constants.baseURL}${Constants.getSalesReps}?manager=${this.currentUserName}`}
                                        onChange={event => {
                                            this.selectedUserName = event.target.value;
                                            if (this.selectedUserName === '') {
                                                this.selectedUserRole = this.currentUserRole;
                                                this.selectedUserName = this.currentUserName;
                                            } else {
                                                this.selectedUserRole = roles.SALES_REP;
                                            }
                                        }}
                                    />
                                }
                                {isLeadership &&
                                    <DropDown
                                        label="All Reps"
                                        disabled={manager === ''}
                                        url={`${Constants.baseURL}${Constants.getSalesReps}?manager=${manager}`}
                                        reset={manager === ''}
                                        onChange={event => {
                                            this.selectedUserName = event.target.value;
                                            if (this.selectedUserName === '') {
                                                this.selectedUserRole = roles.SALES_MANAGER;
												this.selectedUserName = manager;                                                
												if (manager === 'Key') {
                                                  this.selectedUserRole = roles.KEY;
                                                  this.selectedUserName = '';																								  
                                               }
                                            } else if (manager === 'Key') {
                                                this.selectedUserRole = roles.KEY;
                                            }
											else {
                                                this.selectedUserRole = roles.SALES_REP;
                                            }
                                        }}
                                    />
                                }
								{isKeyDir &&
                                    <DropDown
                                        label="All Reps"
                                        url={`${Constants.baseURL}${Constants.getSalesReps}?manager=${this.currentUserName}`}
                                        onChange={event => {
                                            this.selectedUserName = event.target.value;
											if (this.selectedUserName === '') {
                                                this.selectedUserName = '';
                                            }
                                        }}
                                    />
                                }
							</div>
							
                            <div className="dashboard-dropdown">
                                <select
                                    className="select special-select"
                                    value={year}
                                    onChange={(event) => {
                                        this.year = event.target.value;
                                        this.setState({
                                            year: this.year
                                        })
                                    }}
                                >
                                    {
                                        Array.from({ length: 3 }, (v, k) =>
                                            today.getFullYear() - k
                                        ).map((year, index) => <option key={`year-${index}`} value={year}>{year}</option>)
                                    }
                                </select>
                            </div>
                            <div className="dashboard-dropdown">
                                <select
                                    className="select special-select"
                                    value={monthOrQuarter}
                                    onChange={(event) => {
                                        this.monthOrQuarter = event.target.value;
                                        this.setState({
                                            monthOrQuarter: this.monthOrQuarter
                                        })
                                    }}
                                >
                                    <optgroup label="Quarters">
                                        {
                                            quarters.map(quarter => <option key={quarter} value={quarter}>{quarter}</option>)
                                        }
                                    </optgroup>
                                    <optgroup label="Months">
                                        {
                                            months.map(month => <option key={month} value={month.toLowerCase()}>{month}</option>)
                                        }
                                    </optgroup>
                                </select>
                            </div>
                            <div className="dashboard-search-button">
                                <Button
                                    dimension={20}
                                    icon={SearchIcon}
                                    onClick={() => { this.refetchData(); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={this.containerRef} className="container-fluid dashboard-container">
                    <div className="inner-dashboard-container">
                        <div className="row first-dashboard-row">

                            <div className="col-sm-8 dashboard-cell-1">
                                <div className="graph-container type-sales">
                                    <h2>MY SALES - {this.year}</h2>
                                    <div className="icons">
                                        {horizontalBarCount > Constants.maxHorizontalBars &&
                                            <>
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={`rotate180 ${horizontalBarPage === 1 ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        this.setState({
                                                            horizontalBarPage: horizontalBarPage - 1
                                                        })
                                                    }}
                                                />
                                                {horizontalBarPage + " "}
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={horizontalBarPage === Math.ceil(horizontalBarCount / Constants.maxHorizontalBars) ? 'disabled' : ''}
                                                    onClick={() => {
                                                        this.setState({
                                                            horizontalBarPage: horizontalBarPage + 1
                                                        })
                                                    }}
                                                />
                                            </>
                                        }


                                    </div>

                                    <div className="col-sm-12 .dashboard-cell-5">
                                        <HorizontalBarChart data={chartData.slice((horizontalBarPage - 1) * Constants.maxHorizontalBars, horizontalBarPage * Constants.maxHorizontalBars)} />
                                    </div>

                                    <div className="sub_div" widht="100%" >
                                        <Tooltip title="Download" >
                                            <img className="float-right" src={DownloadIcon} width="38px" height="38px" onClick={() => {
                                                this.downloadMySales();
                                            }} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 dashboard-cell-2">
                                <div className="graph-container">
                                    <h2>YTD COMPARISON</h2>
                                    <div className="icons">
                                        {verticalBarCount > Constants.maxVerticalBars &&
                                            <>
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={`rotate180 ${verticalBarPage === 1 ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        this.setState({
                                                            verticalBarPage: verticalBarPage - 1
                                                        })
                                                    }}
                                                />
                                                {verticalBarPage + " "}
                                                <img src={ArrowIcon} widht="20px" height="15px"
                                                    className={verticalBarPage === Math.ceil(verticalBarCount / Constants.maxVerticalBars) ? 'disabled' : ''}
                                                    onClick={() => {
                                                        this.setState({
                                                            verticalBarPage: verticalBarPage + 1
                                                        })
                                                    }}
                                                />
                                            </>
                                        }
                                    </div>
                                    <YTD data={ytdData.slice((verticalBarPage - 1) * Constants.maxVerticalBars, verticalBarPage * Constants.maxVerticalBars)} ytdLabel = {this.ytdLabel} isMonth= {this.isMonth} />
                                </div>
                            </div>

                        </div>

                        {!isKey &&
                            <React.Fragment>
                                <div className="row second-dashboard-row" >

                                    <div className="col-sm-3 dashboard-cell-1 ">

                                        <h2 > </h2>
                                        <DashboardTable columns={['c1', 'c2']} data={this.getTable1Data()} />
                                        <div>

                                        </div>

                                    </div>

                                    <div className="col-sm-5 dashboard-cell-2">
                                    <h2>ACHIEVEMENT</h2>
                                        <div className="graph-container">
                                        <br></br>
                                        <h6>% To Goal</h6>
                                            <Speedometer size={200} dx={55} dy={-6} percentage={(() => {
                                                const percent = parseFloat((kpiData.totalAchieved / kpiData.totalTarget) * 100).toFixed(1)
                                                if (percent > 100) {
                                                    return 100;
                                                }
                                                return percent;
                                            })()} />
                                        </div>
                                        <div className="graph-container">
                                        <br></br>
                                        <h6>% YTD vs Prior Year</h6>
                                            <Speedometer size={200} dx={55} dy={-6} percentage={(() => {
                                                const percent = parseFloat((kpiData.totalYtd / kpiData.totalPrevYtd) * 100).toFixed(1)
                                                if (percent > 100) {
                                                    return 100;
                                                }
                                                return percent;
                                            })()} />
                                            </div>
                                            <div className="graph-container">
                                            <br></br>
                                            <h6>Qtr vs Prior Qtr</h6>
                                            <Speedometer size={200} dx={55} dy={-6} percentage={(() => {
                                                const percent = parseFloat((kpiData.totalQuarter / kpiData.totalPrevQuarter) * 100).toFixed(1)
                                                if (percent > 100) {
                                                    return 100;
                                                }
                                                return percent;
                                            })()} />
                                            </div>
                                    </div>

                                    <div className="col-sm-1 dashboard-cell-2">
                                        <div className="graph-container">
                                            <h2>TOP</h2>
                                            <DashboardTable columns={['c1']} data={this.getTable2Data()} />
                                        </div>
                                    </div>


                                    <div className="col-sm-3 dashboard-cell-2">
                                        <div className="graph-container">
                                            <h2>LEADER BOARD</h2>
                                            <Leaderboard leaders={leaders.slice(0, 3)} />
                                        </div>
                                    </div>
                                </div>
                                
                            </React.Fragment>
                        }
                            <div className="row third-dashboard-row">
                                        <div className="graph-container">
                                        <h2>Monthly Trends</h2>
                                            <LeadershipGraph
                                                mouseover={_ => this.mouseover()}
                                                mousemove={html => this.mousemove(html)}
                                                mouseleave={_ => this.mouseleave()}
                                                data={leadershipGraphData}
                                                fetchData={(manufacturer, distributor, type, stateCode, rep, hqfilter) => {
                                                    this.fetchLeadershipGraphData(manufacturer, distributor, type, stateCode, rep, hqfilter);
                                                }}
                                                download={(rep) => {
                                                    this.downloadMonthlyTrends(rep, hqfilter);
                                                }}
                                                alldistributors='true'
                                            />
                                        </div>
                                    </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
})

export default connect(mapStateToProps)(Dashboard);