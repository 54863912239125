import React from 'react';
import * as d3 from 'd3';
import { axisBottom, axisLeft } from 'd3';
import './LeadershipLineGraph.css'
import { numberWithComas } from '../../../util/Constants';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const datax = [
    {
        month: 'Jan',
        prevAmount: 100,
        currentYtd: 200
    },{
        month: 'Feb',
        prevAmount: 500,
        currentYtd: 700
    },{
        month: 'Mar',
        prevAmount: 400,
        amount: 350
    },{
        month: 'Apr',
        prevAmount: 900,
        amount: 100
    },{
        month: 'May',
        prevAmount: 520,
        amount: 860
    },{
        month: 'Jun',
        prevAmount: 1500,
        amount: 1000
    },{
        month: 'Jul',
        prevAmount: 290,
        amount: 650
    },{
        month: 'Aug',
        prevAmount: 900,
        amount: 300
    },{
        month: 'Sep',
        prevAmount: 240,
        amount: 1560
    },{
        month: 'Oct',
        prevAmount: 640,
        amount: 970
    },{
        month: 'Nov',
        prevAmount: 200,
        amount: 500
    },{
        month: 'Dec',
        prevAmount: 1300,
        amount: 1400
    },
]

export default class LeadershipLineGraph extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        //this.drawChart();
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.drawChart();
        }
    }

    mouseover() {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.style("opacity", 1)
    }

    mousemove(html) {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.html(html)
        const styles = Tooltip.node().getBoundingClientRect();
        Tooltip.style('left', `${d3.event.x + 5}px`).style('top', `${d3.event.y - styles.height - 5}px`)
    }

    mouseleave() {
        const Tooltip = d3.select(this.tooltipRef.current)
        Tooltip.style("opacity", 0)
    }

    drawChart() {
        const { data, mouseover, mousemove, mouseleave } = this.props;
        // define width and height
        const width = 170*6, height = 100*4;
        // define left and right clearance for graph
        const leftClearance = 100, rightClearance = 50;
        // define top and bottom clearance for graph
        const topClearance = 30, bottomClearance = 50;
        // clean the graph
        d3.select(this.chartRef.current).selectAll('svg').remove();
        // define the svg
        const svg =
            d3.select(this.chartRef.current)
                .append('svg')
                .attr('preserveAspectRatio', 'xMinYMin meet')
                .attr('viewBox', `0 0 ${width} ${height}`)
        // create x scale - months
        const scaleX = 
            d3.scalePoint()
                .domain(months)
                .range([leftClearance, width-rightClearance])
        // create y scale - values
        const previousYtds = data.map(row=>row.prevAmount)
        const currentYtds = data.map(row => row.amount)
        const prevToPrevYtds = data.map(row => row.prevToPrevAmount)
        const maxValue = d3.max([...previousYtds, ...currentYtds, ...prevToPrevYtds])
        const scaleY =
            d3.scaleLinear()
                .domain([0, maxValue])
                .range([height-bottomClearance, topClearance])

        // add x axis of graph
        svg.append('g')
            .attr('transform', `translate(0, ${height-bottomClearance})`)
            .call(axisBottom(scaleX))

        // add y axis of graph
        svg.append('g')
            .attr('transform', `translate(${leftClearance}, 0)`)
            .call(axisLeft(scaleY))

        // line generator for generating current ytd line
        const currentLineGenerator =
            d3.line()
                .x(d=>scaleX(d.month))
                .y(d=>scaleY(d.amount))
        // append current ytd line to svg
        svg.append('path')
            .datum(data)
            .attr('d', currentLineGenerator)
            .attr('class', 'ytd-current-line')

        // append circles to current ytd data points
        svg.selectAll('current-circles')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', 3)
            .attr('cx', d=>scaleX(d.month))
            .attr('cy', d=>scaleY(d.amount))
            .attr('fill', 'orange')
            .on('mouseover', mouseover)
            .on('mousemove', d=>{
                mousemove(`
                    <div>Monthly Sales: ${numberWithComas(parseFloat(d.amount).toFixed(0))}</div>
                `)
            })
            .on('mouseleave', mouseleave)

        // line generator for generating previous ytd line
        const previousLineGenerator =
            d3.line()
                .x(d=>scaleX(d.month))
                .y(d=>scaleY(d.prevAmount))
        // append previous ytd line to svg
        svg.append('path')
            .datum(data)
            .attr('d', previousLineGenerator)
            .attr('class', 'ytd-previous-line')

        // append circles to previous ytd data points
        svg.selectAll('previous-circles')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', 3)
            .attr('cx', d=>scaleX(d.month))
            .attr('cy', d=>scaleY(d.prevAmount))
            .attr('fill', 'yellowgreen')
            .on('mouseover', mouseover)
            .on('mousemove', d=>{
                mousemove(`
                    <div>Monthly Sales: ${numberWithComas(parseFloat(d.prevAmount).toFixed(0))}</div>
                `)
            })
            .on('mouseleave', mouseleave)

            // line generator for generating previous ytd line
        const prevToPrevLineGenerator =
        d3.line()
            .x(d=>scaleX(d.month))
            .y(d=>scaleY(d.prevToPrevAmount))
    // append previous ytd line to svg
    svg.append('path')
        .datum(data)
        .attr('d', prevToPrevLineGenerator)
        .attr('class', 'ytd-prevToPrev-line')

    // append circles to previous ytd data points
    svg.selectAll('prevToPrev-circles')
        .data(data)
        .enter()
        .append('circle')
        .attr('r', 3)
        .attr('cx', d=>scaleX(d.month))
        .attr('cy', d=>scaleY(d.prevToPrevAmount))
        .attr('fill', 'darkred')
        .on('mouseover', mouseover)
        .on('mousemove', d=>{
            mousemove(`
                <div>Monthly Sales: ${numberWithComas(parseFloat(d.prevToPrevAmount).toFixed(0))}</div>
            `)
        })
        .on('mouseleave', mouseleave)
    }

    render() {
        return(
            <table className="leadership-line-graph-table">
                <tbody>
				    <tr>
                        <td className="current-ytd-line-legend">
                            <div></div> Current Year
                        </td>
                        <td className="previous-ytd-line-legend">
                            <div></div> Previous Year
                        </td>
                        <td className="prevToPrev-ytd-line-legend">
                            <div></div> Year 2019
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <div ref={this.chartRef}></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

LeadershipLineGraph.defaultProps = {
    mouseover: () => {},
    mousemove: () => {},
    mouseleave: () => {},
    data: datax
}