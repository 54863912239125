import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationComponent.css';
import $ from 'jquery';
import Constants, { roles } from '../util/Constants';
import { Icon } from '@material-ui/core';

import BudgetIcon from '../../images/menu-icon-budget.png';
import DashboardIcon from '../../images/menu-icon-dashboard.png';
import ExpenseIcon from '../../images/menu-icon-expense.png';
import HeatmapIcon from '../../images/menu-icon-heatmap.png';
import ReportIcon from '../../images/menu-icon-report.png';
import LookupIcon from '../../images/menu-icon-lookup.png';
import MasterIcon from '../../images/menu-icon-master.png';
import RespIcon from '../../images/menu-icon-resp.png';
import UploadIcon from '../../images/menu-icon-upload.png';
import UserIcon from '../../images/menu-icon-user.png';
import VerifyIcon from '../../images/menu-icon-verify.png';
import ZiprepIcon from '../../images/menu-icon-ziprep.png';


export default class NavigationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            path: window.location.hash,
            showMenu: false,
            mobileView: false
        };
        this.mobileView = false;
        this.onChangeNav = this.onChangeNav.bind(this)
    }

    componentDidMount() {
        const { userDetails } = this.props;
        if (this.state.path === '#/') {
            const homeScreen = '#/nav-'+(userDetails.homeScreen || Constants.defaultHomeScreen);
            this.setState({path: homeScreen})
            window.location.href = homeScreen;
        }
        this.handleWindowResize();
        $(window).on('resize', ()=>{
            this.handleWindowResize()
        });
    }

    onChangeNav(event) {
        const { enabledScreens } = this.props;
        let nav = event.target.href.substr(event.target.href.indexOf('#'));
        if (nav === this.state.path || !enabledScreens.includes(event.target.getAttribute('value')))
            event.preventDefault()
        else
            this.setState({path: nav})
    }

    getClassName(nav, name) {
        const { enabledScreens } = this.props;
        if(!enabledScreens.includes(name))
            return 'nav-item nav-link disabled-link'
        else if (nav === this.state.path)
            return 'nav-item nav-link active';
        else
            return 'nav-item nav-link';
    }

    toggleMenu() {
        const { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu
        })
    }

    handleWindowResize() {
        const { mobileView } = this.state;
        const width = $(window).width();
        if(width < 670 && !mobileView) {   // turn on mobile view
            this.setState({
                mobileView: true,
                showMenu: false
            })
        } else if(width >= 670 && mobileView) { // turn off mobile view
            this.setState({
                mobileView: false,
                showMenu: false
            })
        }
    }

    /**
     * This function wraps the links in mobile view or desktop view containers
     * 
     * @param {links} links - links to each page
     */
    wrapNavbar(links) {
        const { mobileView } = this.state;
        const { userDetails } = this.props;
        const isAdmin = userDetails.role === roles.ADMIN;
        if(mobileView) {
            const { showMenu } = this.state;
            // wrapper for mobile view
            return(
                <div className="nav-outer-div">
                    <i class="fa fa-bars" onClick={()=>{
                        this.toggleMenu();
                    }}></i>
                    { showMenu &&
                        <React.Fragment>
                            <div className="nav-overlay" onClick={()=>{
                                this.toggleMenu();
                            }}></div>
                            <div className="nav-inner-div" onClick={()=>{
                                this.toggleMenu();
                            }}>
                                {links}
                            </div>
                        </React.Fragment>
                    }
                </div>
            )
        } else {
            // wrapper for desktop view
            return(
                <div className={isAdmin ? `nav nav-tabs tab admin-menu` : `nav nav-tabs tab`} id="nav-tab" role="tablist" >
                    {links}
                </div>
            )
        }
    }

    render() {
        const { enabledScreens } = this.props;
        return (
            this.wrapNavbar(
                <React.Fragment>
                    { enabledScreens.includes('HOME') &&
                    <div className={this.getClassName("#/nav-home", 'HOME')+" lookup-buttons-panel"}  >
                        <img src={RespIcon} widht="20px" height="20px"/>
                        <Link to="/nav-home" value="HOME"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        RESP MAP
                        </Link>
                    </div>}
                   
                   

                    { enabledScreens.includes('USERS') &&
                    <div className={this.getClassName("#/nav-user", 'USERS')+" lookup-buttons-panel"}  >
                        <img src={UserIcon} widht="20px" height="20px"/>
                        <Link to="/nav-user" value="USERS"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Users
                        </Link>
                    </div>} 
                    
                    { enabledScreens.includes('ZIP_REP') &&
                    <div className={this.getClassName("#/nav-zip", 'ZIP_REP')+" lookup-buttons-panel"}  >
                        <img src={ZiprepIcon} widht="20px" height="20px"/>
                        <Link to="/nav-zip" value="ZIP_REP"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Zip Rep
                        </Link>
                    </div>} 
                    
                

                    { enabledScreens.includes('UPLOAD_DATA') &&
                    <div className={this.getClassName("#/nav-upload", 'UPLOAD_DATA')+" lookup-buttons-panel"}  >
                        <img src={UploadIcon} widht="20px" height="20px"/>
                        <Link to="/nav-upload" value="UPLOAD_DATA"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Upload 
                        </Link>
                    </div>} 
                    
                    { enabledScreens.includes('MASTER') &&
                    <div className={this.getClassName("#/nav-master", 'MASTER')+" lookup-buttons-panel"}  >
                        <img src={MasterIcon} widht="20px" height="20px"/>
                        <Link to="/nav-master" value="MASTER"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Master
                        </Link>
                    </div>} 
                    
                    
                    { enabledScreens.includes('REPORTS') &&
                    <div className={this.getClassName("#/nav-report", 'REPORTS')+" lookup-buttons-panel"}  >
                        <img src={ReportIcon} widht="20px" height="20px"/>
                        <Link to="/nav-report" value="REPORTS"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Report
                        </Link>
                    </div>} 
                    
                    { enabledScreens.includes('LOOKUP') &&
                    <div className={this.getClassName("#/nav-lookup", 'LOOKUP')+" lookup-buttons-panel"}  >
                        <img src={LookupIcon} widht="20px" height="20px"/>
                        <Link to="/nav-lookup" value="LOOKUP"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Mfg Lookup
                        </Link>
                    </div>} 
                    
                    
                    { enabledScreens.includes('HEAT_MAP') &&
                    <div className={this.getClassName("#/nav-heatmap", 'HEAT_MAP')+" lookup-buttons-panel"}  >
                        <img src={HeatmapIcon} widht="20px" height="20px"/>
                        <Link to="/nav-heatmap" value="HEAT_MAP"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                         Heat Map
                        </Link>
                    </div>} 
                    
                    { enabledScreens.includes('UPLOAD_VERIFY') &&
                    <div className={this.getClassName("#/nav-verify", 'UPLOAD_VERIFY')+" lookup-buttons-panel"}  >
                        <img src={VerifyIcon} widht="20px" height="20px"/>
                        <Link to="/nav-verify" value="UPLOAD_VERIFY"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Verification
                        </Link>
                    </div>} 
                    
                    
                    { enabledScreens.includes('DASHBOARD') &&
                    <div className={this.getClassName("#/nav-dashboard", 'DASHBOARD')+" lookup-buttons-panel"}  >
                        <img src={DashboardIcon} widht="20px" height="20px"/>
                        <Link to="/nav-dashboard" value="DASHBOARD"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Dashboard
                        </Link>
                    </div>}

                    { enabledScreens.includes('SAM_DASHBOARD') &&
                    <div className={this.getClassName("#/nav-samdashboard", 'SAM_DASHBOARD')+" lookup-buttons-panel"}  >
                        <img src={DashboardIcon} widht="20px" height="20px"/>
                        <Link to="/nav-samdashboard" value="SAM_DASHBOARD"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        SAMDashboard
                        </Link>
                    </div>}

                    { enabledScreens.includes('EXPENSE') &&
                    <div className={this.getClassName("#/nav-expense", 'EXPENSE')+" lookup-buttons-panel"}  >
                        <img src={ExpenseIcon} widht="20px" height="20px"/>
                        <Link to="/nav-expense" value="EXPENSE"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Expense
                        </Link>
                    </div>} 
                    
                    { enabledScreens.includes('BUDGET') &&
                     <div className={this.getClassName("#/nav-budget", 'BUDGET')+" lookup-buttons-panel"}  >
                        <img src={BudgetIcon} widht="20px" height="20px"/>
                        <Link to="/nav-budget" value="BUDGET"  role="tab" className="nav-link2"
                            aria-controls="nav-zip" aria-selected="false" onClick={this.onChangeNav}>
                        Budget
                        </Link>
                    </div>} 
                    
                    
                </React.Fragment>
            )
        )
    }

}

NavigationComponent.defaultProps = {
    userDetails: {}
}