import React from 'react';
import SearchBox from './SearchBox/SearchBox';
import TableHeaders from '../../ZipReportComponent/TableHeaders';
import './DistRepMapper.css';
import TableBody from '../../ZipReportComponent/TableBody';
import Constants, { toastOptions, downloadFile } from '../../../util/Constants';
import AddAndSaveButtons from '../AddAndSaveButtons';
import { toast } from 'react-toastify';
import Popup from '../../Popup/Popup';
import TableFooter from '../../ZipReportComponent/TableFooter';
import UploadIcon from '../../../../images/icon-upload.ico';
import DownloadIcon from '../../../../images/icon-save.png';
import Button from '../../ZipReportComponent/Button';
import SimpleUploadComponent from '../../SimpleUploadComponent/SimpleUploadComponent';
import { connect } from 'react-redux';
import { showLoader } from '../../../../redux/actions';

const columns = [
    {
        label: "Distributor",
        nosort: true,
        key: "distributor",
        type: 'enum',
        enum: {
            label: 'Choose Distributor',
            values: []
        }
    }, {
        label: "Manufacturer",
        nosort: true,
        key: "mfg",
        type: 'enum',
        enum: {
            label: 'Choose Manufacturers',
            values: []
        }
    }, {
        label: <span>Representative<br/>(Key Purch)</span>,
        nosort: true,
        key: "person"
    }, {
        label: <span>Group<br/>(ISS)</span>,
        nosort: true,
        key: "groups"
    }, {
        label: <span>Team<br/>(Purch)</span>,
        nosort: true,
        key: "team"
    }
]

const recordsPerPage = Constants.distRepMapperRows;

class DistRepMapper extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            data: [],
            distributors: [],
            manufacturers: [],
            showPopup: false,
            totalPages: 0,
            page: 1,
            columns,
            resetEditSwitch: false
        }
        this.index = 0;
        this.distributor = "";
    }

    static count = 0;

    fetchData(index, searchParams={}) {
        if(index === undefined) {
            index = this.index;
        } else {
            this.index = index;
            this.setState({
                page: index+1
            });
        }
        const searchString = Object.keys(searchParams).reduce((total, current)=>{
            if(!searchParams[current]) {
                return total;
            }
            return `${total}&${current}=${searchParams[current]}`
        }, '');
        const url = `${Constants.baseURL}${Constants.getDistRepMapper}?limit=${recordsPerPage}&index=${index}${searchString}`;
        fetch(url)
        .then(response=>response.json())
        .then(data=>{
            console.log(data)
            this.setState({
                data: Array.isArray(data.response) ? data.response : [],
                totalPages: Math.ceil(data.totalCount/recordsPerPage)
            })
        })
        .catch(error=>{
            console.log(error);
        }).finally(()=>{
            this.newData = [];
        })
    }

    fetchDistributors() {
        const url = `${Constants.baseURL}${Constants.getDistributors}`;
        fetch(url)
        .then(data=>data.json())
        .then(distributors=>{
            columns[0].enum.values = distributors.map(distributor=>distributor.distributionName)
            this.setState({
                columns
            })
        })
        .catch(error=>{
            console.log('Error: '+error);
        });
    }

    fetchManufacturers() {
        const url = `${Constants.baseURL}${Constants.getManufacturers}`;
        fetch(url)
        .then(data=>data.json())
        .then(manufacturers=>{
            columns[1].enum.values = manufacturers.map(manufacturer=>manufacturer.mfgName);
            this.setState({
                columns
            })
        }).catch(error=>{
            console.log(error);
        })
    }

    deleteData(id) {
        if(String(id).startsWith('temp')) {
            const { data } = this.state;
            const newData = data.filter(row=>row.id !== id);
            this.setState({
                data: newData
            })
            return;
        }
        const params = Constants.deleteDistRepMap.replace('{distrubutorRepMapperId}', id);
        const url = `${Constants.baseURL}${params}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json'
            }
        }).then(response=>{
            if(response.status === 204) {
                toast.success("Success: Delete success", toastOptions);
            } else {
                throw new Error();
            }
        })
        .catch(error=>{
            console.log(error);
            toast.error("Error: Delete failed", toastOptions);
        }).finally(()=>{
            this.fetchData();
        })
    }

    addRow() {
        const { data } = this.state;
        const dummyRow = {
            distributor: '',
            groups: '',
            person: '',
            team: '',
            id: `temp${DistRepMapper.count}`
        }
        DistRepMapper.count++;
        data.push(dummyRow);
        this.setState({
            data
        })
    }

    saveData() {
        if(JSON.stringify(this.newData)==='[]')
            return;
        this.newData.forEach(row=>{
            if(String(row.id).startsWith('temp')) {
                delete row.id;
            }
        })
        const url = `${Constants.baseURL}${Constants.modifyDistRepMapper}`;
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(this.newData)
        }).then(response=>{
            console.log(response);
            this.setState({
                resetEditSwitch: !this.state.resetEditSwitch
            })
        }).catch(error=>{
            console.log(error);
        }).finally(()=>{
            this.fetchData();
        })
    }

    componentDidMount() {
        this.fetchData();
        this.fetchDistributors();
        this.fetchManufacturers();
    }

    /**
     * Higher order function to close popup
     * 
     * @param {function} arg - function to execute
     */
    closePopup(arg = ()=>{}) {
        this.setState({
            showPopup: false
        })
        arg()
    }

    render() {
        const { data, columns, showPopup, totalPages, page, resetEditSwitch } = this.state;
        const { dispatch, editable } = this.props;
        return (
            <div className="container-fluid dist-rep-container">
                { showPopup &&
                    <Popup
                        onYes={()=>{
                            this.closePopup(this.deleteData(this.idToDelete));
                        }}
                        onNo={()=>{
                            this.closePopup();
                        }}
                    />
                }
                <div className="row">
                    <div className="col-sm-9">
                        <SearchBox
                            onDistributorChange={(distributor)=>{
                                this.distributor = distributor;
                            }}
                            onSearch={(searchParams)=>{
                                this.fetchData(0, searchParams);
                            }}
                        />
                    </div>
                    <div className="col-sm-3">
                        <div className="row zip-update-button-container">
                            <SimpleUploadComponent
                                uploadUrl={`${Constants.baseURL}${Constants.uploadDistRepMapperData}`}
                                image={{
                                    src: UploadIcon,
                                    width: 20,
                                    height: 20
                                }}
                                label="UPLOAD"
                                disabled={!editable}
                            />
                            <Button
                                onClick={() => {
                                    dispatch(showLoader(true));
                                    downloadFile(`${Constants.baseURL}${Constants.downloadDistRepMapperData}`, undefined, 'xlsx')
                                        .finally(()=>{
                                            dispatch(showLoader(false));
                                        })
                                }}
                                icon={DownloadIcon}
                                label='Download'
                                dimension='20'
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="listing-grid table-responsive table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <TableHeaders
                                            columns={columns}
                                        />
                                        {editable && <th className="history-table-header" scope="col" >Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableBody
                                        columns={columns}
                                        data={data}
                                        deleteHistory={(id)=>{
                                            this.idToDelete = id;
                                            this.setState({
                                                showPopup: true
                                            })
                                        }}
                                        hiddenEditRowIndexes={data.reduce((resultArray, currentRow, index)=>{
                                            if(String(currentRow.id).startsWith('temp')) {
                                                resultArray.push(index);
                                            }
                                            return resultArray;
                                        }, [])}
                                        onDataChange={(newData)=>{
                                            this.newData = newData;
                                        }}
                                        disableActions={!editable}
                                        resetEditSwitch={resetEditSwitch}
                                    />
                                </tbody>
                                <tfoot>
                                    <TableFooter
                                        totalPages={totalPages}
                                        page={page}
                                        changePage={(index)=>{
                                            this.fetchData(index);
                                        }}
                                    />
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={()=>{
                        this.addRow();
                    }}
                    onSaveClick={()=>{
                        this.saveData();
                    }}
                    disabled={!editable}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('MASTER')
})

export default connect(mapStateToProps)(DistRepMapper);