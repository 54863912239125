import React from 'react';
import * as d3 from 'd3';
import { numberWithComas } from '../../../util/Constants';
import './YTD.css';

const datax = [
    [120, 90],
    [50, 40],
    [100, 160],
    [70, 45],
    [140, 150],
    [125, 145],
    [170,  80],
    [180, 200]
];

const namesx = ['Land only', 'Houses>$400K', '$401K - $500K', '$501K - $600K', '$601K - $700K', '$701K - $800K', '$800K+', 'Total'];

const colors = ['#e4f0d2', '#a6cf4b'];

export default class YTD extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        this.drawGraph();
        // this.drawLegend();
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.drawGraph();
        }
    }

    drawGraph() {
        const marginBottom = 30;
        const { dx, barWidth, data, aspectRatio } = this.props;
        const height = 300;
        const maxValue = data.reduce((t,c)=>{
            let max = t;
            const { currentYtd, previousYtd } = c;
            if(currentYtd > max) {
                max = currentYtd;
            }
            if(previousYtd > max) {
                max = previousYtd;
            }
            return max;
        }, 0);
        const names = data.map(obj=>obj.mfg);
        
        // clear chart
        d3.select(this.chartRef.current).selectAll('svg').remove();

        const svg = d3.select(this.chartRef.current)
            .append('svg')
            .attr("preserveAspectRatio", aspectRatio)
            .attr('viewBox', '0 0 400 340')

        const markerBoxWidth = 6;
        const markerBoxHeight = 6;
        const refX = markerBoxWidth/2;
        const refY = markerBoxHeight/2;
        const arrowPoints = [[0, 0], [0, markerBoxWidth], [markerBoxHeight, refX]];
        const defs = svg.append('defs')
        defs.append('marker')
            .attr('id', 'arrow-increase')
            .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
            .attr('refX', markerBoxHeight)
            .attr('refY', refY)
            .attr('markerWidth', markerBoxWidth)
            .attr('markerHeight', markerBoxHeight)
            .attr('orient', 'auto-start-reverse')
            .append('path')
            .attr('d', d3.line()(arrowPoints))
            .attr('stroke', 'green')
            .attr('fill', 'green')

        defs.append('marker')
            .attr('id', 'arrow-decrease')
            .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
            .attr('refX', markerBoxHeight)
            .attr('refY', refY)
            .attr('markerWidth', markerBoxWidth)
            .attr('markerHeight', markerBoxHeight)
            .attr('orient', 'auto-start-reverse')
            .append('path')
            .attr('d', d3.line()(arrowPoints))
            .attr('stroke', '#de425b')
            .attr('fill', '#de425b')

        if(this.props.width) {
            svg.attr('width', this.props.width);
        }
        if(this.props.height) {
            svg.attr('height', this.props.height);
        }
            // .attr('width', '400')
            // .attr('height', '300');
        const scaleX = d3.scaleBand()
            .domain(names)
            .range([0, barWidth*2*names.length]);
        const scaleY = d3.scaleLinear()
            .domain([0, maxValue])
            .range([245, 0]);
        const scaleYValueConverter = d3.scaleLinear()
            .domain([0, maxValue])
            .range([0, 245])
        const g = svg.selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', (d,i)=>`translate(${(i*barWidth*2)+dx}, ${height-marginBottom})`)
        
        const Tooltip = d3.select(this.tooltipRef.current);

        // Three function that change the tooltip when user hover / move / leave a cell
        const mouseover = function(d) {
            Tooltip
            .style("opacity", 1)
        }
        const mousemove = function(d) {
            const data = d3.select(this).data();
            Tooltip
            .html(`
                <div style="text-align: center; font-weight: 600;">
                    ${numberWithComas(data[0].value)}
                </div>
            `)
            const styles = Tooltip.node().getBoundingClientRect();
            Tooltip.style('left', `${d3.event.x + 5}px`).style('top', `${d3.event.y - styles.height-5}px`)

        }
        const mouseleave = function(d) {
            Tooltip
            .style("opacity", 0)
        }

        Tooltip.on('mouseover', (d)=>{
            Tooltip.style('left', 0);
        })

        g.selectAll('g')
            .data(d=>{
                const diffPercent = Math.ceil((d.currentYtd - d.previousYtd)*100/d.previousYtd)
                const currentYtd = {
                    value: d.currentYtd,
                    hasText: d.currentYtd >= d.previousYtd,
                    diffValue: d.currentYtd - d.previousYtd,
                    diffPercent
                }
                const previousYtd = {
                    value: d.previousYtd,
                    hasText: d.currentYtd < d.previousYtd,
                    diffValue: d.previousYtd - d.currentYtd,
                    diffPercent
                }
                return [previousYtd, currentYtd]
            })
            .enter()
            .append('g')
            .attr('transform', (d,i)=>`translate(${i*20}, -${scaleYValueConverter(d.value)})`)
            .append('rect')
            .attr('width', (d,i)=>barWidth-(i!==0?2:0))
            .attr('height', d=>scaleYValueConverter(d.value))
            .style('fill', (d,i)=>colors[i])
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)

        g.selectAll('g')
            .filter(d=>d.hasText)
            .select(function() {
                return this.parentNode
            })
            .append('text')
            .text(d=> `${Math.abs(d.diffPercent)}%`)
            .attr('text-anchor', 'start')
            .attr('font-size', 10)
            .attr('y', d=>-scaleYValueConverter(d.value)-5)
            .attr('fill', d => d.diffPercent < 0 ? 'red' : 'green')

        g.selectAll('g')
            .filter(d=>!d.hasText)
            .append('path')
            .attr('d', (d) => {
                if(d.diffPercent < 0) {
                    return d3.line()([[10, scaleYValueConverter(d.diffValue)], [10, 0]])
                } else {
                    return d3.line()([[10, 0], [10, scaleYValueConverter(d.diffValue)]])
                }
            })
            .attr('stroke', d=>d.diffPercent < 0 ? 'red' : 'green')
            .attr('marker-end', d => d.diffPercent > 0 ? 'url(#arrow-increase)' : 'url(#arrow-decrease')
            .attr('fill', 'none');

        const xaxis = d3.axisBottom(scaleX)
        const yaxis = d3.axisLeft(scaleY);
        svg.append('g')
            .style('color', 'white')
            .attr('transform', `translate(${dx}, ${height-marginBottom})`)
            .call(xaxis)
            .selectAll('text')
            .style('transform', 'rotate(-90deg) translate(-3px, -7px)')
            .style('text-anchor', 'end')
             .style('font-size', '8px')
        svg.append('g')
            .style('color', 'white')
            .attr('transform', `translate(${dx}, ${height-marginBottom-245})`)
            .call(yaxis);

        //this.drawLegend(svg);
    }

    drawLegend(svg) {
        const data = [
            {
                label: 'Current YTD',
                color: colors[1]
            },
            {
                label: 'Previous YTD',
                color: colors[0]
            }
        ]
        const legend = svg.append('g')
                        .attr('transform', `translate(310, 0)`)
                        .style('fill', 'white')
        const g = legend.selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', (d,i)=>`translate(0, ${(i+1)*20})`)
        
        // const g = svg.selectAll('g')
        //     .data(data)
        //     .enter()
        //     .append('g')
        //     .attr('transform', (d,i)=>`translate(0, ${(i+1)*20})`)
        g.append('rect')
            .attr('width', '10px')
            .attr('height', '10px')
            .attr('fill', d=>d.color)
            .style('transform', 'translate(0, -10px)')
        g.append('text')
            .html(d=>d.label)
            .attr('transform', `translate(20, -3)`)
            .attr('font-size', '8')
    }

    render() {
        const { parentWidth, parentHeight } = this.props;
        const label = this.props.isMonth ? "YTD" : "Quarter";
        return(
            <React.Fragment>
                <div ref={this.tooltipRef} className="tooltip"></div>
                <div ref={this.chartRef} style={{position: 'relative', width: parentWidth, height: parentHeight, marginTop: '20px'}}>
                    <div className="ytd-new-legend">
                        <span className="ytd-legend-square">Previous {label}</span>
                        <span className="ytd-legend-square">Current  {label}</span>
                    </div>
                </div>
			</React.Fragment>
        )
    }
}

YTD.defaultProps = {
    barWidth: 20,
    barPadding: 7,
    dx: 60,
    data: [],
    aspectRatio: 'xMinYMin meet',
    width: undefined,
    height: undefined,
    parentWidth: '100%',
    parentHeight: undefined
}