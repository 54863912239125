import React from 'react';
import SearchBox from '../../SearchBox/SearchBox';
import ManufacturersTable from './ManufacturersTable';
import Constants, { toastOptions } from '../../../util/Constants';
import { connect } from 'react-redux';
import { showLoader } from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Popup from '../../Popup/Popup';
import AddAndSaveButtons from '../AddAndSaveButtons';

const dummyRow = {
    mfgCode: '',
    mfgName: '',
    sortOrder: '',
    priority: '',
    status: 'active'
}

class ManufacturersPage extends React.Component {

    static count = 0;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showPopup: '',
            manufacturer: '',
            resetEditSwitch: false
        }
    }

    componentDidMount() {
        this.abortController = new AbortController();
        this.fetchData();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    fetchData() {
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.getManufacturers}`;
        fetch(url, {
            signal: this.abortController.signal
        }).then(response=>{
            dispatch(showLoader(false));
            return response.json();
        }).then(jsonData=>{
            jsonData.forEach(row=>{
                row.status = row.status === "active" ? true : false
            })
            this.setState({
                data: jsonData
            })
        }).catch(error=>{
            dispatch(showLoader(false));
            console.log("Error: "+error);
        })
    }

    saveData() {
        if(!this.updatedData) {
            return;
        }
        const { dispatch } = this.props;
        dispatch(showLoader(true));
        const url = `${Constants.baseURL}${Constants.createOrUpdateManufacturers}`;
        const body = JSON.stringify(this.updatedData.map(row=>{
            row.status = row.status ? 'active' : 'inactive';
            if(String(row.id).startsWith('temp')) {
                delete row.id;
            }
            return row;
        }));
        fetch(url, {
            method: 'POST',
            signal: this.abortController.signal,
            body,
            headers: {
                'Content-type': 'application/json'
            }
        }).then(response=>{
            if(response.status !== 200) {
                throw new Error()
            }
            dispatch(showLoader(false));
            toast.success("Success: Changes saved", toastOptions);
            this.fetchData();
            this.setState({
                resetEditSwitch: !this.state.resetEditSwitch
            })
            return response.text();
        }).then(text=>{
            this.setState({
                message: text,
                showOk: true,
                showPopup: true
            })
        })
        .catch(error=>{
            console.log(error);
            dispatch(showLoader(false));
            toast.error("Error: Operation failed", toastOptions);
            this.fetchData();
        })
    }

    addRow() {
        const { data } = this.state;
        data.push(JSON.parse(JSON.stringify(dummyRow)));
        const newRowIndex = data.length - 1;
        data[newRowIndex].id = `temp${ManufacturersPage.count}`;
        ManufacturersPage.count++;
        this.setState({
            data
        })
    }

    deleteRow(id) {
        let { data } = this.state;
        data = data.filter(row=>String(row.id) !== String(id));
        this.setState({
            data
        });
        if(this.updatedData) {
            this.updatedData = this.updatedData.filter(row=>String(row.id) !== String(id));
        }
        if(String(id).startsWith('temp')) {
            return;
        }
        this.deleteApiCall(id);
    }

    deleteApiCall(id) {
        const params = Constants.deleteManufacturers.replace('{manufactureId}', id);
        const url = `${Constants.baseURL}${params}`;
        fetch(url, {
            method: 'DELETE'
        }).then(response=>{
            console.log(response);
            this.fetchData();
        }).catch(error=>{
            console.log(error);
            this.fetchData();
        })
    }

    /**
     * Higher order function to close popup
     * 
     * @param {function} arg - function to execute
     */
    closePopup(arg = ()=>{}) {
        this.setState({
            showPopup: false
        })
        arg()
    }

    render() {
        
        const { data, showPopup, manufacturer, showOk, message, resetEditSwitch } = this.state;
        const { editable } = this.props;

        return(
            <div className="container-fluid">
                { showPopup &&
                    <Popup
                        onYes={()=>{
                            this.closePopup(this.deleteRow(this.idToDelete));
                        }}
                        onNo={()=>{
                            this.closePopup();
                        }}
                        showOk={showOk}
                        onOk={()=>{
                            this.closePopup(()=>{
                                this.setState({
                                    showOk: false,
                                    message: ''
                                })
                            });
                        }}
                        message={message}
                    />
                }
                <div className="row">
                    <div className="col-sm-3">
                        <div className="txt-wrap user-table-search input-group">
                            <SearchBox
                                placeholder="Manufacturer"
                                onChange={(value)=>{
                                    this.setState({
                                        manufacturer: value
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <ManufacturersTable
                            data={data}
                            onDataChange={(updatedData)=>{
                                this.updatedData = updatedData;
                                updatedData.forEach(row=>{
                                    const changeIndex = data.indexOf(d=>d.id === row.id);
                                    data[changeIndex] = row;
                                })
                                this.setState({
                                    data
                                })
                            }}
                            deleteRow={(id)=>{
                                this.idToDelete = id;
                                this.setState({
                                    showPopup: true
                                })
                            }}
                            manufacturer={manufacturer}
                            editable={editable}
                            resetEditSwitch={resetEditSwitch}
                        />
                    </div>
                </div>
                <AddAndSaveButtons
                    onAddClick={()=>{
                        this.addRow();
                    }}
                    onSaveClick={()=>{
                        this.saveData();
                    }}
                    disabled={!editable}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    editable: state.privileges.writable.includes('MASTER')
})

export default connect(mapStateToProps)(ManufacturersPage);