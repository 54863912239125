import React from 'react';
import Distributors from '../Distributors';
import Manufacturers from '../Manufacturers';
import SearchButton from '../ZipReportComponent/SearchButton';
import Button from '../ZipReportComponent/Button';

export default class MFGSearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.search = {}
        this.formRef = React.createRef();
    }

    render() {
        const { onSearch, onManufacturersFetchComplete, onDistributorsFetchComplete } = this.props;
        return(
            <div className="container-fluid zip-search-box">
                <form ref={this.formRef} className="row">
                    <div className="col-sm-3">
                        <Distributors
                            onChange={(event)=>this.search.distributor = event.target.value}
                            onFetchComplete={distributors=>onDistributorsFetchComplete(distributors)}
                        />
                    </div>
                    <div className="col-sm-3">
                        <Manufacturers
                            onChange={(event)=>this.search.mfg = event.target.value}
                            onFetchComplete={manufacturers=>onManufacturersFetchComplete(manufacturers)}
                        />
                    </div>
                    <div className="col-sm-2">
                        <input type="text" className="txt form-control" placeholder="State" onChange={(event)=>this.search.state = event.target.value} />
                    </div>
                    <div className="col-sm-4 mfg-lookup-search-button-container">
                        <SearchButton onClick={()=>{onSearch(this.search)}} />
                        <Button
                            label="clear"
                            onClick={()=>{
                                this.search = {};
                                onSearch({});
                                this.formRef.current.reset();
                            }}
                        />
                    </div>
                </form>
            </div>
        )
    }
}

MFGSearchBox.defaultProps = {
    onSearch: ()=>{},
    onDistributorsFetchComplete: ()=>{},
    onManufacturersFetchComplete: ()=>{}
}